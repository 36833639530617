import './AuctionBids.css';
import React from 'react';
import { deleteBid, getAuctionBids, getBorders, getInitialBids, saveAuctionBids, sendAuctionBids } from '../../apis/vitusApi';
import VContentContainer from '../../components/VContentContainer/VContentContainer';
import VDatePicker from '../../components/VDatePicker/VDatePicker';
import VFilterContainer from '../../components/VFilterContainer/VFilterContainer';
import { AddStyles } from '../../components/VTagInput/cusomized-react-tag-input/ReactTags';
import VTagInput from '../../components/VTagInput/VTagInput';
import VDropdown from '../../components/VDropdown/VDropdown';
import { handleApiError, alertError, alertSuccess, alertWarning, rearrangeErrors } from '../../helpers/errorHelper';
import { extractDate, getDate, isArray, getCompanyOptions, isCompanySelectable } from '../../helpers/generalHelper';
import { createExcel } from '../../helpers/excelHelper';
import VMainContainer from '../../components/VMainContainer/VMainContainer';
import VTabs, { VTab } from '../../components/VTabs/VTabs';
import VTable from '../../components/VTable/VTable';
import _, { isObject } from 'lodash';
import AddAuctionBidModal from '../../modals/AddAuctionBidModal/AddAuctionBidModal';
import SendAuctionEmailsModal from '../../modals/SendAuctionEmailsModal/SendAuctionEmailsModal';
import NeedRefreshModal from '../../modals/NeedRefreshModal/NeedRefreshModal';
import { portalMessages } from '../../helpers/portalMessages';
import ConfirmationModal from '../../modals/ConfirmationModal/ConfirmationModal';
import { SpinnerManager } from '../../components/VSpinner/SpinnerManager';
import history from '../../history';

/*
 Filter arrangements on branch 'VIT-2292_Auction_Bid_Filter_Update':
    - borderTags -> counterPartyTags conversion
    - disabling tag input as default
    - removing tag methods
    - tag input min-height update
    - clear button function and error messages update
*/


class AuctionBids extends React.Component {
    spinner = new SpinnerManager(history.location.pathname);
    hoursColumn = { columnName: 'hour', title: "Hour", columnIndex: 0, unit: "CET" };
    amountColumn = { columnName: 'amount', title: "Amount", columnIndex: 1, unit: "(MWh)" };
    bidNumStr = "[BID_NUM]";
    bidColumn = { columnName: 'bid', title: this.bidNumStr, columnIndex: 2, unit: "(EUR/MWh)" };

    state = {
        selectedDate: getDate(1),
        counterPartyTags: [],
        borderDetails: {},
        counterPartyDetails: {},
        directionDetails: {},
        counterParties: null,
        activeFilter: { date: null, borders: [], company: null },
        addAuctionBidModal: { show: false, counterParty: '' },
        newAuctionBid: null,
        editingTables: false,
        showSendAllModal: false,
        selectedCounterParty: '',
        showNeedsRefresModal: false,
        inconsistentDataCaught: false,
        sentMailExist: false,
        activeFilterToDisplay: [],
        bordersOfCounterParties: {},
    };

    showErrorMessage(error) {
        const errorMessage = this.specifyErrorMessage(error);
        if (errorMessage)
            alertError(errorMessage);
    }

    showSuccessMessage(message) {
        alertSuccess(message);
    }

    setTabError(error) {
        let inconsistentDataCaught = false;

        let allErrors = rearrangeErrors(error);

        if (!_.isEmpty(allErrors)) {
            Object.keys(allErrors).forEach(errorKey => {
                const borderError = allErrors[errorKey];

                Object.keys(borderError).forEach(errorType => {
                    if (errorType === 'InconsistentData')
                        inconsistentDataCaught = true;
                })
            })
        }

        this.showErrorMessage(this.specifyErrorMessage(error));

        if (inconsistentDataCaught)
            this.setState({ inconsistentDataCaught });
    }

    setWarning(warn) {
        if (warn
            && warn.message
            && warn.message.NotFound
            && warn.message.NotFound === 'marginAnalysis')
            alertWarning(portalMessages.AUCTION_BIDS.NO_MARGIN);
        else
            alertWarning(portalMessages.UNEXPECTED_ERROR_OCCURED);
    }

    specifyErrorMessage(error) {
        let message;

        if (!isObject(error)) {
            message = error;
        }
        else {
            let allErrors = rearrangeErrors(error);

            if (!_.isEmpty(allErrors)) {
                Object.keys(allErrors).forEach(errorKey => {
                    const borderError = allErrors[errorKey];

                    Object.keys(borderError).forEach(errorType => {
                        switch (errorType) {
                            case 'NotFound':
                                break;
                            case 'InvalidRequest':
                                message = portalMessages.AUCTION_BIDS.INVALID_BID_DELETE;
                                break;
                            case 'InconsistentData':
                                switch (borderError[errorType]) {
                                    case 'amount':
                                        message = portalMessages.AUCTION_BIDS.INCONSISTENT_AMOUNT;
                                        break;
                                    case 'bid':
                                        message = portalMessages.AUCTION_BIDS.INCONSISTENT_DATA_BID;
                                        break;
                                    case 'mail_sent':
                                        message = portalMessages.AUCTION_BIDS.INCONSISTENT_DATA_MAIL;
                                        break;
                                    case 'previous_bid':
                                        message = portalMessages.AUCTION_BIDS.INVALID_GREATER_THAN_PREVIOUS;
                                        break;
                                    case 'next_bid':
                                        message = portalMessages.AUCTION_BIDS.INVALID_LESS_THAN_NEXT;
                                        break;
                                    default:
                                        message = portalMessages.AUCTION_BIDS.INCONSISTENT_DATA;
                                        break;
                                }
                                break;
                            case 'InvalidData':
                                switch (borderError[errorType]) {
                                    case 'amount':
                                        message = portalMessages.AUCTION_BIDS.AMOUNT_TOO_BIG_OR_SMALL;
                                        break;
                                    case 'amount_zero':
                                        message = portalMessages.AUCTION_BIDS.BID_WITH_NO_AMOUNT;
                                        break;
                                    case 'bid':
                                        message = portalMessages.AUCTION_BIDS.BID_TOO_BIG_OR_SMALL;
                                        break;
                                    case 'bid_zero':
                                        message = portalMessages.AUCTION_BIDS.AMOUNT_WITH_NO_BID;
                                        break;
                                    case 'total_amount':
                                        message = portalMessages.AUCTION_BIDS.ALL_AMOUNTS_ZERO;
                                        break;
                                    case 'total_bid':
                                        message = portalMessages.AUCTION_BIDS.ALL_BIDS_ZERO;
                                        break;
                                    case 'bid_number':
                                        message = portalMessages.AUCTION_BIDS.INVALID_BID_NUMBER;
                                        break;
                                    case 'requested_date':
                                        message = portalMessages.AUCTION_BIDS.INVALID_REQUEST_DATE;
                                        break;
                                    case 'max_bid_number':
                                        message = portalMessages.AUCTION_BIDS.INVALID_MAX_BID_NUMBER;
                                        break;
                                    default:
                                        message = portalMessages.INVALID_GENERAL;
                                        break;
                                }
                                break;
                            case 'Authorization':
                                switch (borderError[errorType]) {
                                    case 'mailSend':
                                        message = portalMessages.AUTHORIZATION.SEND_MAIL;
                                        break;
                                    default:
                                        message = portalMessages.AUTHORIZATION.GENERAL;
                                        break;
                                }
                                break;
                            default:
                                message = portalMessages.UNEXPECTED_ERROR_OCCURED;
                                break;
                        }
                    });
                });
            }
        }

        return message;
    }

    startSpinner() {
        return this.spinner.showSpinner();
    }

    stopSpinnerFor(key) {
        this.spinner.hideSpinner(key);
    }

    componentDidMount() {
        const spinnerKey = this.startSpinner();

        getBorders().then(response => {
            if (response.data.success) {
                const bordersWithTwoCountries = response.data.success.borders.filter(b => b.border.split('<>').length === 2);

                const borderDetails = {};
                const counterPartyDetails = {}
                const directionDetails = {}
                const bordersOfCounterParties = {};

                bordersWithTwoCountries.forEach(border => {
                    borderDetails[border.border] = {};

                    border.directions.forEach(direction => {
                        direction.counterParties.forEach(party => {
                            if (!borderDetails[border.border][party])
                                borderDetails[border.border][party] = [];

                            if (!counterPartyDetails[party])
                                counterPartyDetails[party] = [];

                            borderDetails[border.border][party].push(direction.direction);
                            counterPartyDetails[party].push(direction.direction);
                            directionDetails[direction.direction] = border.border;
                        });

                        direction.counterParties.forEach(party => {
                            borderDetails[border.border][party].sort();
                        });
                    });
                });

                Object.keys(counterPartyDetails).forEach(p => {
                    counterPartyDetails[p].sort();
                });

                Object.keys(borderDetails).forEach(border => {
                    Object.keys(borderDetails[border]).forEach(counterParty => {

                        if (!(counterParty in bordersOfCounterParties))
                            bordersOfCounterParties[counterParty] = [];

                        bordersOfCounterParties[counterParty].push(border);
                    });

                });

                const counterPartyTags = _.orderBy(Object.keys(bordersOfCounterParties)).map(cp => this.generateTag(cp));

                this.setState({ counterPartyTags, borderDetails, counterPartyDetails, directionDetails, bordersOfCounterParties });

                this.onShow();
            }


            if (response.data.error)
                this.showErrorMessage(response.data.error);

        }, error => {
            handleApiError(error);
            this.setState({ showNeedsRefresModal: true });
        }).finally(() => {
            this.stopSpinnerFor(spinnerKey);
        });
    }

    generateTag(value) {
        return { id: value, text: value };
    }

    onShowButtonClick = async () => {
        if ((!this.state.counterPartyTags || this.state.counterPartyTags.length === 0) && !this.state.selectedDate) {
            this.showErrorMessage(portalMessages.ADD_DATE_AND_COUNTER_PARTY);
            return;
        }

        if (!this.state.selectedDate) {
            this.showErrorMessage(portalMessages.DATE_SELECTION);
            return;
        }

        if (!this.state.counterPartyTags || this.state.counterPartyTags.length === 0) {
            this.showErrorMessage(portalMessages.ADD_COUNTER_PARTY)
            return;
        }

        return await this.onShow();
    }

    onClearButtonClick(e) {
        this.setState({
            selectedDate: getDate(1)
        });
    }

    onShow = async () => {
        if (!this.state.selectedDate)
            return;

        if (!this.state.counterPartyTags || this.state.counterPartyTags.length === 0)
            return;
            

        let borders = [];
        this.state.counterPartyTags.forEach(cp => borders.push(...this.state.bordersOfCounterParties[cp.id]));

        var filter = {
            filter: {
                date: extractDate(this.state.selectedDate),
                borders: _.uniq(borders),
            }
        };

        await this.getAuctionBidsForFilter(filter);
    }

    getAuctionBidsForFilter = async (filter) => {
        const spinnerKey = this.startSpinner();

        try {
            const response = await getAuctionBids(filter)
            let counterParties = null;

            if (response.data.success) {
                counterParties = response.data.success;
                const directionDetails = this.state.directionDetails;

                counterParties.forEach(party => {
                    party.direction_list.sort((a, b) => {
                        if (directionDetails[a.direction] > directionDetails[b.direction])
                            return 1;

                        if (directionDetails[a.direction] < directionDetails[b.direction])
                            return -1;

                        if (a.direction > b.direction)
                            return 1;

                        if (a.direction < b.direction)
                            return -1;

                        if (a.bid_number > b.bid_number)
                            return 1;

                        return -1;
                    })
                })
            }

            this.setState({
                counterParties: counterParties,
                activeFilter: {
                    date: Date.parse(filter.filter.date),
                    borders: filter.filter.borders.map(b => {
                        return this.generateTag(b)
                    }),
                    counterParties: this.state.counterPartyTags.map(i => i.text),
                    company: filter.filter.company
                },
                activeFilterToDisplay: [
                    { label: "Company", value: filter.filter.company },
                    { label: "Date", value: filter.filter.date },
                    { label: "Counter Party", value: this.state.counterPartyTags.map(i => i.text) },
                ]
            });

            if (response.data.error)
                this.showErrorMessage(response.data.error);


        } catch (error) {
            handleApiError(error);
        } finally {
            this.stopSpinnerFor(spinnerKey)
        }
    }

    onAddBidButtonClick(counterParty) {
        this.setState(
            {
                addAuctionBidModal: {
                    show: true,
                    counterParty
                }
            });
    }

    onSendAllButtonClick(counterParty) {
        let counterPartyDetails = this.state.counterParties.filter(c => c.counter_party === counterParty);
        const sentMailExist = counterPartyDetails[0].direction_list.filter(d => d.mail_sent).length > 0;

        this.setState({
            selectedCounterParty: counterParty,
            showSendAllModal: true,
            sentMailExist: sentMailExist
        });
    }

    onSendAllToMeButtonClick(counter_party) {
        this.onSendAllBids(counter_party, false, true)
    }

    onSendOnlyNewBids(counterParty) {
        this.onSendAllBids(counterParty, true);
    }

    onSendAllBids(counterParty, onlyNews = false, isTest = false) {
        this.setState({ showSendAllModal: false });

        if (!this.state.counterParties || this.state.counterParties.length === 0) {
            this.setTabError(`No bids to send for ${counterParty}.`);
            return;
        }

        const [counterPartyDetails] = this.state.counterParties.filter(c => c.counter_party === counterParty);

        if (counterPartyDetails) {
            const { counter_party, direction_list } = counterPartyDetails;

            let body;

            if (onlyNews) {
                const directions = direction_list.filter(d => !d.mail_sent);

                if (directions.length === 0) {
                    this.setTabError(`All mails are already sent for ${counterParty}.`);
                    return;
                }

                body = this.convertTableToJson(counter_party, directions)
                body.send_only_unsent_bid = true;
            }
            else {
                body = this.convertTableToJson(counter_party, direction_list)
            }

            body.is_test = isTest;

            this.callSendMail(body);
        }
        else {
            this.setTabError(`No bids to send for ${counterParty}.`);
        }
    }

    onAddBid(counterParty, direction) {
        var filter = {
            filter: {
                requested_date: extractDate(this.state.activeFilter.date),
                counter_party: counterParty,
                direction: direction,
                company: this.state.activeFilter.company
            }
        };

        const spinnerKey = this.startSpinner();

        getInitialBids(filter).then(response => {

            if (response.data.error)
                this.setTabError(response.data.error);

            if (response.data.warn)
                this.setWarning(response.data.warn);

            if (response.data.success) {
                this.setState({ newAuctionBid: response.data.success[0].direction_list[0] }, () => {
                    const scrollTo = document.getElementById(`end_of_${counterParty}`);
                    if (scrollTo)
                        scrollTo.scrollIntoView();
                });
            }
        }, error => {
            handleApiError(error);
        }).finally(() => {
            this.stopSpinnerFor(spinnerKey);
        });

        this.onCancelAddBid();
    }

    onCancelAddBid() {
        this.setState({ addAuctionBidModal: { ...this.state.addAuctionBidModal, show: false } });
    }

    isEditingDisabled() {
        return this.state.activeFilter.date <= new Date() || this.state.editingTables;
    }

    onExportAllButtonClick(counterParty, direction_list) {

            let tableHeaders = [[], [], []]
            let tableValues = {};
            direction_list.forEach(direction_data => {
                tableHeaders[0].push(direction_data['direction']);
                tableHeaders[0].push('');
                tableHeaders[0].push('');
                tableHeaders[0].push('');

                tableHeaders[1].push('Hour');
                tableHeaders[1].push('Amount');
                tableHeaders[1].push(direction_data['bid_number']);
                tableHeaders[1].push('');

                tableHeaders[2].push('CET');
                tableHeaders[2].push('(MWh)');
                tableHeaders[2].push('(EUR/MWh)');
                tableHeaders[2].push('');

                direction_data.data.forEach((cell, cell_idx) => {
                    if (tableValues[cell_idx]){
                        tableValues[cell_idx].push(cell['hour']);
                        tableValues[cell_idx].push(cell['amount']);
                        tableValues[cell_idx].push(cell['bid']);
                        tableValues[cell_idx].push('');
                    }
                    else{
                        tableValues[cell_idx] = []
                        tableValues[cell_idx].push(cell['hour']);
                        tableValues[cell_idx].push(cell['amount']);
                        tableValues[cell_idx].push(cell['bid']);
                        tableValues[cell_idx].push('');
                    }
                })
            })
            
            createExcel(
                tableHeaders,
                Object.values(tableValues),
                `AuctionBids_${counterParty}_${extractDate(this.state.activeFilter.date)}`
            );
    }

    renderTabs() {
        if ((!this.state.activeFilter.date && !this.state.counterParties)
            || !this.state.activeFilter
            || !this.state.activeFilter.borders)
            return;

        const counterPartiesToList = {};

        this.state.activeFilter.borders.forEach(tag => {
            Object.keys(this.state.borderDetails[tag.id]).forEach(counterParty => {
                counterPartiesToList[counterParty] = { counter_party: counterParty };
            });
        });

        if (this.state.counterParties) {
            this.state.counterParties.forEach(counterParty => {
                counterPartiesToList[counterParty.counter_party] = counterParty;
            });
        }

        let finalCounterPartiesToList = {};
        this.state.activeFilter.counterParties.forEach(cp => finalCounterPartiesToList[cp] = counterPartiesToList[cp]);

        return Object.keys(finalCounterPartiesToList).sort().map(counterParty => {
            const { counter_party, direction_list } = finalCounterPartiesToList[counterParty];

            return (
                <VTab key={counter_party}
                    eventKey={counter_party}
                    title={counter_party}>

                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'
                                style={{ textAlign: 'right', margin: '5px 0 0 0', padding: '0' }}>
                                <button className="btn v-button v-tab-button"
                                    disabled={this.isEditingDisabled()}
                                    onClick={(e) => this.onAddBidButtonClick(counter_party)}>
                                    <i aria-hidden="true" className="fa fa-plus fa-fw" />
                                    Add Bid
                                </button>
                                <button className="btn v-button v-tab-button"
                                    disabled={this.isEditingDisabled() || !direction_list || direction_list.length === 0}
                                    onClick={(e) => this.onSendAllToMeButtonClick(counter_party)}>
                                    <i aria-hidden="true" className="fa fa-share fa-fw" />
                                    Send All To Me
                                </button>
                                <button className="btn v-button v-tab-button"
                                    disabled={this.isEditingDisabled() || !direction_list || direction_list.length === 0}
                                    onClick={(e) => this.onSendAllButtonClick(counter_party)}>
                                    <i aria-hidden="true" className="fa fa-share fa-fw" />
                                    Send All To Counter Party
                                </button>
                                <button className="btn v-button v-tab-button"
                                    disabled={this.isEditingDisabled() || !direction_list || direction_list.length === 0}
                                    onClick={(e) => this.onExportAllButtonClick(counter_party, direction_list)}>
                                    <i aria-hidden="true" className="fa fa-download fa-fw" />
                                    Export All Bids
                                </button>
                            </div>
                        </div>
                        {
                            ((!direction_list || direction_list.length === 0) && !this.state.newAuctionBid) ?
                                <div className='row'>
                                    <span
                                        style={{
                                            fontWeight: '600',
                                            margin: '5px',
                                            padding: '5px',
                                        }}>
                                        No bids for {counter_party}
                                    </span>
                                </div>
                                :
                                <div className='row'>
                                    <div className='v-infinite-width v-table-list'>
                                        {
                                            (direction_list && direction_list.length > 0) &&
                                            direction_list.map(direction_details => {
                                                return this.createSavedDataTable(counter_party, direction_details);
                                            })
                                        }
                                        {
                                            this.state.newAuctionBid &&
                                            this.createNewDataTable(counter_party)
                                        }
                                        <div id={`end_of_${counter_party}`}></div>
                                    </div>
                                </div>
                        }
                    </div>
                </VTab>
            )
        });
    }

    onSaveTable = async (counterParty, oldItemsJson, newItemsTableData) => {

        let numParts;
        for (let k = 0; k < newItemsTableData.values.length; k++) {
            if (newItemsTableData.values[k][this.amountColumn.columnIndex] < 0) {
                this.setTabError(portalMessages.AUCTION_BIDS.AUCTION_NO_NEGATIVE);
                return false;
            }

            if (newItemsTableData.values[k][this.bidColumn.columnIndex] < 0) {
                this.setTabError(portalMessages.AUCTION_BIDS.BID_TOO_BIG_OR_SMALL);
                return false;
            }

            numParts = newItemsTableData.values[k][this.bidColumn.columnIndex].toString().split('.');
            if (numParts.length > 1 && numParts[1].length > 2) {
                this.setTabError(portalMessages.AUCTION_BIDS.BIDS_FRACTIONS);
                return false;
            }
        }

        const body = this.convertTableToJson(counterParty, oldItemsJson, newItemsTableData);

        const spinnerKey = this.startSpinner();

        let response;

        try {
            response = await saveAuctionBids(body);
        } catch (error) {
            handleApiError(error);
            this.stopSpinnerFor(spinnerKey);
            return false;
        }

        if (response.data.success) {
            const activeFilter = this.state.activeFilter;

            await this.getAuctionBidsForFilter({
                filter: {
                    date: extractDate(activeFilter.date),
                    borders: activeFilter.borders.map(b => b.id),
                    company: activeFilter.company
                }
            });

            if (oldItemsJson.bid_id)
                this.showSuccessMessage(portalMessages.AUCTION_BIDS.BIDS_UPDATED_SUCCESSFULLY);
            else
                this.showSuccessMessage(portalMessages.AUCTION_BIDS.BIDS_CREATED_SUCCESSFULLY)

            this.setState({ editingTables: false })
            this.stopSpinnerFor(spinnerKey);
            return true;
        }
        else {
            this.setTabError(response.data.error);
            this.stopSpinnerFor(spinnerKey);
            return false;
        }
    }

    updateIfInconsistent = async () => {
        if (!this.state.inconsistentDataCaught)
            return;

        const spinnerKey = this.startSpinner();

        const activeFilter = this.state.activeFilter;

        await this.getAuctionBidsForFilter({
            filter: {
                date: extractDate(activeFilter.date),
                borders: activeFilter.borders.map(b => b.id),
                company: activeFilter.company
            }
        });

        this.setState({ inconsistentDataCaught: false });
        this.stopSpinnerFor(spinnerKey);
    }

    onSendMail(counterParty, oldItemsJson, bidId) {
        const body = this.convertTableToJson(counterParty, oldItemsJson);
        body.is_test = false;

        this.callSendMail(body);
    }

    callSendMail = async (body) => {
        const spinnerKey = this.startSpinner();

        try {
            const response = await sendAuctionBids(body)

            if (response.data.success) {
                const activeFilter = this.state.activeFilter;
                console.log("activeFilter", activeFilter)
                await this.getAuctionBidsForFilter({
                    filter: {
                        date: extractDate(activeFilter.date),
                        borders: activeFilter.borders.map(b => b.id),
                        company: activeFilter.company
                    }
                });

                this.showSuccessMessage(portalMessages.MAIL_SENT_SUCCESSFULLY);
            }

            if (response.data.error)
                this.setTabError(response.data.error);
        } catch (error) {
            handleApiError(error);
        } finally {
            this.stopSpinnerFor(spinnerKey);
        }
    }

    onTableDelete(bid_id, direction, counterParty) {
        const spinnerKey = this.startSpinner();

        const filter = {
            bid_id: bid_id,
            direction: direction,
            counter_party: counterParty,
            requested_date: extractDate(this.state.activeFilter.date),
            company: this.state.activeFilter.company,
        }

        deleteBid(filter).then(response => {
            if (response.data.success) {
                const activeFilter = this.state.activeFilter;

                this.getAuctionBidsForFilter({
                    filter: {
                        date: extractDate(activeFilter.date),
                        borders: activeFilter.borders.map(b => b.id),
                        company: activeFilter.company,
                    }
                });

                this.showSuccessMessage(portalMessages.AUCTION_BIDS.BIDS_DELETED_SUCCESSFULLY);
            }

            if (response.data.error)
                this.setTabError(response.data.error);
        }, error => {
            handleApiError(error);
        }).finally(() => {
            this.stopSpinnerFor(spinnerKey);
        });
    }

    getConfirmationMessage(message, counterParty, direction, bidNo, warning = "") {
        return (
            <div className='v-auction-confirmation'>
                <div className='container'>
                    <div className='row'>
                        <div className='col'>
                            {warning.length > 0 &&
                                <span className='v-auction-warning'>{warning}</span>}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            {message}
                        </div>
                    </div>
                    <div className='row'>
                        <div style={{ whiteSpace: 'nowrap' }} className='col'>
                            Counter Party:
                        </div>
                        <div className='col-7'>
                            <strong>{counterParty}</strong>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            Direction:
                    </div>
                        <div className='col-7'>
                            <strong>{direction}</strong>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            Bid Number:
                        </div>
                        <div className='col-7'>
                            <strong>{bidNo}</strong>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    createSavedDataTable(counterParty, directionDetails) {
        const { bid_id, direction, bid_number } = directionDetails;

        return (
            <VTable
                key={bid_id}
                simpleNumbers
                showAverage
                customColumnClasses={{ 0: "v-column-narrow-bold" }}
                onError={(message) => this.setTabError(message)}
                onOpenEditMode={() => this.setState({ editingTables: true })}
                onCloseEditMode={() => {
                    this.setState({ editingTables: false });
                    this.updateIfInconsistent();
                }}
                onSendMail={() => this.onSendMail(counterParty, directionDetails, bid_id)}
                onTableDelete={() => this.onTableDelete(bid_id, direction, counterParty)}
                deleteMessage={this.getConfirmationMessage(portalMessages.AUCTION_BIDS.DELETE_BIDS,
                    counterParty, direction, bid_number)}
                sendMailMessage={this.getConfirmationMessage(directionDetails.mail_sent ?
                    portalMessages.RESEND_SENT_MAIL : portalMessages.AUCTION_BIDS.SEND_MAIL,
                    counterParty, direction, bid_number)}
                onSaveChangesAsync={(newData) => this.onSaveTable(counterParty, directionDetails, newData)}
                title={direction}
                exportFileName={`AuctionBids_${counterParty}_${direction}_${extractDate(this.state.activeFilter.date)}`}
                inputType='number'
                items={this.convertJsonToTable(directionDetails)}
                readonlyColumns={[this.hoursColumn.title]}
                scales={{ '*': 2, [this.amountColumn.title]: -1 }}
                headerButtons={{
                    showEditButton: !this.isEditingDisabled(),
                    showExportExcelButton: true,
                    showEmailButton: !this.isEditingDisabled(),
                    showDeleteTableButton: !this.isEditingDisabled(),
                    showImportExcelButton: !this.isEditingDisabled(),
                }}
            />
        );
    }

    createNewDataTable(counterParty) {
        return (
            <VTable
                key={'newBid'}
                simpleNumbers
                customColumnClasses={{ 0: "v-column-narrow-bold" }}
                onError={(message) => this.setTabError(message)}
                editModeOn={true}
                onOpenEditMode={() => this.setState({ editingTables: true })}
                onCloseEditMode={() => {
                    this.setState({ newAuctionBid: null, editingTables: false });
                    this.updateIfInconsistent();
                }}
                onSaveChangesAsync={(newData) => this.onSaveTable(counterParty, this.state.newAuctionBid, newData)}
                title={this.state.newAuctionBid.direction}
                exportFileName={`AuctionBids_${counterParty}_${this.state.newAuctionBid.direction}_${extractDate(this.state.activeFilter.date)}`}
                inputType='number'
                items={this.convertJsonToTable(this.state.newAuctionBid)}
                readonlyColumns={[this.hoursColumn.title]}
                scales={{ '*': 2, [this.amountColumn.title]: -1 }}
                headerButtons={{
                    showImportExcelButton: true,
                    showExportExcelButton: true
                }}
            />
        );
    }

    convertTableToJson(counterParty, oldItemsJson, tableData) {
        var body = {
            requested_date: extractDate(this.state.activeFilter.date),
            counter_party: counterParty,
            company: this.state.activeFilter.company,
            direction_list: []
        };

        if (isArray(oldItemsJson)) {
            // multiple bids

            body.direction_list = oldItemsJson.map(item => {
                return {
                    direction: item.direction,
                    bid_id: item.bid_id,
                    bid_number: item.bid_number,
                    mail_sent: item.mail_sent,
                    previous_data: item.data
                };
            });
        }
        else {
            // single bid
            const direction = {
                direction: oldItemsJson.direction
            };

            direction.bid_id = oldItemsJson.bid_id;
            direction.bid_number = oldItemsJson.bid_number;
            direction.mail_sent = oldItemsJson.mail_sent;

            // adding old data for update or email
            if (oldItemsJson.bid_id) {
                direction.previous_data = oldItemsJson.data;
            }

            // adding new data for update or adding new bid
            if (tableData && tableData.values && tableData.values.length > 0) {
                direction.updated_data = tableData.values.map(value => {
                    return {
                        [this.hoursColumn.columnName]: Number(value[this.hoursColumn.columnIndex]) - 1,
                        [this.amountColumn.columnName]: Number(value[this.amountColumn.columnIndex]),
                        [this.bidColumn.columnName]: Number(value[this.bidColumn.columnIndex]),
                    }
                });
            }

            body.direction_list.push(direction);
            body.bid_id = oldItemsJson.bid_id;
        }

        return body;
    }

    convertJsonToTable(directionDetails) {
        const { bid_number, data, mail_sent } = directionDetails;

        let headers = [
            [this.hoursColumn.title, this.amountColumn.title, this.bidColumn.title.replace(this.bidNumStr, bid_number)],
            [this.hoursColumn.unit, this.amountColumn.unit, this.bidColumn.unit]
        ];

        let values = [];

        data.sort((a, b) => a[this.hoursColumn.columnName] - b[this.hoursColumn.columnName]); //order by hours

        for (let i = 0; i < data.length; i++) {
            const currentRow = data[i];
            values.push([])
            values[i].push(currentRow[this.hoursColumn.columnName] + 1)
            values[i].push(currentRow[this.amountColumn.columnName])
            values[i].push(currentRow[this.bidColumn.columnName])
        }

        return {
            mailAlreadySent: mail_sent,
            readonly: true,
            headers,
            values
        };
    }

    onSelectedTabChanged(key) {
        this.setState({ selectedCounterParty: key })
    }

    getAvaliableDirections(counterParty) {
        const directionsOfCounterParty = this.state.counterPartyDetails[counterParty];
        const shownBorders = this.state.activeFilter.borders;
        const directionBorderPairs = this.state.directionDetails;

        return directionsOfCounterParty.filter(direction =>
            shownBorders.filter(border =>
                border.id === directionBorderPairs[direction]
            ).length > 0
        );
    }

    render() {
        return (
            <React.Fragment>
                <VContentContainer title="Auction Bids">

                    <VFilterContainer showActiveFilter activeFilter={this.state.activeFilterToDisplay}>
                        <div className="v-filter-group">
                            <div className="v-filter-label v-label">
                                Date
                            </div>
                            <div>
                                <VDatePicker
                                    disabled={this.state.editingTables}
                                    selectedDate={this.state.selectedDate}
                                    onSelectedDateChange={(selectedDate) => this.setState({ selectedDate })}
                                    maxDate={getDate(2)}
                                />
                            </div>
                        </div>
                        <div className="v-filter-group">
                            <div className="v-filter-label v-label">
                                Counter Party
                            </div>
                            <div className="v-filter-taginput v-auction-bid">
                                <VTagInput
                                    disabled={true}
                                    addStyle={AddStyles.Textbox}
                                    tags={this.state.counterPartyTags}
                                />
                            </div>
                        </div>
                        <div className="v-filter-buttons">
                            <button
                                disabled={this.state.editingTables}
                                className="btn v-cancel-button v-filter-button"
                                onClick={() => this.onClearButtonClick()}>
                                <i aria-hidden="true" className="fa fa-eraser fa-fw" />Clear
                            </button>
                            <button
                                disabled={this.state.editingTables}
                                tabIndex={0}
                                className="btn v-button v-filter-button"
                                onClick={() => this.onShowButtonClick()}>
                                <i aria-hidden="true" className="fa fa-search fa-fw" />Show
                             </button>
                        </div>
                    </VFilterContainer>
                    {(!_.isEmpty(this.state.activeFilter) && this.state.activeFilter.date) &&
                        <VMainContainer>
                            <VTabs
                                onSelect={(key) => { this.onSelectedTabChanged(key) }}
                                disableAllTabs={this.state.editingTables}
                                disabledAllMessage={portalMessages.TAB_SELECTION}>
                                {this.renderTabs()}
                            </VTabs>
                        </VMainContainer>
                    }
                    {(this.state.addAuctionBidModal && this.state.addAuctionBidModal.counterParty) &&
                        <AddAuctionBidModal
                            show={this.state.addAuctionBidModal.show}
                            availableDirections={this.getAvaliableDirections(this.state.addAuctionBidModal.counterParty)}
                            counterParty={this.state.addAuctionBidModal.counterParty}
                            onAdd={(counterParty, selectedDirection) => this.onAddBid(counterParty, selectedDirection)}
                            onCancel={() => this.onCancelAddBid()}
                        />}
                </VContentContainer>
                <SendAuctionEmailsModal
                    show={this.state.showSendAllModal && this.state.sentMailExist}
                    onHide={() => this.setState({ showSendAllModal: false })}
                    counterParty={this.state.selectedCounterParty}
                    onSendAllBids={() => this.onSendAllBids(this.state.selectedCounterParty)}
                    onSendOnlyNewBids={() => this.onSendOnlyNewBids(this.state.selectedCounterParty)}
                />
                <ConfirmationModal
                    show={this.state.showSendAllModal && !this.state.sentMailExist}
                    confirmText='Send'
                    message={portalMessages.AUCTION_BIDS.SEND_ALL_BIDS.replace('[COUNTER_PARTY]', this.state.selectedCounterParty)}
                    onCancel={() => this.setState({ showSendAllModal: false })}
                    onHide={() => this.setState({ showSendAllModal: false })}
                    onConfirm={() => this.onSendOnlyNewBids(this.state.selectedCounterParty)}
                />
                <NeedRefreshModal
                    show={this.state.showNeedsRefresModal}
                    message={portalMessages.BORDERS_NOT_FOUND}
                />
            </React.Fragment>
        );
    }
};

export default AuctionBids;