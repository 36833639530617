import React from 'react';
import Divider from '@material-ui/core/Divider';
import Checkbox from "@material-ui/core/Checkbox";
import AccordionActions from '@material-ui/core/AccordionActions';
import MultipleSelect from '../../components/MultipleSelect/MultipleSelect';
import TDatePicker from '../../components/TDatePicker/TDatePicker';
import { TDatePickerTypes } from '../../components/TDatePicker/TDatePicker';
import moment from 'moment';
import { getLocalStorage, setLocalStorage } from '../../../helpers/localStorageHelper';

const localStorageConfig = {
    counterParty: {
        ownerPage: "resaleRevenue",
        ownerElement: "filters",
        key:"selectedCounterParties",
        matchKey: "value"
    },
    company: {
        ownerPage: "resaleRevenue",
        ownerElement: "filters",
        key: "selectedCompanies",
        matchKey: "value"
    },
    tso: {
        ownerPage: "resaleRevenue",
        ownerElement: "filters",
        key: "selectedTSO",
        matchKey: "value"
    },
    direction: {
        ownerPage: "resaleRevenue",
        ownerElement: "filters",
        key: "selectedDirection",
        matchKey: "value"
    },
    startDate: {
        ownerPage: "resaleRevenue",
        ownerElement: "filters",
        key: "selectedStartDate",
    },
    endDate: {
        ownerPage: "resaleRevenue",
        ownerElement: "filters",
        key: "selectedEndDate",
    }
}

class ResaleRevenueFilter extends React.Component {
    state = {
        selectedTSO: this.getOrElseLocalStorageAsList(localStorageConfig.tso, this.props.tsoNames),
        selectedDirections: this.getOrElseLocalStorageAsList(localStorageConfig.direction, this.props.directionNames),
        selectedCompanies: this.getOrElseLocalStorageAsList(localStorageConfig.company, this.props.companyNames),
        selectedCounterParties: this.getOrElseLocalStorageAsList(localStorageConfig.counterParty, this.props.counterPartyNames),
        selectedStartDate: null,
        selectedEndDate: new Date((new Date().setDate(new Date().getDate() + 1))).setHours(0, 0, 0, 0),
        companyNames: this.props.companyNames,
        counterPartyNames: this.props.counterPartyNames,
        tsoNames: this.props.tsoNames,
        directionNames: this.props.directionNames,
    };

    getOrElseLocalStorage(config, defaultValue) {
        let localStorageValue = getLocalStorage(config.ownerPage, config.ownerElement, config.key)
        if (localStorageValue){
            return localStorageValue
        }

        return defaultValue
    }

    getOrElseLocalStorageAsList(config, currentData) {
        let localStorageList = getLocalStorage(config.ownerPage, config.ownerElement, config.key)
       
        if (!localStorageList || !localStorageList.length) {
            return currentData
        } else {
            let existingList = currentData.filter(x => localStorageList.includes(x[config.matchKey]))

            if (existingList.length) {
                return existingList
            } else {
                return currentData
            }
        }
    }

    setPnlLocalStorageAsList(config, data) {
        let dataList = data.map(x => x[config.matchKey])
        setLocalStorage(config.ownerPage, config.ownerElement, config.key, dataList)
    }

    setPnlLocalStorage(config, data) {
        setLocalStorage(config.ownerPage, config.ownerElement, config.key, data)
    }

    componentDidMount() {
        this.onShowButtonClick();
    }

    onShowButtonClick() {
        let filter = {
            start_date: this.state.selectedStartDate ? moment(this.state.selectedStartDate).utcOffset(0, true).format() : null,
            end_date: moment(this.state.selectedEndDate).utcOffset(0, true).format(),
            company_list: this.state.selectedCompanies?.map(c => c.value === "Vitus Commodities" ? "Vitus" : c.value),
            counter_party_list: this.state.selectedCounterParties?.map(c => c.value),
            tso_list: this.state.selectedTSO?.map(m => m.value),
            direction_list: this.state.selectedDirections?.map(m => m.value)
        }
        
        this.props.onShowButtonClick(filter)
    }

    onTSOChange = selectedTSO => {
        this.setState({ selectedTSO: selectedTSO }, () => {
            this.setPnlLocalStorageAsList(localStorageConfig.tso, selectedTSO);
        });
    }

    onDirectionChange = selectedDirections => {
        this.setState({ selectedDirections: selectedDirections }, () => {
            this.setPnlLocalStorageAsList(localStorageConfig.direction, selectedDirections);
        });
    }

    onCompanyChange = selectedCompanies => {
        this.setState({ selectedCompanies: selectedCompanies }, () => {
            this.setPnlLocalStorageAsList(localStorageConfig.company, selectedCompanies);
        });
    }

    onCompanyCounterParty = selectedCounterParties => {
        this.setState({ selectedCounterParties: selectedCounterParties }, () => {
            this.setPnlLocalStorageAsList(localStorageConfig.counterParty, selectedCounterParties);
        });
    }

    onChangeStartDate = startDate => {
        this.setState({ selectedStartDate: startDate }, () => {
            this.setPnlLocalStorage(localStorageConfig.startDate, startDate);
        })
    }

    onChangeEndDate = endDate => {
        this.setState({ selectedEndDate: endDate }, () => {
            this.setPnlLocalStorage(localStorageConfig.endDate, endDate);
        })
    }

    render() {
        return (
            <div style={{ "marginTop": "10px" }}>
                <div style={{ "marginTop": "10px", display: "flex", flexDirection: "row" }}>
                    <div style={{ "margin": "5px", "width": "70%" }}>
                        <MultipleSelect
                            title="TSO"
                            items={this.props.tsoNames}
                            onChange={this.onTSOChange}
                            selectedItems={this.state.tsoNames}
                        >
                        </MultipleSelect>
                        <MultipleSelect
                            title="Directions"
                            items={this.props.directionNames}
                            onChange={this.onDirectionChange}
                            selectedItems={this.state.directionNames}
                        >
                        </MultipleSelect>
                        <MultipleSelect
                            title="Companies"
                            items={this.props.companyNames}
                            onChange={this.onCompanyChange}
                            selectedItems={this.state.selectedCompanies}
                        >
                        </MultipleSelect>
                        <MultipleSelect
                            title="Counter Parties"
                            items={this.props.counterPartyNames}
                            onChange={this.onCompanyCounterParty}
                            selectedItems={this.state.selectedCounterParties}
                        >
                        </MultipleSelect>
                    </div>
                    <div style={{ "margin": "5px", "width": "45%" }}>                        
                        <TDatePicker
                            type={TDatePickerTypes.month}
                            label={"Start Date"}
                            selectedDate={this.state.selectedStartDate}
                            onSelectedDateChange={this.onChangeStartDate}
                        />

                        <TDatePicker
                            type={TDatePickerTypes.month}
                            label={"End Date"}
                            selectedDate={this.state.selectedEndDate}
                            onSelectedDateChange={this.onChangeEndDate}
                        />
                    </div>
                    

                </div>
                <div style={{ "margin": "12px", "paddingRight": "0px" }}>
                    <Divider />
                    <AccordionActions>
                        <button
                            size="small"
                            className="btn t-orange-button"
                            onClick={() => this.onShowButtonClick()}
                        >
                            Apply
                        </button>
                    </AccordionActions>
                </div>

            </div>
        );
    }
}

export default ResaleRevenueFilter;