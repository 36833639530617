import './ExchangeOffers.css';

import { isEmpty, sortBy } from 'lodash';
import React from 'react';
import VContentContainer from '../../components/VContentContainer/VContentContainer';
import VDatePicker from '../../components/VDatePicker/VDatePicker';
import VDropdown from '../../components/VDropdown/VDropdown';
import VFilterContainer from '../../components/VFilterContainer/VFilterContainer';
import VMainContainer from '../../components/VMainContainer/VMainContainer';
import { SpinnerManager } from '../../components/VSpinner/SpinnerManager';
import VTable, { AggType } from '../../components/VTable/VTable';
import VTabs, { VTab } from '../../components/VTabs/VTabs';
import { alertError, alertSuccess, alertWarning, handleApiError, specifyErrorMessage } from '../../helpers/errorHelper';
import { extractDate, getDate, getCompanyOptions, isCompanySelectable } from '../../helpers/generalHelper';
import { portalMessages } from '../../helpers/portalMessages';
import history from '../../history';
import NeedRefreshModal from '../../modals/NeedRefreshModal/NeedRefreshModal';
import {
    getExchangeOffers,
    getOfferParameters,
    saveExchangeOffers,
    getObligedAmounts,
    saveObligedAmounts,
    sendExchangeOffers,
    deleteObligedAmounts,
    getExchangeMatrix,
    getNextLevelOffers,
    deleteOffer,
    getHorizontalTable
} from '../../apis/vitusApi';
import { createExcel, loadExcel } from '../../helpers/excelHelper';
import VFileUploadIcon from '../../components/VFileUploadIcon/VFileUploadIcon';
import ExportFileModal from '../../modals/ExportFileModal/ExportFileModal';
import ConfirmationModal from '../../modals/ConfirmationModal/ConfirmationModal';
import ResetTableModal from '../../modals/ResetTableModal/ResetTableModal';
import { getLocalStorage, setLocalStorage } from '../../helpers/localStorageHelper';
import VMatrix from '../../components/VMatrix/VMatrix';
import VComponentSpinner from '../../components/VComponentSpinner/VComponentSpinner';
import VHorizontalTable from '../../components/VHorizontalTable/VHorizontalTable';
import ControlLevels from './ControlLevels/ControlLevels';
import ServicingOffers from './ServicingOffers/ServicingOffers';

class ExchangeOffers extends React.Component {
    exchangeCountryCodes = { 'ENEX': 'gr', 'IBEX': 'bg', 'HUPX': 'hu', 'OPCOM': 'ro', 'SEEPEX': 'rs', 'EPIAS': 'tr', 'CROPX': 'hr', 'IPEX': 'it', 'MEPX': 'me', 'HENEX': 'gr' };
    anyPriceSpecialValue = "Any price";
    matrixFormatKey = "Matrix";
    priceScale = 2;
    matrixOfferScale = 2;
    matrixCapacityScale = 1;
    specialProfileScale = 1;
    specialProfileScalePairs = { 'IBEX': ['Energovia'] };
    spinner = new SpinnerManager(history.location.pathname);
    borderTableRefs = [];
    editingTableCount = 0;
    editingOperationType = "";
    editingObligedTableRefs = {};
    storedActiveFilter = getLocalStorage('exchangeOffers', 'filters', 'activeFilter',) || {};
    controlDataTag = 'control';
    servicingDataTag = 'servicing';
    totalDataTag = 'total';
    ownerCompany = 'vitus';
    ipex = 'IPEX';
    energovia = 'Energovia';
    obligedTableRef = React.createRef();

    operationTypes = {
        buy: { key: 'buy', title: 'Buy' },
        sell: { key: 'sell', title: 'Sell' },
        all: { key: 'all' }
    };

    tabKeys = {
        result: "Result",
        vitus: "Vitus",
        total: "Total",
        servicing: "Servicing"
    };

    viewTypes = {
        bordered: 1, // Seepex and derivations
        levelled: 2  // Opcom
    };

    configInitials = {
        disabled: false,
        showSelf: true,
        showObliged: false,
        showServicing: false,
        showTotal: false,
        showResult: false,
        type: null,
        validateExchangeResults: false,
        requiresBorderOnServicing: false,
        sendMailEnabled: true,
        doNotValidateProfilePrice: false
    };

    exchangeConfig = {
        default: this.configInitials,
        epias: {
            vitus: {
                ...this.configInitials,
                showSelf: false,
                type: this.viewTypes.bordered,
                showServicing: true,
                showTotal: false,
                showResult: false,
                requiresBorderOnServicing: true,
                sendMailEnabled: false,
                doNotValidateProfilePrice: true
            }
        },
        seepex: {
            energovia: {
                ...this.configInitials,
                showObliged: true,
                type: this.viewTypes.bordered,
                showServicing: true,
                showTotal: true,
                showResult: true
            }
        },
        ibex: {
            energovia: {
                ...this.configInitials,
                showTotal: true,
                showObliged: true,
                type: this.viewTypes.bordered,
                showResult: true,
                validateExchangeResults: true,
                showServicing: true
            },
            axpo: {
                ...this.configInitials,
                showObliged: true,
                type: this.viewTypes.bordered,
                showResult: true,
                showServicing: true,
                showTotal: true
            }
        },
        henex: {
            energovia: {
                ...this.configInitials,
                showObliged: false,
                type: this.viewTypes.bordered,
                showServicing: true,
                showTotal: true
            }
        },
        enex: {
            energovia: {
                ...this.configInitials,
                showObliged: false,
                type: this.viewTypes.bordered,
                showServicing: true,
                showTotal: true
            },
            ensco: {
                ...this.configInitials,
                showObliged: false,
                type: this.viewTypes.bordered,
                showTotal: true,
                showServicing: true,
                showTotal: true
            },
            green: {
                ...this.configInitials,
                showObliged: false,
                type: this.viewTypes.bordered,
                showServicing: true,
                showTotal: true
            }
        },
        opcom: {
            energovia: {
                ...this.configInitials,
                showObliged: true,
                type: this.viewTypes.levelled,
                showServicing: true,
                disableServicing: true,
                showTotal: true,
                showResult: true
            }
        },
        hupx: {
            energovia: {
                ...this.configInitials,
                showObliged: true,
                type: this.viewTypes.bordered,
                showServicing: true,
                showTotal: true,
                showResult: true
            }
        },
        cropx: {
            energovia: {
                ...this.configInitials,
                showObliged: true,
                type: this.viewTypes.bordered,
                showServicing: true,
                showTotal: true,
                showResult: true
            }
        },
        ipex: {
            energovia: {
                ...this.configInitials,
                showObliged: true,
                type: this.viewTypes.bordered,
                requiresBorderOnServicing: true,
                showServicing: true,
                showTotal: true,
                showResult: true
            }
        },
        mepx: {
            energovia: {
                ...this.configInitials,
                showObliged: true,
                type: this.viewTypes.bordered,
                showServicing: true,
                showTotal: true,
                showResult: true
            }
        }
    };

    state = {
        selectedDate: getDate(1),
        allTablesList: [],
        borderList: [],
        obligedAmounts: [],
        controlList: [],
        total: [],
        totalObliged: [],
        activeFilter: {
            date: null,
            exchange: this.storedActiveFilter?.exchange,
            counterParty: this.storedActiveFilter?.counterParty,
        },
        showNeedsRefresModal: false,
        exchanges: {},
        selectedExchange: null,
        selectedCounterParty: null,
        exchangeOptions: [],
        counterPartyOptions: [],
        selectedExchangeOption: null,
        selectedCounterPartyOption: null,
        selectedCounterPartyConfig: this.getCounterPartyConfigForActiveFilter(),
        editingTables: {
            editingObliged: false,
            editingBorderTables: false
        },
        inconsistentDataCaught: false,
        editingTableCount: 0,
        showExportOptions: false,
        showConfirmCancelModal: false,
        mailSent: null,
        showSendMailModal: false,
        showResetSeepexObligedModal: false,
        currencyList: [],
        matrixData: {},
        horizontalTableData: {},
        activeTab: null,
        newOfferLevel: {},
        showAddLevelModal: false,
        showDeleteLevelledObligedDeleteModal: false,
        showEditLevelledObligedDeleteModal: false,
        activeFilterToDisplay: [],
        borderToReset: {},
        showResetBorderModal: false,
        showIrregularMailModal: false,
        irregularMailConfirmed: false,
        irregularMailBody: null,
        exchangeResultList: [],
        servicingCompanies: {},
        showObligedCheckListModal: false,
        showLevelObligedCheckListModal: false,
        showSendEmailCheckListModal: false,
        obligedCheckList: [],
        ignoreObligedCheck: false
    };

    currencyLabel = '[CURRENCY]';

    columns = {
        eur: { title: "EUR" },
        hour: { key: "hour", title: "Hour" },
        sell: { key: "sell", title: "Sell", unit: "MWh" },
        buy: { key: "buy", title: "Buy", unit: "MWh" },
        cet: { key: "", title: "CET" },
        profile: { key: "profile", title: "Profile", unit: "MWh" },
        price_offer: { key: "price_offer", title: "Price Offer", unit: `${this.currencyLabel}/MWh` },

        total:
        {
            st: { key: "st", title: "Vitus", order: 1 },
            servicing: { key: "servicing", title: "Servicing", order: 2 },
            lt: { key: "lt", title: "LT", order: 3 },
            total: { key: "total", title: "Total", order: 4 }
        },

        obligedAmounts: {
            lt: {
                key: "lt",
                sell: { title: "LT Sell" },
                buy: { title: "LT Buy" }
            },
            st: {
                key: "st",
                sell: { title: "Sell" },
                buy: { title: "Buy" }
            }
        },

        levelledObligedAmounts: {
            borders: {
                [this.getBorderKey('RO<>BG', 'lt')]: {
                    border: 'RO<>BG',
                    title: 'RO<>BG',
                    type: 'lt',
                    key: this.getBorderKey('RO<>BG', 'lt'),
                    order: 1,
                    unit: 'MWh',
                    index: -1,
                    term: 'LT'
                },
                [this.getBorderKey('RS<>RO', 'st')]: {
                    border: 'RS<>RO',
                    title: 'RO<>RS',
                    type: 'st',
                    key: this.getBorderKey('RS<>RO', 'st'),
                    order: 2,
                    unit: 'MWh',
                    index: -1,
                    term: 'ST'
                },
                /*[this.getBorderKey('RO<>BG', 'st')]: {
                    border: 'RO<>BG',
                    title: 'RO<>BG',
                    type: 'st',
                    key: this.getBorderKey('RO<>BG', 'st'),
                    order: 3,
                    unit: 'MWh',
                    index: -1,
                    term: 'ST'
                }
                */
            },
            total: {
                name: 'Total Allocated',
                unit: 'MWh'
            }
        }
    };

    getBorderKey(border, type) {
        return `${border}_${type}`;
    }

    columnIndices = {
        border: {
            borderHour: () => { return 0 },
            borderSellProfile: () => { return 1 },
            borderSellPriceOffer: () => { return 2 },
            borderSellCustomCurrencyPriceOffer: () => { return 3 },
            // buy indices depend on how many columns are listed becase of different currencies
            borderBuyProfile: (columCount) => { return Math.floor(columCount / 2) + 1 },
            borderBuyPriceOffer: (columCount) => { return Math.floor(columCount / 2) + 2 },
            borderBuyCustomCurrencyPriceOffer: (columCount) => { return Math.floor(columCount / 2) + 3 },

            levelProfile: () => { return 1 },
            levelPriceOffer: () => { return 2 },
            levelCustomCurrencyPriceOffer: () => { return 3 },
        },
        obligedAmounts: {
            hour: 0,
            st: { sell: 1, buy: 2 },
            lt: { sell: 3, buy: 4 }
        }
    }

    errorMessages = {
        Default: portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Default)',
        MissingParameter: {
            "requested_date": portalMessages.DATE_SELECTION,
            "counter_party": portalMessages.SELECT_COUNTER_PARTY,
            "exchange": portalMessages.SELECT_EXCHANGE
        },
        InconsistentData: portalMessages.EXCHANGE_OFFERS.INCONSISTENT_DATA,
        NotFound: portalMessages.EXCHANGE_OFFERS.OFFER_NOT_FOUND,
        InvalidData: {
            Default: portalMessages.UNEXPECTED_ERROR_OCCURED + ' (InvalidData)',
            "total_profile": {
                "sendMail": portalMessages.EXCHANGE_OFFERS.ALL_ZERO_SEND_MAIL,
                "editValues": portalMessages.EXCHANGE_OFFERS.ALL_ZERO_SAVE
            },
            "date": {
                Default: portalMessages.INVALIED_REQUESTED_DATE,
                "editValues": portalMessages.EXCHANGE_OFFERS.INVALID_DATE,
                "sendMail": portalMessages.EXCHANGE_OFFERS.INVALID_MAIL_DATE,
                "editObligedValues": portalMessages.EXCHANGE_OFFERS.INVALID_OBLIGED_DATE,
            },
            "sell_profile": portalMessages.EXCHANGE_OFFERS.INVALID_PROFILE,
            "buy_profile": portalMessages.EXCHANGE_OFFERS.INVALID_PROFILE,
            "next_level": portalMessages.EXCHANGE_OFFERS.MAX_LEVEL_COUNT,
            "level": portalMessages.EXCHANGE_OFFERS.MAX_LEVEL_COUNT,
            "sell_price_order": portalMessages.EXCHANGE_OFFERS.SELL_PRICE_ORDER,
            "buy_price_order": portalMessages.EXCHANGE_OFFERS.BUY_PRICE_ORDER,
            "sell_buy_price_order": portalMessages.EXCHANGE_OFFERS.BUY_SELL_PRICE_ORDER
        }
    };

    resultFormats = {
        verticalTable: { format: "Vertical Table", title: "Vertical Table" },
        matrix: { format: "Matrix", title: "Exchange Matrix" },
        verticalTableNoControl: { format: "Vertical Table (w/o control offer)", title: "Vertical Table (w/o control offer)" },
        horizontalTable: { format: "Horizontal Table", title: "Horizontal Table" }
    };

    showErrorMessage(error, operationName) {
        const { message, errorType } = specifyErrorMessage(error, this.errorMessages, operationName);

        if (errorType === 'InconsistentData' && !this.state.inconsistentDataCaught)
            this.setState({ inconsistentDataCaught: true });

        if (message)
            alertError(message);
    }

    getExchangeConfig(exchange) {
        return this.exchangeConfig[exchange.toLowerCase()];
    }

    getCounterPartyConfig(exchange, counterParty) {
        return this.exchangeConfig[exchange.toLowerCase()][counterParty.toLowerCase()];
    }

    getCounterPartyConfigForActiveFilter() {
        if (!this.state
            || !this.state.activeFilter
            || !this.state.activeFilter.exchange
            || !this.state.activeFilter.counterParty) {
            return this.exchangeConfig.default;
        }

        return this.getCounterPartyConfig(this.state.activeFilter.exchange, this.state.activeFilter.counterParty);
    }

    validateOperationType(operationType, expectedOperationType) {
        if (expectedOperationType === undefined)
            expectedOperationType = this.editingOperationType;
        return !expectedOperationType || operationType?.key === expectedOperationType.key;
    }

    saveAllEnabled(operationType) {
        return this.validateOperationType(operationType) && this.state.editingTables.editingBorderTables;
    }

    importAllEnabled(operationType) {
        return this.state.allTablesList
            && this.state.allTablesList.length > 0
            && !this.saveAllEnabled(operationType)
            && !this.state.editingTables.editingObliged
            && this.dateIsEditable()
            && this.validateOperationType(operationType);
    }

    exportAllEnabled(operationType) {
        return this.state.allTablesList && this.state.allTablesList.length > 0 && this.validateOperationType(operationType);
    }

    sendMailEnabled() {
        if (this.state.selectedCounterPartyConfig?.sendMailEnabled === false)
            return false;

        return this.showButtonEnabled()
            && this.exportAllEnabled()
            && this.dateIsEditable()
            && this.state.mailSent !== null
            && this.state.mailSent !== undefined; //this.state.mailSent===false (or true) enables the button
    }

    mailAlreadySent() {
        return this.state.mailSent;
    }

    showButtonEnabled() {
        return !(this.state.editingTables.editingBorderTables
            || this.state.editingTables.editingObliged);
    }

    testingEnvironment() {
        if (process.env?.REACT_APP_VITUS_API?.includes('local') || process.env?.REACT_APP_VITUS_API?.includes('test'))
            return true;
        return false;
    }

    dateIsEditable() {
        return this.state
            && this.state.activeFilter
            && this.state.activeFilter.date
            && (this.testingEnvironment() || this.state.activeFilter.date > extractDate(getDate(-4)));
    }

    editBorderEnabled(operationType) {
        return this.dateIsEditable()
            && !this.state.editingTables.editingObliged
            && this.validateOperationType(operationType);
    }

    editObligedEnabled() {
        return this.dateIsEditable()
            && !this.state.editingTables.editingBorderTables;
    }

    updateWhichTablesAreInEdit(newState) {
        if (Object.keys(newState).filter(n => this.state.editingTables[n] !== newState[n]).length > 0)
            this.setState({ editingTables: { ...this.state.editingTables, ...newState } });
    }

    onOpenBorderTableEditMode(operationType) {
        this.editingTableCount++;
        this.editingOperationType = operationType;

        this.setState({
            editingTables: {
                ...this.state.editingTables,
                editingBorderTables: (this.editingTableCount > 0)
            },
            editingTableCount: this.editingTableCount
        });
    }

    onCloseBorderTableEditMode(operationType, isNewOffer) {
        this.editingTableCount--;
        this.editingOperationType = this.editingTableCount === 0 ? "" : operationType;

        this.setState({
            editingTables: {
                ...this.state.editingTables,
                editingBorderTables: (this.editingTableCount > 0)
            },
            editingTableCount: this.editingTableCount,
            newOfferLevel: isNewOffer ? {} : this.state.newOfferLevel,
            addNewControlLevel: false
        });

        this.updateIfInconsistent();
    }

    onOpenObligedTableEditMode() {
        this.updateWhichTablesAreInEdit({ editingObliged: true });
    }

    onCloseObligedTableEditMode() {
        this.editingOperationType = "";

        this.updateWhichTablesAreInEdit({ editingObliged: false });
    }

    componentDidMount() {
        this.getParameters();
    }

    getParameters() {
        const spinnerKey = this.spinner.showSpinner();

        getOfferParameters().then(response => {
            if (response.data.success) {
                const parameters = response.data.success.offer_parameters;
                const exchanges = {};

                const servicingCompanies = {};
                const servicingBorders = {};

                parameters.forEach(p => {
                    if (!exchanges[p.exchange])
                        exchanges[p.exchange] = {};

                    exchanges[p.exchange][p.counter_party] = {
                        borders: p.borders,
                        counterParty: p.counter_party,
                        country: p.country,
                        currency: p.currency,
                        format: p.format,
                        validMinPrice: p.valid_min_price,
                        validMaxPrice: p.valid_max_price,
                        sell_directions: p.sell_direction_list,
                        buy_directions: p.buy_direction_list
                    };

                    if (p.counter_party.toLowerCase() === this.ownerCompany) {
                        if (!servicingBorders[this.ownerCompany])
                            servicingBorders[this.ownerCompany] = [];
                        servicingBorders[this.ownerCompany].push(...p.borders);
                    }
                    else if (p.counter_party === this.energovia && p.exchange === this.ipex){
                        if (!servicingCompanies[p.counter_party]){
                            servicingCompanies[p.counter_party] = [];
                            servicingCompanies[p.counter_party].push(...p.borders);
                        }
                        else{
                            servicingCompanies[p.counter_party].push(...p.borders);
                        }
                    } 
                    else {
                        if (!servicingCompanies[p.counter_party])
                            servicingCompanies[p.counter_party] = [];
                    }
                });

                Object.keys(servicingCompanies).forEach(k => {
                    servicingCompanies[k].push(...servicingBorders[this.ownerCompany]);
                    servicingCompanies[k].sort();
                });;

                const exchangeOptions = this.getAvailableExchanges(exchanges);
                let selectedExchangeOption;

                if (this.state.activeFilter.exchange) {
                    selectedExchangeOption = exchangeOptions.filter(e => e.value === this.state.activeFilter.exchange
                        && !e.isDisabled);

                    if (selectedExchangeOption.length !== 0)
                        selectedExchangeOption = selectedExchangeOption[0];
                    else
                        selectedExchangeOption = "";
                }

                if (!selectedExchangeOption) {
                    selectedExchangeOption = exchangeOptions.filter(c => !c.isDisabled)[0];
                }

                const selectedExchange = selectedExchangeOption.value;

                const counterPartyOptions = this.getAvailableCounterParties(selectedExchange, exchanges[selectedExchange]);

                let selectedCounterPartyOption;

                if (this.state.activeFilter.counterParty) {
                    selectedCounterPartyOption = counterPartyOptions.filter(c => c.value === this.state.activeFilter.counterParty
                        && !c.isDisabled);

                    if (selectedCounterPartyOption.length !== 0)
                        selectedCounterPartyOption = selectedCounterPartyOption[0];
                    else
                        selectedCounterPartyOption = "";
                }

                if (!selectedCounterPartyOption) {
                    selectedCounterPartyOption = counterPartyOptions.filter(c => !c.isDisabled)[0];
                }

                const selectedCounterParty = selectedCounterPartyOption.value;

                this.setState({
                    exchanges,
                    exchangeOptions,
                    counterPartyOptions,
                    selectedExchange,
                    selectedCounterParty,
                    selectedExchangeOption,
                    selectedCounterPartyOption,
                    servicingCompanies
                });

                if (this.state.selectedDate
                    && selectedExchange
                    && selectedCounterParty) {
                    const filter = {
                        filter: {
                            date: extractDate(this.state.selectedDate),
                            counter_party: selectedCounterParty,
                            exchange: selectedExchange
                        }
                    };

                    this.refreshBorderTablesAsync(filter);
                }
            }
            else {
                this.setState({ showNeedsRefresModal: true });
            }

            if (response.data.error)
                this.showErrorMessage(response.data.error);

        }, error => {
            handleApiError(error);
            this.setState({ showNeedsRefresModal: true });
        }).finally(() => {
            this.spinner.hideSpinner(spinnerKey);
        });
    }

    updateIfInconsistent = async () => {
        if (!this.state.inconsistentDataCaught)
            return;

        const spinnerKey = this.spinner.showSpinner();

        try {
            await this.refreshBorderTablesAsync();

            this.setState({ inconsistentDataCaught: false });
        }
        finally {
            this.spinner.hideSpinner(spinnerKey);
        }
    }

    getAvailableExchanges(exchanges) {
        if (!exchanges)
            return [];

        const exchangeOptions = Object.keys(exchanges).map(e => {
            const exchangeConfig = this.getExchangeConfig(e);

            return {
                value: e,
                label: <div><span className={`v-flag-icon flag-icon flag-icon-${this.exchangeCountryCodes[e]}`} /> {e}</div>,
                isDisabled: exchangeConfig ? exchangeConfig.disabled : true
            };
        });

        return exchangeOptions;
    }

    getAvailableCounterParties(exchange, counterParties) {
        if (!counterParties)
            return [];

        const counterPartyOptions = Object.keys(counterParties).map(c => {
            const counterPartyConfig = this.getExchangeConfig(exchange)[c.toLowerCase()];

            return {
                value: c,
                label: c,
                isDisabled: counterPartyConfig ? counterPartyConfig.disabled : true
            };
        });

        return counterPartyOptions;
    }

    refreshBorderTablesAsync = async (filter) => {
        if (!filter)
            filter = {
                filter: {
                    date: this.state.activeFilter.date,
                    counter_party: this.state.activeFilter.counterParty,
                    exchange: this.state.activeFilter.exchange
                }
            };

        return await this.getSeepexLikeOfferTablesAsync(filter)
    }

    onShowButtonClick() {
        if (!this.state.selectedDate) {
            this.showErrorMessage(portalMessages.DATE_SELECTION);
            return;
        }

        if (!this.state.selectedCounterParty) {
            this.showErrorMessage(portalMessages.SELECT_COUNTER_PARTY);
            return;
        }

        if (!this.state.selectedExchange) {
            this.showErrorMessage(portalMessages.SELECT_EXCHANGE);
            return;
        }


        const filter = {
            filter: {
                date: extractDate(this.state.selectedDate),
                counter_party: this.state.selectedCounterParty,
                exchange: this.state.selectedExchange
            }
        };

        this.refreshBorderTablesAsync(filter);
    }

    onClearButtonClick() {
        this.setState({ selectedDate: getDate(1) });
    }

    getCurrentExchange() {
        if (!this.state.activeFilter.exchange
            || !this.state.activeFilter.counterParty
            || !this.state.exchanges
            || isEmpty(this.state.exchanges))
            return;
        return this.state.exchanges[this.state.activeFilter.exchange][this.state.activeFilter.counterParty];
    }

    getCurrentCurrency() {
        return this.getCurrentExchange()?.currency;
    }

    getCurrentFormat() {
        return this.getCurrentExchange()?.format;
    }

    getCurrenctCountry() {
        return this.getCurrentExchange()?.country;
    }

    getSeepexLikeOfferTablesAsync = async (filter) => {
        const getOffersSpinnerKey = this.spinner.showSpinner();

        try {
            let response;

            try {
                response = await getExchangeOffers(filter);
            } catch (error) {
                handleApiError(error);
                return false;
            }

            if (response.data.success) {
                const selectedCounterPartyConfig = this.getCounterPartyConfig(filter.filter.exchange, filter.filter.counter_party);

                let clearObligedObj = {};
                if (!selectedCounterPartyConfig.showObliged)
                    clearObligedObj = {
                        totalObliged: [],
                        obligedAmounts: []
                    };

                this.setState({
                    matrixData: {},
                    horizontalTableData: {},
                    allTablesList: response.data.success.border_list,
                    borderList: response.data.success.border_list.filter(b => b.offer_type !== this.controlDataTag && b.offer_type !== this.servicingDataTag),
                    controlList: sortBy(response.data.success.border_list.filter(b => b.offer_type === this.controlDataTag), ['level']),
                    servicingList: response.data.success.border_list.filter(b => b.offer_type === this.servicingDataTag),
                    total: response.data.success.total,
                    currencyList: response.data.success.currency_list,
                    mailSent: response.data.success.mail_sent,
                    selectedCounterPartyConfig,
                    newOfferLevel: {},
                    ...clearObligedObj,
                    activeFilter: {
                        date: filter.filter.date,
                        exchange: filter.filter.exchange,
                        counterParty: filter.filter.counter_party,
                    },
                    activeFilterToDisplay: [
                        { label: "Date", value: filter.filter.date },
                        { label: "Exchange", value: filter.filter.exchange },
                        { label: "Counter Party", value: filter.filter.counter_party }
                    ],
                    addNewControlLevel: false
                }, () => {
                    setLocalStorage('exchangeOffers', 'filters', 'activeFilter',
                        {
                            exchange: filter.filter.exchange,
                            counterParty: filter.filter.counter_party
                        });

                    const currency = this.getCurrentCurrency();

                    if (!this.currencyConversionAvailable(currency))
                        this.showErrorMessage(portalMessages.EXCHANGE_OFFERS.NO_CURRENCY);
                });

                if (selectedCounterPartyConfig.showObliged)
                    await this.getSeepexLikeObligedAmountsAsync(filter);

                this.prepareResultDataAsync();
            }
            else if (!response.data.error) {
                // At least 1 of success and error must exist, if both of them
                //are missing, show unexpected error.
                this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Borders)');
            }

            if (response.data.warn) {
                if (response.data.warn.message.NotFound
                    && response.data.warn.message.NotFound === 'border')
                    alertWarning(portalMessages.EXCHANGE_OFFERS.NO_MARGIN);
                else
                    alertWarning(portalMessages.UNEXPECTED_ERROR_OCCURED);
            }

            if (response.data.error) {
                // This is not added as an else clause to below conditions because
                //there might be both success and error and we want to show both
                this.showErrorMessage(response.data.error);
            }
        } catch (error) {
            this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Borders)');
        } finally {
            this.spinner.hideSpinner(getOffersSpinnerKey);
        }
    }

    getSeepexLikeObligedAmountsAsync = async (filter) => {
        const getObligedSpinnerKey = this.spinner.showSpinner();

        try {
            let response;

            try {
                response = await getObligedAmounts(filter);
            } catch (error) {
                handleApiError(error);
                return;
            }

            if (response.data.success) {
                const originalTotals = response.data.success.obliged_amounts;
                let totalObliged = [...originalTotals];

                const newBorderTotal = this.state.total;

                if (this.getCounterPartyConfigForActiveFilter()?.type !== this.viewTypes.levelled
                    && newBorderTotal && newBorderTotal.length > 0) {
                    // calculate acquireds
                    // acquired = st obliged + lt obliged + vitus borders => shown in total table (NOT TOTAL OBLIGED TABLE!)
                    const totalObligedColumns = originalTotals.filter(t => t.type === this.columns.total.total.key);
                    const totalObligedColumnDict = {};

                    if (totalObligedColumns[0].operation_type.toLowerCase() === 'all') {
                        totalObligedColumns[0].data.forEach(t => {
                            totalObligedColumnDict[t.hour] = t;
                        });
                    }
                    else {
                        totalObligedColumns[0].data.forEach(t => {
                            totalObligedColumnDict[t.hour] = t;
                        });

                        const opType = totalObligedColumns[1].operation_type.toLowerCase();

                        totalObligedColumns[1].data.forEach(t => {
                            totalObligedColumnDict[t.hour][opType] = t[opType];
                        });
                    }

                    var stIdx = newBorderTotal.findIndex(t => t.type === this.columns.total.st.key);

                    newBorderTotal[stIdx].data = newBorderTotal[stIdx].data.map(d => {
                        return {
                            hour: d.hour,
                            buy: d.buy + totalObligedColumnDict[d.hour].buy,
                            sell: d.sell + totalObligedColumnDict[d.hour].sell
                        };
                    });

                    var totalIdx = newBorderTotal.findIndex(t => t.type === this.columns.total.total.key);

                    newBorderTotal[totalIdx].data = newBorderTotal[totalIdx].data.map(d => {
                        return {
                            hour: d.hour,
                            buy: d.buy + totalObligedColumnDict[d.hour].buy,
                            sell: d.sell + totalObligedColumnDict[d.hour].sell
                        };
                    });
                }

                this.setState({
                    total: newBorderTotal,
                    totalObliged,
                    exchangeResultList: response.data.success.exchange_result_list,
                    obligedAmounts: originalTotals.filter(received_type =>
                        Object.keys(this.columns.obligedAmounts).filter(expcted_type =>
                            expcted_type === received_type.type).length > 0
                    )
                });
            }
            else if (!response.data.error) {
                // At least 1 of success and error must exist, if both of them
                //are missing, show unexpected error.
                this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Obliged Amounts)');
            }

            if (response.data.error) {
                // This is not added as an else clause to below conditions because
                //there might be both success and error and we want to show both
                this.showErrorMessage(response.data.error);
            }
        }
        finally {
            this.spinner.hideSpinner(getObligedSpinnerKey);
        }
    }

    currencyConversionExists(currency) {
        return currency !== this.columns.eur.title;
    }

    currencyConversionAvailable(currency) {
        const noConversionNeeded = !this.currencyConversionExists(currency);  // conversion is not needed

        const conversionNeededAndAvailable = (  //conversion needed and rate is available
            this.state.currencyList
            && this.state.currencyList.length > 0
            && !isEmpty(this.getCurrencyRate(currency))
        );

        return noConversionNeeded || conversionNeededAndAvailable;
    }

    getConvertedCurrency(value, valueCurrency, rate) {
        if (!rate || isEmpty(rate))
            rate = this.getCurrencyRate();

        if (!this.currencyConversionAvailable(rate.currency))
            return 0;

        if (isNaN(value) || value === "")
            return value;

        value = Number(value);

        const rateCurrencies = rate.currency.split('/');

        if (rateCurrencies[0] === valueCurrency)
            return (value * rate.value).toFixed(this.priceScale)
        else if (rateCurrencies[1] === valueCurrency)
            return (value / rate.value).toFixed(this.priceScale)

        return 0;
    }

    createSingleCurrencyTable(data, border) {
        const priceUnitEur = this.columns.price_offer.unit.replace(this.currencyLabel, this.columns.eur.title);

        const sell_direction = this.getCurrentExchange()?.sell_directions[border]
        const buy_direction = this.getCurrentExchange()?.buy_directions[border]
        const country_code = this.exchangeCountryCodes[this.state.activeFilter.exchange]?.toUpperCase()

        let sell_name = this.columns.sell.title
        let buy_name = this.columns.buy.title
        if (sell_direction) {
            sell_name = `${sell_direction} (${country_code} ${this.columns.sell.title})`
        }
        if (buy_direction) {
            buy_name = `${buy_direction} (${country_code} ${this.columns.buy.title})`
        }
        const headers = [
            [
                this.columns.hour.title,
                {
                    name: sell_name,
                    html: sell_name,
                    agg: AggType.sum
                },
                {
                    name: this.columns.sell.title,
                    html: "",
                    agg: AggType.avg
                },
                {
                    name: buy_name,
                    html: buy_name,
                    agg: AggType.sum
                },
                {
                    name: this.columns.buy.title,
                    html: "",
                    agg: AggType.avg
                }
            ],
            [
                this.columns.cet.title,
                {
                    name: `${this.columns.profile.title}`,
                    html: `<span>${this.columns.profile.title}</span>`
                },
                {
                    name: `${this.columns.price_offer.title}`,
                    html: `<span>${this.columns.price_offer.title}</span>`
                },
                {
                    name: `${this.columns.profile.title}`,
                    html: `<span>${this.columns.profile.title}</span>`
                },
                {
                    name: `${this.columns.price_offer.title}`,
                    html: `<span>${this.columns.price_offer.title}</span>`
                }
            ],
            [
                "",
                {
                    name: `${this.columns.profile.unit}`,
                    html: `<span>${this.columns.profile.unit}</span>`
                },
                {
                    name: `${priceUnitEur}`,
                    html: `<span>${priceUnitEur}</span>`
                },
                {
                    name: `${this.columns.profile.unit}`,
                    html: `<span>${this.columns.profile.unit}</span>`
                },
                {
                    name: `${priceUnitEur}`,
                    html: `<span>${priceUnitEur}</span>`
                }
            ]
        ];

        const values = data.map(hourDetails => {
            return [
                hourDetails[this.columns.hour.key] + 1,
                hourDetails[this.columns.sell.key][this.columns.profile.key],
                hourDetails[this.columns.sell.key][this.columns.price_offer.key],
                hourDetails[this.columns.buy.key][this.columns.profile.key],
                hourDetails[this.columns.buy.key][this.columns.price_offer.key]
            ];
        });

        return { headers, values };
    }

    getCurrencyRate(currency) {
        if (!currency)
            currency = this.getCurrentCurrency();

        const noConversionNeeded = !this.currencyConversionExists(currency);  // conversion is not needed
        if (noConversionNeeded)
            return {};

        const rateArr = this.state.currencyList.filter(co => co.currency.includes(currency) && co.currency.includes(this.columns.eur.title));

        if (rateArr.length === 1) {
            return rateArr[0];
        }
        else if (rateArr.length > 1) {
            this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Multiple currency matchs)');
        }

        return {};
    }

    convertLevelledJsonToTable(data, operationType) {
        const currency = this.getCurrentCurrency();
        const priceUnitEur = this.columns.price_offer.unit.replace(this.currencyLabel, this.columns.eur.title);
        const currentcyTitle = this.columns.price_offer.unit.replace(this.currencyLabel, currency);

        const headers = [
            [
                {
                    name: `${this.columns.hour.title}`,
                    html: `<span>${this.columns.hour.title}</span>`
                },
                {
                    name: `${this.columns[operationType.key].title} ${this.columns.profile.title}`,
                    html: `<span>${this.columns[operationType.key].title} ${this.columns.profile.title}</span>`,
                    agg: AggType.sum
                },
                {
                    name: `${this.columns.price_offer.title}`,
                    html: `<span>${this.columns.price_offer.title}</span>`,
                    agg: AggType.avg
                },
                {
                    name: `${this.columns.price_offer.title}`,
                    html: `<span>${this.columns.price_offer.title}</span>`,
                    agg: AggType.avg
                },
            ],
            [
                {
                    name: `${this.columns.cet.title}`,
                    html: `<span>${this.columns.cet.title}</span>`
                },
                {
                    name: `${this.columns.profile.unit}`,
                    html: `<span>${this.columns.profile.unit}</span>`
                },
                {
                    name: `${priceUnitEur}`,
                    html: `<span>${priceUnitEur}</span>`
                },
                {
                    name: `${currentcyTitle}`,
                    html: `<span>${currentcyTitle}</span>`
                },
            ]
        ];

        let priceValue;

        const rate = this.getCurrencyRate(currency);

        const values = data.map(hourDetails => {

            priceValue = hourDetails[operationType.key][this.columns.price_offer.key];

            return [
                hourDetails[this.columns.hour.key] + 1,
                hourDetails[this.columns[operationType.key].key][this.columns.profile.key] ?? 0,
                priceValue,
                this.getConvertedCurrency(priceValue, this.columns.eur.title, rate)
            ];
        });

        return { headers, values };
    }

    createTwoCurrencyTable(currency, data, border) {
        const priceUnitEur = this.columns.price_offer.unit.replace(this.currencyLabel, this.columns.eur.title);
        const currentcyTitle = this.columns.price_offer.unit.replace(this.currencyLabel, currency);

        const sell_direction = this.getCurrentExchange()?.sell_directions[border]
        const buy_direction = this.getCurrentExchange()?.buy_directions[border]
        const country_code = this.exchangeCountryCodes[this.state.activeFilter.exchange]?.toUpperCase()

        let sell_name = this.columns.sell.title
        let buy_name = this.columns.buy.title
        if (sell_direction) {
            sell_name = `${sell_direction} (${country_code} ${this.columns.sell.title})`
        }
        if (buy_direction) {
            buy_name = `${buy_direction} (${country_code} ${this.columns.buy.title})`
        }

        const headers = [
            [
                this.columns.hour.title,
                {
                    name: sell_name,
                    html: sell_name,
                    agg: AggType.sum
                },
                {
                    name: this.columns.sell.title,
                    html: "",
                    agg: AggType.avg
                },
                {
                    name: this.columns.sell.title,
                    html: "",
                    agg: AggType.avg
                },
                {
                    name: buy_name,
                    html: buy_name,
                    agg: AggType.sum
                },
                {
                    name: this.columns.buy.title,
                    html: "",
                    agg: AggType.avg
                },
                {
                    name: this.columns.buy.title,
                    html: "",
                    agg: AggType.avg
                }
            ],
            [
                this.columns.cet.title,
                {
                    name: `${this.columns.profile.title}`,
                    html: `<span>${this.columns.profile.title}</span>`
                },
                {
                    name: `${this.columns.price_offer.title}`,
                    html: `<span>${this.columns.price_offer.title}</span>`
                },
                {
                    name: `${this.columns.price_offer.title}`,
                    html: `<span>${this.columns.price_offer.title}</span>`
                },
                {
                    name: `${this.columns.profile.title}`,
                    html: `<span>${this.columns.profile.title}</span>`
                },
                {
                    name: `${this.columns.price_offer.title}`,
                    html: `<span>${this.columns.price_offer.title}</span>`
                },
                {
                    name: `${this.columns.price_offer.title}`,
                    html: `<span>${this.columns.price_offer.title}</span>`
                }
            ],
            [
                "",
                {
                    name: `${this.columns.profile.unit}`,
                    html: `<span>${this.columns.profile.unit}</span>`
                },
                {
                    name: `${priceUnitEur}`,
                    html: `<span>${priceUnitEur}</span>`
                },
                {
                    name: `${currentcyTitle}`,
                    html: `<span>${currentcyTitle}</span>`
                },
                {
                    name: `${this.columns.profile.unit}`,
                    html: `<span>${this.columns.profile.unit}</span>`
                },
                {
                    name: `${priceUnitEur}`,
                    html: `<span>${priceUnitEur}</span>`
                },
                {
                    name: `${currentcyTitle}`,
                    html: `<span>${currentcyTitle}</span>`
                }
            ]
        ];

        const rate = this.getCurrencyRate(currency);

        let sellValue, buyValue;

        const values = data.map(hourDetails => {

            sellValue = hourDetails[this.columns.sell.key][this.columns.price_offer.key];
            buyValue = hourDetails[this.columns.buy.key][this.columns.price_offer.key];

            return [
                hourDetails[this.columns.hour.key] + 1,
                hourDetails[this.columns.sell.key][this.columns.profile.key],
                sellValue,
                this.getConvertedCurrency(sellValue, this.columns.eur.title, rate),
                hourDetails[this.columns.buy.key][this.columns.profile.key],
                buyValue,
                this.getConvertedCurrency(buyValue, this.columns.eur.title, rate)
            ];
        });

        return { headers, values };
    }

    convertBorderedJsonToTable(data, border) {
        //currencyLabel
        const currency = this.getCurrentCurrency();

        if (!this.currencyConversionExists(currency)) {
            // working with EUR

            return this.createSingleCurrencyTable(data, border);
        }
        else {
            // working with different currency

            return this.createTwoCurrencyTable(currency, data, border);
        }
    }

    getTableToJsonConvertor(offerType) {
        if (this.getCounterPartyConfigForActiveFilter()?.type === this.viewTypes.bordered
            || offerType === this.servicingDataTag)
            return (...vars) => this.convertBorderedTableToJson(offerType, ...vars);
        else
            return (...vars) => this.convertLevelledTableToJson(...vars);
    }

    onSaveBorderTableAsync = async (borderDetails, operationType) => {
        let spinnerKey = this.spinner.showSpinner();

        try {
            let body = {
                date: this.state.activeFilter.date,
                counter_party: this.state.activeFilter.counterParty,
                exchange: this.state.activeFilter.exchange,
                mail_sent: this.state.mailSent,
                border_list: []
            };

            const customCurrency = this.getCurrentCurrency();
            const includeConverted = this.currencyConversionExists(customCurrency);

            let border, borderDetail, newData, convertedBorder;

            let converted, updated_data;

            for ({ borderDetail, newData } of borderDetails) {
                converted = { updatedData: null, inputError: null, updatedOtherCurrencyData: null };

                if (newData && !isEmpty(newData)) {
                    const tableToJsonConvertor = this.getTableToJsonConvertor(borderDetail.offer_type);
                    converted = tableToJsonConvertor(newData, operationType);

                    if (converted.inputError) {
                        this.showErrorMessage(converted.inputError);
                        return false;
                    }

                    updated_data = converted.updatedData;
                }
                else {
                    updated_data = borderDetail.data;
                }

                border = {
                    border: borderDetail.border,
                    offer_type: borderDetail.offer_type,
                    currency: borderDetail.currency,
                    offer_id: borderDetail.offer_id,
                    level: borderDetail.level,
                    operation_type: borderDetail.operation_type,
                    previous_data: borderDetail.offer_id ? borderDetail.data : [],
                    servicing_company: borderDetail.servicing_company,
                    updated_data
                };

                body.border_list.push(border);

                if (includeConverted) { // The json provided from get and sent to save are different!!!
                    const { otherCurrencyDataExist, otherCurrencyData } = this.getOtherCurrencyData(borderDetail);

                    convertedBorder = {
                        border: borderDetail.border,
                        offer_type: borderDetail.offer_type,
                        currency: customCurrency,
                        offer_id: otherCurrencyDataExist ? otherCurrencyData.offer_id : null,
                        level: otherCurrencyData?.level ?? borderDetail?.level,
                        operation_type: otherCurrencyData?.operation_type ?? borderDetail?.operation_type,
                        previous_data: (otherCurrencyDataExist && otherCurrencyData.offer_id) ? otherCurrencyData.data : [],
                        updated_data: converted.updatedOtherCurrencyData,
                        servicing_company: borderDetail.servicing_company
                    };

                    body.border_list.push(convertedBorder);
                }
            }

            let errorMessage = this.validateBordersInclusively(operationType, body.border_list);

            if (errorMessage) {
                this.showErrorMessage(errorMessage);
                return false;
            }

            const newDataToValidate = body.border_list.filter(b => b.currency === this.columns.eur.title);
            const oldDataToValidate = this.state.borderList.filter(b => !newDataToValidate.find(nb => nb.offer_id === b.offer_id));

            errorMessage = this.validateBordersExclusively([...newDataToValidate, ...oldDataToValidate]);

            if (errorMessage) {
                this.showErrorMessage(errorMessage);
                return false;
            }

            let response;

            try {
                response = await saveExchangeOffers(body);
            } catch (error) {
                handleApiError(error);
                return false;
            }

            if (response.data.success) {
                await this.refreshBorderTablesAsync();
                alertSuccess(portalMessages.EXCHANGE_OFFERS.OFFERS_UPDATED);
                return true;
            }
            else {
                this.showErrorMessage(response.data.error || {}, "editValues");
                return false;
            }

        } catch (error) {
            this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Borders)');
            return false;
        } finally {
            this.spinner.hideSpinner(spinnerKey);
        }
    }

    validateLevelledRow(row, validMinPrice, validMaxPrice) {
        const priceIdx = this.columnIndices.border.levelPriceOffer();

        let offer = {
            profile: row[this.columnIndices.border.levelProfile()],
            price_offer: row[priceIdx]
        };

        if (isNaN(offer.profile))
            return portalMessages.EXCHANGE_OFFERS.PROFILE_NAN;

        if (isNaN(offer.price_offer))
            return portalMessages.EXCHANGE_OFFERS.INVALID_PRICE_OFF.replace('[X]', offer.price_offer);

        if ((offer.profile && !offer.price_offer) || (!offer.profile && offer.price_offer))
            return portalMessages.EXCHANGE_OFFERS.PROFILE_OR_PRICE_ZERO;

        if (validMinPrice !== null && offer.profile > 0 && !isNaN(offer.price_offer) && offer.price_offer < validMinPrice)
            return portalMessages.EXCHANGE_OFFERS.OFFER_TOO_BIG_OR_SMALL.replace("[MIN]", validMinPrice).replace("[MAX]", validMaxPrice).replace('[EXCHANGE]', this.state.activeFilter.exchange);

        if (validMaxPrice !== null && offer.profile > 0 && !isNaN(offer.price_offer) && offer.price_offer > validMaxPrice)
            return portalMessages.EXCHANGE_OFFERS.OFFER_TOO_BIG_OR_SMALL.replace("[MIN]", validMinPrice).replace("[MAX]", validMaxPrice).replace('[EXCHANGE]', this.state.activeFilter.exchange);

        let numParts = offer.price_offer.toString().split('.');

        if (numParts.length > 1 && numParts[1].length > this.priceScale)
            return portalMessages.EXCHANGE_OFFERS.PRICE_FRACTIONS;

        numParts = offer.profile.toString().split('.');

        if (numParts.length > 1 && Number(numParts[1]) !== 0)
            return `${this.state.activeFilter.exchange} ${this.state.activeFilter.counterParty} ${portalMessages.EXCHANGE_OFFERS.PROFILE_INTEGER}`;

        return '';
    }

    validateBorderedRow(row, validMinPrice, validMaxPrice, offerType) {
        const sellPriceIdx = this.columnIndices.border.borderSellPriceOffer(row.length);
        const buyPriceIdx = this.columnIndices.border.borderBuyPriceOffer(row.length);

        let sell = {
            profile: row[this.columnIndices.border.borderSellProfile(row.length)],
            price_offer: row[sellPriceIdx]
        };
        let buy = {
            profile: row[this.columnIndices.border.borderBuyProfile(row.length)],
            price_offer: row[buyPriceIdx]
        };

        // profiles must be numeric
        const sellProfileNan = isNaN(sell.profile);
        const buyProfileNan = isNaN(buy.profile);

        if (sellProfileNan || buyProfileNan) {
            return portalMessages.EXCHANGE_OFFERS.PROFILE_NAN;
        }

        // price offers can only be numeric or special (i.e "Any price")
        const specialWords = this.getSpecialWords(row.length, offerType);

        const sellPriceIsInvalid = isNaN(sell.price_offer)
            && (!specialWords[sellPriceIdx]
                || specialWords[sellPriceIdx].filter(s => s.toLocaleLowerCase() === sell.price_offer.toLowerCase()).length === 0);

        if (sellPriceIsInvalid) {
            return portalMessages.EXCHANGE_OFFERS.INVALID_PRICE_OFF.replace('[X]', sell.price_offer);
        }

        const buyPriceIsInvalid = isNaN(buy.price_offer)
            && (!specialWords[buyPriceIdx]
                || specialWords[buyPriceIdx].filter(s => s.toLocaleLowerCase() === buy.price_offer.toLowerCase()).length === 0);

        if (buyPriceIsInvalid) {
            return portalMessages.EXCHANGE_OFFERS.INVALID_PRICE_OFF.replace('[X]', buy.price_offer);
        }

        // price and profile must be both specified or both zero
        const sellOnlyOneZero = (sell.profile && !sell.price_offer) || (!sell.profile && sell.price_offer);
        const buyonlyOneZero = (buy.profile && !buy.price_offer) || (!buy.profile && buy.price_offer);

        if ((sellOnlyOneZero || buyonlyOneZero) && !this.state.selectedCounterPartyConfig?.doNotValidateProfilePrice) {
            return portalMessages.EXCHANGE_OFFERS.PROFILE_OR_PRICE_ZERO;
        }

        //price min/max check
        if (validMinPrice !== null) {
            if (sell.profile > 0 && !isNaN(sell.price_offer) && sell.price_offer < validMinPrice)
                return portalMessages.EXCHANGE_OFFERS.OFFER_TOO_BIG_OR_SMALL.replace("[MIN]", validMinPrice).replace("[MAX]", validMaxPrice).replace('[EXCHANGE]', this.state.activeFilter.exchange);

            if (buy.profile > 0 && !isNaN(buy.price_offer) && buy.price_offer < validMinPrice)
                return portalMessages.EXCHANGE_OFFERS.OFFER_TOO_BIG_OR_SMALL.replace("[MIN]", validMinPrice).replace("[MAX]", validMaxPrice).replace('[EXCHANGE]', this.state.activeFilter.exchange);
        }

        if (validMaxPrice !== null) {
            if (sell.profile > 0 && !isNaN(sell.price_offer) && sell.price_offer > validMaxPrice)
                return portalMessages.EXCHANGE_OFFERS.OFFER_TOO_BIG_OR_SMALL.replace("[MIN]", validMinPrice).replace("[MAX]", validMaxPrice).replace('[EXCHANGE]', this.state.activeFilter.exchange);

            if (buy.profile > 0 && !isNaN(buy.price_offer) && buy.price_offer > validMaxPrice)
                return portalMessages.EXCHANGE_OFFERS.OFFER_TOO_BIG_OR_SMALL.replace("[MIN]", validMinPrice).replace("[MAX]", validMaxPrice).replace('[EXCHANGE]', this.state.activeFilter.exchange);
        }

        // price scale can not be more than 2 digits
        let numParts = sell.price_offer.toString().split('.');

        if (numParts.length > 1 && numParts[1].length > this.priceScale) {
            return portalMessages.EXCHANGE_OFFERS.PRICE_FRACTIONS;
        }

        numParts = buy.price_offer.toString().split('.');

        if (numParts.length > 1 && numParts[1].length > this.priceScale) {
            return portalMessages.EXCHANGE_OFFERS.PRICE_FRACTIONS;
        }

        // border profile must be integer (EXCEPT IBEX ENERGOVIA => .0)
        // servicing profile can have 1 digit for any filter

        if (offerType === this.servicingDataTag
            || (this.specialProfileScalePairs[this.state.activeFilter.exchange]
                && this.specialProfileScalePairs[this.state.activeFilter.exchange].find(cp => cp === this.state.activeFilter.counterParty))) {
            numParts = sell.profile.toString().split('.');

            if (numParts.length > 1 && numParts[1].length > this.specialProfileScale) {
                return `${this.state.activeFilter.exchange} ${this.state.activeFilter.counterParty} ${portalMessages.EXCHANGE_OFFERS.PROFILE_FRACTION}`;
            }

            numParts = buy.profile.toString().split('.');

            if (numParts.length > 1 && numParts[1].length > this.specialProfileScale) {
                return `${this.state.activeFilter.exchange} ${this.state.activeFilter.counterParty} ${portalMessages.EXCHANGE_OFFERS.PROFILE_FRACTION}`;
            }
        }
        else {
            numParts = sell.profile.toString().split('.');

            if (numParts.length > 1 && Number(numParts[1]) !== 0) {
                return `${this.state.activeFilter.exchange} ${this.state.activeFilter.counterParty} ${portalMessages.EXCHANGE_OFFERS.PROFILE_INTEGER}`;
            }

            numParts = buy.profile.toString().split('.');

            if (numParts.length > 1 && Number(numParts[1]) !== 0) {
                return `${this.state.activeFilter.exchange} ${this.state.activeFilter.counterParty} ${portalMessages.EXCHANGE_OFFERS.PROFILE_INTEGER}`;
            }
        }

        if (buy.profile < 0 || sell.profile < 0) {
            return portalMessages.EXCHANGE_OFFERS.PROFILES_POSITIVE;
        }

        return "";
    }

    convertLevelledTableToJson(data, operationType) {
        let updatedData = [];
        let inputError;
        let updatedOtherCurrencyData = [];

        const validMinPrice = this.getCurrentExchange()?.validMinPrice ?? null;
        const validMaxPrice = this.getCurrentExchange()?.validMaxPrice ?? null;

        for (let row of data.values) {
            row = row.map(c => isNaN(c) ? c : Number(c));

            inputError = this.validateLevelledRow(row, validMinPrice, validMaxPrice);

            if (inputError) {
                updatedData = [];
                break;
            }

            updatedData.push({
                hour: row[this.columnIndices.border.borderHour(row.length)] - 1,
                [operationType.key]: {
                    profile: row[this.columnIndices.border.levelProfile(row.length)],
                    price_offer: row[this.columnIndices.border.levelPriceOffer(row.length)]
                }
            });
        }

        if (this.currencyConversionExists(this.getCurrentCurrency())) {
            for (let row of data.values) {
                row = row.map(c => isNaN(c) ? c : Number(c));

                updatedOtherCurrencyData.push({
                    hour: row[this.columnIndices.border.borderHour(row.length)] - 1,
                    [operationType.key]: {
                        profile: row[this.columnIndices.border.levelProfile(row.length)],
                        price_offer: row[this.columnIndices.border.levelCustomCurrencyPriceOffer(row.length)]
                    }
                });
            }
        }

        return { updatedData, inputError, updatedOtherCurrencyData };
    }

    convertBorderedTableToJson(offerType, data) {
        let updatedData = [];
        let inputError;
        let updatedOtherCurrencyData = [];

        const validMinPrice = this.getCurrentExchange()?.validMinPrice ?? null;
        const validMaxPrice = this.getCurrentExchange()?.validMaxPrice ?? null;

        for (let row of data.values) {
            row = row.map(c => isNaN(c) ? c : Number(c));

            inputError = this.validateBorderedRow(row, validMinPrice, validMaxPrice, offerType);

            if (inputError) {
                updatedData = [];
                break;
            }

            updatedData.push({
                hour: row[this.columnIndices.border.borderHour(row.length)] - 1,
                sell: {
                    profile: row[this.columnIndices.border.borderSellProfile(row.length)],
                    price_offer: row[this.columnIndices.border.borderSellPriceOffer(row.length)]
                },
                buy: {
                    profile: row[this.columnIndices.border.borderBuyProfile(row.length)],
                    price_offer: row[this.columnIndices.border.borderBuyPriceOffer(row.length)]
                }
            });
        }

        if (this.currencyConversionExists(this.getCurrentCurrency())) {
            //include converted currency

            for (let row of data.values) {
                row = row.map(c => isNaN(c) ? c : Number(c));

                updatedOtherCurrencyData.push({
                    hour: row[this.columnIndices.border.borderHour(row.length)] - 1,
                    sell: {
                        profile: row[this.columnIndices.border.borderSellProfile(row.length)],
                        price_offer: row[this.columnIndices.border.borderSellCustomCurrencyPriceOffer(row.length)]
                    },
                    buy: {
                        profile: row[this.columnIndices.border.borderBuyProfile(row.length)],
                        price_offer: row[this.columnIndices.border.borderBuyCustomCurrencyPriceOffer(row.length)]
                    }
                });
            }
        }

        return { updatedData, inputError, updatedOtherCurrencyData };
    }

    createLevelledTables(operationType) {
        const sortedBorders = [...this.state.borderList.filter(b => b.operation_type?.toLowerCase() === operationType.key)]
            .sort((a, b) => { return (a.level > b.level) ? 1 : ((b.level > a.level) ? -1 : 0) });

        return sortedBorders.map((borderDetail, idx) => {
            const ref = React.createRef();
            this.borderTableRefs.push(ref);

            // sortedBorders.length > 1 because if there is only 1 table it can not be deleted, obliged must be deleted in that case
            const canBeDeleted = sortedBorders.length > 1 && idx === sortedBorders.length - 1;

            return this.createBorderTable(borderDetail, ref, idx + 1, borderDetail.level, operationType, false, canBeDeleted);
        });
    }

    createBorderedTables() {
        const sortedBorders = [...this.state.borderList].sort((a, b) => { return (a.border > b.border) ? 1 : ((b.border > a.border) ? -1 : 0) });

        return sortedBorders.map((borderDetail, idx) => {
            const ref = React.createRef();
            this.borderTableRefs.push(ref);
            return this.createBorderTable(borderDetail, ref, idx + 1);
        });
    }

    async deleteOfferTablesByIdAsync(idsToDelete, operationType) {
        let spinnerKey = this.spinner.showSpinner();

        try {

            let response;

            try {
                response = await deleteOffer({
                    date: this.state.activeFilter.date,
                    counter_party: this.state.activeFilter.counterParty,
                    exchange: this.state.activeFilter.exchange,
                    offer_ids: idsToDelete,
                    operation_type: operationType?.key ?? 'All'
                });
            } catch (error) {
                handleApiError(error);
                return false;
            }

            if (response.data.success) {
                await this.refreshBorderTablesAsync();
                alertSuccess(portalMessages.EXCHANGE_OFFERS.OFFERS_DELETED_SUCCESSFULLY);
                return true;
            }
            else {
                this.showErrorMessage(response.data.error || {}, "deleteLevel");
                return false;
            }

        } catch (error) {
            this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Delete Level)');
            return;
        } finally {
            this.spinner.hideSpinner(spinnerKey);
        }
    }

    async deleteLevelTableAsync(borderDetail, operationType) {
        let spinnerKey = this.spinner.showSpinner();

        try {
            const wasSavedBefore = !!borderDetail.offer_id && borderDetail.other_currency_data.length > 0 && borderDetail.other_currency_data[0].offer_id;

            if (wasSavedBefore) {
                await this.deleteOfferTablesByIdAsync([borderDetail.offer_id, borderDetail.other_currency_data[0].offer_id], operationType);
            }
            else {
                this.saveAllBordersAsync(operationType, [borderDetail.level])
            }

        } catch (error) {
            this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Delete Level)');
            return;
        } finally {
            this.spinner.hideSpinner(spinnerKey);
        }
    }

    getDeleteLevelConfirmationMessage(message, exchange, counterParty, operationType, level, servicingCompany) {
        return (
            <div className='v-offer-confirmation'>
                <div className='container'>
                    <div className='row'>
                        <div className='col'>
                            {message}
                        </div>
                    </div>
                    <div className='row'>
                        <div style={{ whiteSpace: 'nowrap' }} className='col'>
                            Exchange:
                        </div>
                        <div className='col-7'>
                            <strong>{exchange}</strong>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            Counter Party:
                    </div>
                        <div className='col-7'>
                            <strong>{counterParty}</strong>
                        </div>
                    </div>
                    {servicingCompany &&
                        <div className='row'>
                            <div className='col'>
                                Servicing Company:
                        </div>
                            <div className='col-7'>
                                <strong>{servicingCompany}</strong>
                            </div>
                        </div>}
                    {operationType?.title &&
                        <div className='row'>
                            <div className='col'>
                                Type:
                        </div>
                            <div className='col-7'>
                                <strong>{operationType.title}</strong>
                            </div>
                        </div>}
                    {level &&
                        <div className='row'>
                            <div className='col'>
                                Level:
                        </div>
                            <div className='col-7'>
                                <strong>{level}</strong>
                            </div>
                        </div>}
                </div>
            </div>
        );
    }

    getBorderScales(columnCount, offerType) {
        let profileScale = 0;

        if (offerType === this.servicingDataTag)
            profileScale = 1;

        if (this.currencyConversionExists(this.getCurrentCurrency()))
            return {
                [this.columnIndices.border.borderSellProfile(columnCount)]: profileScale,
                [this.columnIndices.border.borderBuyProfile(columnCount)]: profileScale,
                [this.columnIndices.border.borderSellPriceOffer(columnCount)]: this.priceScale,
                [this.columnIndices.border.borderBuyPriceOffer(columnCount)]: this.priceScale,
                [this.columnIndices.border.borderSellCustomCurrencyPriceOffer(columnCount)]: this.priceScale,
                [this.columnIndices.border.borderBuyCustomCurrencyPriceOffer(columnCount)]: this.priceScale
            };

        return {
            [this.columnIndices.border.borderSellProfile(columnCount)]: profileScale,
            [this.columnIndices.border.borderBuyProfile(columnCount)]: profileScale,
            [this.columnIndices.border.borderSellPriceOffer(columnCount)]: this.priceScale,
            [this.columnIndices.border.borderBuyPriceOffer(columnCount)]: this.priceScale
        };
    }

    getBorderReadonlyColumnIndices(columnCount) {
        if (this.currencyConversionExists(this.getCurrentCurrency()))
            return [
                this.columnIndices.border.borderHour(),
                this.columnIndices.border.borderSellCustomCurrencyPriceOffer(columnCount),
                this.columnIndices.border.borderBuyCustomCurrencyPriceOffer(columnCount)
            ];

        return [
            this.columnIndices.border.borderHour()
        ];
    }

    getBorderIgnoredColumnsForFiles(columnCount) {
        if (!this.currencyConversionExists(this.getCurrentCurrency()))
            return [];

        return [
            this.columnIndices.border.borderSellCustomCurrencyPriceOffer(columnCount),
            this.columnIndices.border.borderBuyCustomCurrencyPriceOffer(columnCount)
        ]
    }

    getBorderInputHooks(columnCount) {
        if (!this.currencyConversionExists(this.getCurrentCurrency()))
            return {};

        const sellColumnIndex = this.columnIndices.border.borderSellPriceOffer(columnCount);
        const buyColumnIndex = this.columnIndices.border.borderBuyPriceOffer(columnCount);

        return {
            [sellColumnIndex]: {
                columnIndex: this.columnIndices.border.borderSellCustomCurrencyPriceOffer(columnCount),
                calculator: (value) => {
                    return this.getConvertedCurrency(value, this.columns.eur.title);
                }
            }
            ,
            [buyColumnIndex]: {
                columnIndex: this.columnIndices.border.borderBuyCustomCurrencyPriceOffer(columnCount),
                calculator: (value) => {
                    return this.getConvertedCurrency(value, this.columns.eur.title);
                }
            }
        };
    }

    hideSaveButton(level) {
        return level || this.state.editingTableCount !== 1;
    }

    createBorderTable(borderDetail, ref, tabIndex, level, operationType, isNewOffer, canBeDeleted) {
        const items = level ? this.convertLevelledJsonToTable(borderDetail.data, operationType) : this.convertBorderedJsonToTable(borderDetail.data, borderDetail.border);
        const columnCount = items.headers[0].length;
        const identifier = level ? 'Level ' + level : borderDetail.border;

        const deleteEnabled = !!operationType && canBeDeleted && this.editBorderEnabled(operationType);

        return (
            <VTable
                tabIndex={tabIndex}
                ref={ref}
                key={identifier}
                customColumnClasses={{ 0: "v-column-narrow-bold" }}
                editModeOn={isNewOffer}
                isNew={!!isNewOffer}
                title={identifier}
                endOfTableMark={isNewOffer ? `endOf${operationType.title}Levels` : ''}
                operationType={operationType}
                onError={(message) => this.showErrorMessage(message)}
                simpleNumbers
                inputType='number'
                inputClass='v-input-4'
                exportFileName={`ExchangeOffers_${this.state.activeFilter.exchange}_${this.state.activeFilter.counterParty}_${level ? ('_Level' + level + '_' + operationType.title) : ('_' + borderDetail.border)}_${this.state.activeFilter.date}`}
                onOpenEditMode={() => this.onOpenBorderTableEditMode(operationType)}
                onCloseEditMode={() => this.onCloseBorderTableEditMode(operationType, isNewOffer)}
                items={items}
                relatedInfo={borderDetail}
                onReset={() => this.setState({ borderToReset: borderDetail, showResetBorderModal: true })}
                onSaveChangesAsync={(newData) => this.onSaveBorderTableAsync([{ newData, borderDetail }], operationType)}
                onTableDelete={() => this.deleteLevelTableAsync(borderDetail, operationType)}
                deleteMessage={!deleteEnabled ? '' : this.getDeleteLevelConfirmationMessage(
                    portalMessages.EXCHANGE_OFFERS.DELETE_OFFERS,
                    this.state.activeFilter.exchange,
                    this.state.activeFilter.counterParty,
                    operationType,
                    level
                )}
                headerButtons={{
                    showEditButton: this.editBorderEnabled(operationType),
                    showResetTableButton: !operationType && this.editBorderEnabled(operationType),
                    showExportExcelButton: true,
                    showImportExcelButton: this.editBorderEnabled(operationType),
                    showDeleteTableButton: deleteEnabled,
                    hideSaveButton: this.hideSaveButton(level)
                }}
                readonlyColumnIndices={this.getBorderReadonlyColumnIndices(columnCount)}
                ignoredColumnsForFiles={this.getBorderIgnoredColumnsForFiles(columnCount)}
                inputHooks={this.getBorderInputHooks(columnCount)}
                scalesByIndices={this.getBorderScales(columnCount)}
                specialWordsByIndices={this.getSpecialWords(columnCount)}
                showSpecialAgg
            />
        );
    }

    getSpecialWords(columnCount, offerType) {
        if (this.getCounterPartyConfigForActiveFilter()?.type === this.viewTypes.levelled
            || (offerType !== this.servicingDataTag
                && this.getCurrentFormat() === this.matrixFormatKey))
            return {};

        if (this.currencyConversionExists(this.getCurrentCurrency()))
            return {
                [this.columnIndices.border.borderSellPriceOffer(columnCount)]: [this.anyPriceSpecialValue],
                [this.columnIndices.border.borderBuyPriceOffer(columnCount)]: [this.anyPriceSpecialValue],
                [this.columnIndices.border.borderSellCustomCurrencyPriceOffer(columnCount)]: [this.anyPriceSpecialValue],
                [this.columnIndices.border.borderBuyCustomCurrencyPriceOffer(columnCount)]: [this.anyPriceSpecialValue]
            };

        return {
            [this.columnIndices.border.borderSellPriceOffer(columnCount)]: [this.anyPriceSpecialValue],
            [this.columnIndices.border.borderBuyPriceOffer(columnCount)]: [this.anyPriceSpecialValue]
        };
    }

    convertTotalJsonToTable(data) {
        let dataObj;
        let typeObj = {};
        let types = [];
        let currentTitle = "";

        let headers = [
            [this.columns.hour.title],
            [this.columns.cet.title],
            [""]
        ];

        let values = [];

        const sortedData = data.sort((a, b) => { return (this.columns.total[a.type] && this.columns.total[b.type]) ? (this.columns.total[a.type].order - this.columns.total[b.type].order) : 1 });

        sortedData.forEach(details => {
            dataObj = {};
            details.type = details.type.toLowerCase();

            if (!this.columns.total[details.type]) //do not add if not predefined
                return;

            details.data.forEach(hourDetails => {
                dataObj[this.columns.hour.key] = hourDetails;
            })

            typeObj[details.type] = details.data;
            types.push(details.type);

            currentTitle = this.columns.total[details.type].title;
            headers[0].push(currentTitle);
            headers[0].push(currentTitle);
            headers[1].push(
                {
                    name: `${this.columns.sell.title}`,
                    html: `<span>${this.columns.sell.title}</span>`
                }
            );
            headers[1].push(
                {
                    name: `${this.columns.buy.title}`,
                    html: `<span>${this.columns.buy.title}</span>`
                }
            );
            headers[2].push(
                {
                    name: `${this.columns.sell.unit}`,
                    html: `<span>${this.columns.sell.unit}</span>`
                }
            );
            headers[2].push(
                {
                    name: `${this.columns.buy.unit}`,
                    html: `<span>${this.columns.buy.unit}</span>`
                }
            );
        });

        typeObj[Object.keys(typeObj)[0]].forEach(totalDetails => {
            values.push(
                [
                    totalDetails[this.columns.hour.key] + 1,
                    ...types.flatMap(type => {
                        const values = typeObj[type][totalDetails[this.columns.hour.key]];

                        if (!values)
                            return ['-', '-']

                        return [
                            values[this.columns.sell.key],
                            values[this.columns.buy.key]
                        ];
                    })
                ]
            );
        });

        return { headers, values };
    }

    createTotalTable(totalType, data) {
        if (!data || isEmpty(data))
            return null;

        return (
            <VTable
                key={totalType}
                title={totalType}
                simpleNumbers
                customColumnClasses={{ 0: "v-column-narrow-bold" }}
                inputType='number'
                exportFileName={`ExchangeOffers_${totalType.replaceAll(' ', '')}_${this.state.activeFilter.exchange}_${this.state.activeFilter.counterParty}_${this.state.activeFilter.date}`}
                readonlyColumns={[this.columns.hour.title]}
                items={this.convertTotalJsonToTable(data)}
                headerButtons={{
                    showExportExcelButton: true
                }}
            />
        );
    }

    convertObligedAmountsJsonToTable(data) {
        let headers = [
            [
                this.columns.hour.title,
                this.columns.obligedAmounts.st.sell.title,
                this.columns.obligedAmounts.st.buy.title,
                this.columns.obligedAmounts.lt.sell.title,
                this.columns.obligedAmounts.lt.buy.title
            ],
            [
                this.columns.cet.title,
                {
                    name: `${this.columns.profile.title}`,
                    html: `<span>${this.columns.profile.title}</span>`
                },
                {
                    name: `${this.columns.profile.title}`,
                    html: `<span>${this.columns.profile.title}</span>`
                },
                {
                    name: `${this.columns.profile.title}`,
                    html: `<span>${this.columns.profile.title}</span>`
                },
                {
                    name: `${this.columns.profile.title}`,
                    html: `<span>${this.columns.profile.title}</span>`
                }
            ],
            [
                "",
                {
                    name: `${this.columns.profile.unit}`,
                    html: `<span>${this.columns.profile.unit}</span>`
                },
                {
                    name: `${this.columns.profile.unit}`,
                    html: `<span>${this.columns.profile.unit}</span>`
                },
                {
                    name: `${this.columns.profile.unit}`,
                    html: `<span>${this.columns.profile.unit}</span>`
                },
                {
                    name: `${this.columns.profile.unit}`,
                    html: `<span>${this.columns.profile.unit}</span>`
                }
            ]
        ];

        let values = [];

        for (let i = 1; i < 25; i++)
            values.push([i]);

        let dataObj = {};
        data.forEach(d => dataObj[d.type] = d);

        dataObj[this.columns.obligedAmounts.st.key].data.forEach(h => {
            values[h.hour].push(h.sell);
            values[h.hour].push(h.buy);
        });

        dataObj[this.columns.obligedAmounts.lt.key].data.forEach(h => {
            values[h.hour].push(h.sell);
            values[h.hour].push(h.buy);
        });

        return { headers, values }
    }

    getExpextedLevelledBorders() {
        return Object.keys(this.columns.levelledObligedAmounts.borders)
            .map(c => this.columns.levelledObligedAmounts.borders[c])
            .sort((a, b) => { return (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0) });
    }

    convertLevelledObligedAmountsJsonToTable(data, operationType) {
        const expectedBorders = this.getExpextedLevelledBorders();

        let headers = [
            [
                {
                    name: `${this.columns.hour.title}`,
                    html: `<span>${this.columns.hour.title}</span>`
                },
                ...expectedBorders.map(e => {
                    return {
                        name: `[DIRECTION] ${e.term} `,
                        html: `<span>[DIRECTION] ${e.term}</span>`
                    }
                }),
                {
                    name: `${this.columns.levelledObligedAmounts.total.name}`,
                    html: `<span>${this.columns.levelledObligedAmounts.total.name}</span>`
                }
            ],
            [
                {
                    name: `${this.columns.cet.title}`,
                    html: `<span>${this.columns.cet.title}</span>`
                },
                ...expectedBorders.map(e => {
                    return {
                        name: `${e.unit}`,
                        html: `<span>${e.unit}</span>`
                    }
                }),
                {
                    name: `${this.columns.levelledObligedAmounts.total.unit}`,
                    html: `<span>${this.columns.levelledObligedAmounts.total.unit}</span>`
                }
            ]
        ];

        let values = [];

        for (let i = 1; i < 25; i++)
            values.push([i]);

        let dataObj = {};
        data.filter(d => d.operation_type?.toLowerCase() === operationType.key)
            .forEach(d => dataObj[this.getBorderKey(d.border, d.type)] = d);

        expectedBorders.forEach((e, idx) => {
            if (dataObj[e.key]?.data) {
                this.columns.levelledObligedAmounts.borders[e.key].index = idx + 1;

                if (dataObj[e.key]?.direction) {
                    headers[0][idx + 1].name = headers[0][idx + 1].name.replace('[DIRECTION]', dataObj[e.key].direction);
                    headers[0][idx + 1].html = headers[0][idx + 1].html.replace('[DIRECTION]', dataObj[e.key].direction);
                }

                dataObj[e.key].data.forEach(h => {
                    values[h.hour].push(h[operationType.key]);
                });
            }
            else {
                values.forEach(v => {
                    v.push(0);
                });
            }
        });

        values.forEach(v => {
            const total = v.slice(1).reduce((a, b) => a + b, 0);
            v.push(total);
        });

        return { headers, values }
    }

    validateObligedValue(value) {
        if (isNaN(value))
            return portalMessages.EXCHANGE_OFFERS.OBLIGED_NAN;

        if (this.getCounterPartyConfigForActiveFilter()?.type !== this.viewTypes.levelled
            && Number(value) < 0)
            return portalMessages.EXCHANGE_OFFERS.OBLIGED_NO_NEGATIVE;

        return "";
    }

    validateObligedRow(row) {
        const stDetails = {
            buy: row[this.columnIndices.obligedAmounts.st.buy],
            sell: row[this.columnIndices.obligedAmounts.st.sell]
        };

        const ltDetails = {
            buy: row[this.columnIndices.obligedAmounts.lt.buy],
            sell: row[this.columnIndices.obligedAmounts.lt.sell]
        };


        let error = this.validateObligedValue(stDetails.buy)
            || this.validateObligedValue(stDetails.sell)
            || this.validateObligedValue(ltDetails.buy)
            || this.validateObligedValue(ltDetails.sell);

        if (error)
            return error;

        return "";
    }

    async onSaveLevelledObligedAmountsAsync(newData, oldData, operationType) {
        const previouslySavedTables = this.getPreviouslySavedLevelTables(operationType);
        this.editingOperationType = operationType;

        if (previouslySavedTables.length > 0) {
            this.setState({ showEditLevelledObligedDeleteModal: true });
            return false;
        }

        let spinnerKey = this.spinner.showSpinner();

        try {
            let obligedValueError = "";

            let body = {
                date: this.state.activeFilter.date,
                counter_party: this.state.activeFilter.counterParty,
                exchange: this.state.activeFilter.exchange,
                mail_sent: this.state.mailSent,
                ignore_obliged_check: this.state.ignoreObligedCheck,
                obliged_amounts: oldData.filter(o => o.operation_type?.toLowerCase() === operationType.key)
                    .map(o => {
                        const colIdx = this.columns.levelledObligedAmounts.borders[this.getBorderKey(o.border, o.type)]?.index;
                        return {
                            border: o.border,
                            obliged_id: o.obliged_id,
                            operation_type: o.operation_type,
                            type: o.type,
                            previous_data: o.obliged_id ? o.data : [],
                            updated_data: newData.values.map(n => {
                                obligedValueError = obligedValueError || this.validateObligedValue(n[colIdx]);

                                return {
                                    hour: n[0] - 1,
                                    [operationType.key]: isNaN(n[colIdx]) ? 0 : Number(n[colIdx])
                                }
                            })
                        }
                    })
            };

            if (obligedValueError) {
                this.showErrorMessage(obligedValueError);
                return false;
            }

            let response;

            try {
                response = await saveObligedAmounts(body);
            } catch (error) {
                handleApiError(error);
                return false;
            }

            if (response.data.success) {
                if (response.data.success.check_list?.length) {

                    this.setState({
                        obligedCheckList: response.data.success.check_list,
                        showLevelObligedCheckListModal: true,
                        newObligedData: newData, oldObligedData: oldData, obligedOperationType: operationType
                    })
                    return false;
                } else {
                    this.onCloseObligedCheckConfirmationModal()
                    await this.refreshBorderTablesAsync();
                    alertSuccess(portalMessages.EXCHANGE_OFFERS.OBLIGED_UPDATED);
                    return true;
                }
            }
            else {
                this.setState({ ignoreObligedCheck: false })
                this.showErrorMessage(response.data.error || {}, "editObligedValues");
                return false;
            }

        } catch (error) {
            this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Obliged Amounts)');
            return false;
        } finally {
            this.spinner.hideSpinner(spinnerKey);
        }
    }

    onSaveObligedAmountsAsync = async (newData, oldData) => {
        let spinnerKey = this.spinner.showSpinner();
        try {
            let body = {
                date: this.state.activeFilter.date,
                counter_party: this.state.activeFilter.counterParty,
                exchange: this.state.activeFilter.exchange,
                mail_sent: this.state.mailSent,
                obliged_amounts: [],
                ignore_obliged_check: this.state.ignoreObligedCheck
            };

            let stDetails = {
                type: this.columns.obligedAmounts.st.key,
                updated_data: []
            };

            let ltDetails = {
                type: this.columns.obligedAmounts.lt.key,
                updated_data: []
            };

            let stOldData, ltOldData;

            if (oldData[0].type === this.columns.obligedAmounts.st.key) {
                stOldData = oldData[0];
                ltOldData = oldData[1];
            }
            else {
                stOldData = oldData[1];
                ltOldData = oldData[0];
            }

            stDetails.obliged_id = stOldData.obliged_id;
            stDetails.previous_data = stDetails.obliged_id ? stOldData.data : [];

            ltDetails.obliged_id = ltOldData.obliged_id;
            ltDetails.previous_data = ltDetails.obliged_id ? ltOldData.data : [];

            let currentRow, inputError;

            for (let i = 0; i < newData.values.length; i++) {
                currentRow = newData.values[i];

                inputError = this.validateObligedRow(currentRow);

                if (inputError) {
                    this.showErrorMessage(inputError);
                    return false;
                }

                currentRow = currentRow.map(c => isNaN(c) ? c : Number(c));

                stDetails.updated_data.push({
                    hour: currentRow[this.columnIndices.obligedAmounts.hour] - 1,
                    buy: currentRow[this.columnIndices.obligedAmounts.st.buy],
                    sell: currentRow[this.columnIndices.obligedAmounts.st.sell]
                });

                ltDetails.updated_data.push({
                    hour: currentRow[this.columnIndices.obligedAmounts.hour] - 1,
                    buy: currentRow[this.columnIndices.obligedAmounts.lt.buy],
                    sell: currentRow[this.columnIndices.obligedAmounts.lt.sell]
                });
            }

            body.obliged_amounts.push(stDetails);
            body.obliged_amounts.push(ltDetails);

            let response;

            try {
                response = await saveObligedAmounts(body);
            } catch (error) {
                handleApiError(error);
                return false;
            }

            if (response.data.success) {
                if (response.data.success.check_list?.length) {
                    this.setState({
                        obligedCheckList: response.data.success.check_list,
                        showObligedCheckListModal: true,
                        newObligedData: newData, oldObligedData: oldData
                    })
                    return false;
                } else {
                    this.onCloseObligedCheckConfirmationModal()
                    await this.refreshBorderTablesAsync();
                    alertSuccess(portalMessages.EXCHANGE_OFFERS.OBLIGED_UPDATED);
                    return true;
                }
            }
            else {
                this.setState({ ignoreObligedCheck: false })
                this.showErrorMessage(response.data.error || {}, "editObligedValues");
                return false;
            }

        } catch (error) {
            this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Obliged Amounts)');
            return false;
        } finally {
            this.spinner.hideSpinner(spinnerKey);
        }
    }

    importAllBorders(e, operationType) {
        if (!this.borderTableRefs || this.borderTableRefs.length === 0) {
            this.showErrorMessage(portalMessages.EXCHANGE_OFFERS.NO_TABLE_TO_IMPORT);
            return;
        }

        const relatedTableRefs = this.borderTableRefs.filter(c => this.validateOperationType(c.current.props.operationType, operationType));

        if (!relatedTableRefs || relatedTableRefs.length === 0) {
            this.showErrorMessage(portalMessages.EXCHANGE_OFFERS.NO_TABLE_TO_IMPORT);
            return;
        }

        loadExcel(e.target.files[0], (data) => {
            const borderNamesRow = data[0];
            const borderIndices = {};

            borderNamesRow.forEach((borderName, idx) => {
                if (borderName && borderName.trim())
                    borderIndices[borderName] = { startIndex: idx }
            });

            relatedTableRefs.forEach(r => {
                const control = r.current;

                const borderDetails = borderIndices[control.getTitle()];

                if (!borderDetails)
                    return;

                const tableColumnCount = control.getOriginalItems(true).values[0].length;
                borderIndices[control.getTitle()].endIndex = borderDetails.startIndex + tableColumnCount - 1;
                borderIndices[control.getTitle()].tableRef = control;
            });

            const results = { true: 0, false: 0 };

            Object.keys(borderIndices).forEach(border => {
                const borderDetails = borderIndices[border];

                if (!borderDetails.tableRef) {
                    this.showErrorMessage(portalMessages.EXCHANGE_OFFERS.BORDER_NOT_LISTED.replace('[BORDER]', border));
                    return;
                }

                const uploadResult = borderDetails.tableRef.handleUploadedExcel(data.slice(1).map(fileRow => {
                    return fileRow.slice(borderDetails.startIndex, borderDetails.endIndex + 1);
                }));

                results[uploadResult]++;
            });

            if (!results.true)
                alertError(`Upload failed for ${this.getTableMessagePart(results.false)}.`);
            else if (results.false)
                alertWarning(`Upload successful for ${this.getTableMessagePart(results.true)}, failed for ${this.getTableMessagePart(results.false)}.`);
        });
    }

    getTableMessagePart(count) {
        if (count > 1)
            return count + " tables";
        return count + " table";
    }

    showExportOptions(operationType) {
        this.editingOperationType = operationType;

        if (this.editingTableCount > 0) {
            this.setState({ showExportOptions: true });
        }
        else {
            this.exportAllBorders();
        }
    }

    exportAllBorders(exportOriginal = true) {
        try {
            if (!this.borderTableRefs || this.borderTableRefs.length === 0) {
                this.showErrorMessage(portalMessages.EXCHANGE_OFFERS.NO_TABLE_TO_EXPORT);
                return;
            }

            const relatedTableRefs = this.borderTableRefs.filter(c => this.validateOperationType(c.current.props.operationType)
                && c.current.props.relatedInfo.offer_type !== this.servicingDataTag);

            if (!relatedTableRefs || relatedTableRefs.length === 0) {
                this.showErrorMessage(portalMessages.EXCHANGE_OFFERS.NO_TABLE_TO_EXPORT);
                return;
            }

            const currentOperationType = relatedTableRefs[0].current.props.operationType?.title;

            const allTables = {
                headers: relatedTableRefs[0].current.getOriginalItems(true).headers.map(() => []),
                values: relatedTableRefs[0].current.getOriginalItems(true).values.map(() => [])
            };

            allTables.headers.push([]); //extra header row for border names

            relatedTableRefs.forEach(r => {
                const control = r.current;
                let items;

                if (exportOriginal || control.getEditedItems().values.length === 0)
                    items = control.getOriginalItems(true);
                else
                    items = control.getEditedItems(true);

                allTables.headers[0].push(control.getTitle());

                for (let i = 1; i < items.headers[0].length; i++) {
                    allTables.headers[0].push(" ");
                }

                for (let i = 0; i < items.headers.length; i++) {
                    items.headers[i].forEach(headerCell => {
                        allTables.headers[i + 1].push(headerCell);
                    });
                }

                allTables.headers.forEach((headerRow) => {
                    headerRow.push("");
                });

                items.values.forEach((valueRow, idx) => {
                    valueRow.forEach(valueCell => {
                        allTables.values[idx].push(valueCell);
                    });
                });

                allTables.values.forEach((valueRow) => {
                    valueRow.push("");
                });
            });

            createExcel(
                allTables.headers,
                allTables.values,
                `ExchangeOffers_${this.state.activeFilter.exchange}_${this.state.activeFilter.counterParty}_Borders${currentOperationType ? '_' + currentOperationType : ''}_${this.state.activeFilter.date}`
            );
        }
        finally {
            this.onHideExportModal();
        }
    }

    cancelAllBorders() {
        this.setState({ showConfirmCancelModal: true });
    }

    confirmCancelAllBorders() {
        if (!this.borderTableRefs || this.borderTableRefs.length === 0) {
            return;
        }

        this.borderTableRefs.forEach(r => {
            if (r.current.isBeingEdited())
                r.current.confirmEditCancelling();
        });

        this.setState({ showConfirmCancelModal: false });
    }

    saveAllBordersAsync = async (operationType, deletedLevelToIgnore) => {
        const allData = [];
        const affectedControls = [];
        let newData;

        this.borderTableRefs.forEach(r => {
            const control = r.current;
            newData = null;

            if (control.isBeingEdited())
                newData = control.getEditedItems();
            else if (this.validateOperationType(control.props.operationType, operationType))
                newData = control.getOriginalItems();

            if (newData && (!deletedLevelToIgnore || deletedLevelToIgnore.length === 0 || !deletedLevelToIgnore.includes(control.props.relatedInfo.level))) {
                affectedControls.push(control);
                allData.push({
                    borderDetail: control.props.relatedInfo,
                    newData
                });
            }
        });

        const result = await this.onSaveBorderTableAsync(allData, operationType);

        if (result) {
            affectedControls.forEach(r => {
                if (r.isBeingEdited() && !r.props.isNew)
                    r.onExternalSaveChagnes();
                else if (r.props.isNew)
                    r.props.onCloseEditMode();
            });
        }

        return result;
    }

    createOtherCurrencyRateRow() {
        const rates = [];

        const currency = this.getCurrentCurrency();

        if (this.currencyConversionExists(currency)) {
            const rate = this.getCurrencyRate(currency);
            if (!isEmpty(rate))
                rates.push(rate);
        }

        if (this.state.borderList.find(b => b.border.toLowerCase().includes('tr'))) {
            const rate = this.getCurrencyRate('TRY');
            if (!isEmpty(rate) && !rates.find(r => r.currency === rate.currency))
                rates.push(rate);
        }

        if (isEmpty(rates))
            return null;

        return (
            <div>
                <div className='v-currency-labels'>
                    {rates.map(rate => {
                        return <label key={rate.currency} className="v-font v-font-size">{`${rate.currency}: ${rate.value}`}</label>
                    })}
                </div>
            </div>
        );
    }

    createBorderedObligedTable() {
        return (
            <VTable
                ref={this.obligedTableRef}
                tabIndex="1"
                key="obligedAmounts"
                title={`${this.getCurrentExchange()?.country} Obliged Amount`}
                simpleNumbers
                onReset={() => this.setState({ showResetSeepexObligedModal: true })}
                onError={(message) => this.showErrorMessage(message)}
                customColumnClasses={{ 0: "v-column-narrow-bold" }}
                scales={{
                    [this.columns.obligedAmounts.st.sell.title]: 0,
                    [this.columns.obligedAmounts.st.buy.title]: 0,
                    [this.columns.obligedAmounts.lt.sell.title]: 0,
                    [this.columns.obligedAmounts.lt.buy.title]: 0
                }}
                exportFileName={`ExchangeOffers_ObligedAmount_${this.state.activeFilter.exchange}_${this.state.activeFilter.counterParty}_${this.state.activeFilter.date}`}
                onOpenEditMode={() => this.onOpenObligedTableEditMode()}
                onCloseEditMode={() => this.onCloseObligedTableEditMode()}
                inputType='number'
                readonlyColumns={[this.columns.hour.title]}
                items={this.convertObligedAmountsJsonToTable(this.state.obligedAmounts)}
                onSaveChangesAsync={(newData) => this.onSaveObligedAmountsAsync(newData, this.state.obligedAmounts)}
                headerButtons={{
                    showEditButton: this.editObligedEnabled(),
                    showExportExcelButton: true,
                    showImportExcelButton: this.editObligedEnabled(),
                    showResetTableButton: this.editObligedEnabled()
                }}
                showTotal
            />
        );
    }

    async onConfirmDeleteAllLevelsAsync(operationType) {
        const previouslySavedTables = this.getPreviouslySavedLevelTables(operationType);

        if (previouslySavedTables.length === 0)
            return;

        const allOfferIds = previouslySavedTables.sort((a, b) => { return (a.level > b.level) ? 1 : ((b.level > a.level) ? -1 : 0) })
            .flatMap(p => [p.offer_id, p.other_currency_data[0]?.offer_id]);

        return await this.deleteOfferTablesByIdAsync(allOfferIds, operationType);
    }

    getPreviouslySavedLevelTables(operationType) {
        return this.state.borderList.filter(b => b.operation_type?.toLowerCase() === operationType.key && b.offer_id);
    }

    onResetLevelledObliged(operationType) {
        this.editingOperationType = operationType;
        this.setState({ showDeleteLevelledObligedDeleteModal: true })
    }

    createLevelledObligedTable(operationType) {
        const levelledObliedRef = React.createRef();

        this.editingObligedTableRefs = {
            ...this.editingObligedTableRefs,
            [operationType.key]: levelledObliedRef
        };

        return (
            <VTable
                ref={levelledObliedRef}
                tabIndex="1"
                key="obligedAmounts"
                title={`${this.getCurrentExchange()?.country} ${operationType.title}`}
                simpleNumbers
                customColumnClasses={{ 0: "v-column-narrow-bold" }}
                onReset={() => this.onResetLevelledObliged(operationType)}
                onError={(message) => this.showErrorMessage(message)}
                scales={{ '*': 0 }}
                exportFileName={`ExchangeOffers_${this.state.activeFilter.exchange}_${this.state.activeFilter.counterParty}_${operationType.title}Totals_${this.state.activeFilter.date}`}
                onOpenEditMode={() => { this.onOpenObligedTableEditMode(); }}
                onCloseEditMode={() => this.onCloseObligedTableEditMode()}
                inputType='number'
                readonlyColumnIndices={[0, 4]}
                items={this.convertLevelledObligedAmountsJsonToTable(this.state.obligedAmounts, operationType)}
                onSaveChangesAsync={(newData) => this.onSaveLevelledObligedAmountsAsync(newData, this.state.obligedAmounts, operationType)}
                headerButtons={{
                    showEditButton: this.editObligedEnabled(),
                    showExportExcelButton: true,
                    showImportExcelButton: this.editObligedEnabled(),
                    showResetTableButton: this.editObligedEnabled()
                }}
                showSpecialAgg
            />
        );
    }

    getBorderOperations(operationType) {

        let opButtonTexts;

        if (operationType) {
            opButtonTexts = {
                upload: `Upload ${operationType.title}s`,
                download: `Download ${operationType.title}s`,
                save: `Save ${operationType.title}s`,
                cancel: `Cancel ${operationType.title}s`
            };
        }
        else {
            opButtonTexts = {
                upload: 'Upload Borders',
                download: 'Download Borders',
                save: 'Save All',
                cancel: 'Cancel All'
            };
        }

        return (
            <React.Fragment>
                <VFileUploadIcon
                    className="btn v-button v-tab-button"
                    size=""
                    disabled={!this.importAllEnabled(operationType)}
                    accept={['.xlsx', '.xls']}
                    labeltext={opButtonTexts.upload}
                    key='importTableIcon'
                    onFileChanged={(e) => this.importAllBorders(e, operationType)}
                />
                <button className="btn v-button v-tab-button"
                    disabled={!this.exportAllEnabled(operationType)}
                    onClick={() => this.showExportOptions(operationType)}>
                    <i aria-hidden="true" className="fa fa-cloud-download fa-fw" />
                    {opButtonTexts.download}
                </button>
                <button className="btn v-button v-tab-button"
                    disabled={!this.saveAllEnabled(operationType)}
                    onClick={() => this.saveAllBordersAsync(operationType)}>
                    <i aria-hidden="true" className="fa fa-check fa-fw" />
                    {opButtonTexts.save}
                </button>
                <button className="btn v-button v-tab-button"
                    disabled={!this.saveAllEnabled(operationType)}
                    onClick={() => this.cancelAllBorders(operationType)}>
                    <i aria-hidden="true" className="fa fa-ban fa-fw" />
                    {opButtonTexts.cancel}
                </button>
            </React.Fragment>
        );
    }

    addNewControlLevelEnabled() {
        return this.editObligedEnabled()
            && this.editBorderEnabled();
    }

    addNewControlLevel() {
        this.setState({ addNewControlLevel: true });
    }

    addNewControlLevelFromFile(e) {
        loadExcel(e.target.files[0], (data) => this.handleNewControlLevelFile(data));
    }

    handleNewControlLevelFile(data) {
        const currency = this.getCurrentCurrency();

        let values;
        if (!this.currencyConversionExists(currency)) {
            // working with EUR
            values = data.slice(3)
        }
        else {
            // working with different currency
            const rate = this.getCurrencyRate(currency);
            let sellValue, buyValue;

            values = data.slice(3).map(hourDetails => {
                sellValue = hourDetails[2];
                buyValue = hourDetails[4];

                return [
                    hourDetails[0],
                    hourDetails[1],
                    sellValue.toFixed(2),
                    Number(this.getConvertedCurrency(sellValue, this.columns.eur.title, rate)).toFixed(2),
                    hourDetails[3],
                    buyValue.toFixed(2),
                    Number(this.getConvertedCurrency(buyValue, this.columns.eur.title, rate)).toFixed(2)
                ];
            });
        }
        this.setState({ addNewControlLevel: true, controlLevelFileData: values });
    }

    getControlLevels() {
        return (
            <ControlLevels
                border={this.totalDataTag}
                offerType={this.controlDataTag}
                currency={this.getCurrentCurrency()}
                controlList={this.state.controlList}
                jsonToTableConverter={(...params) => this.convertBorderedJsonToTable(...params, this.totalDataTag)}
                showErrorMessage={(...params) => this.showErrorMessage(...params)}
                exchange={this.state.activeFilter.exchange}
                counterParty={this.state.activeFilter.counterParty}
                date={this.state.activeFilter.date}
                scalesByIndices={columCount => this.getBorderScales(columCount)}
                specialWordsByIndices={columCount => this.getSpecialWords(columCount)}
                readonlyColumnIndices={columnCount => this.getBorderReadonlyColumnIndices(columnCount)}
                ignoredColumnsForFiles={columnCount => this.getBorderIgnoredColumnsForFiles(columnCount)}
                inputHooks={columnCount => this.getBorderInputHooks(columnCount)}
                editEnabled={this.editBorderEnabled()}
                onEditModeOpened={() => this.onOpenBorderTableEditMode()}
                onEditModeClosed={() => this.onCloseBorderTableEditMode()}
                addNewLevel={this.state.addNewControlLevel}
                controlLevelFileData={this.state.controlLevelFileData}
                onSaveAsync={(...params) => this.onSaveBorderTableAsync(...params)}
                onDeleteAsync={(ids) => this.deleteOfferTablesByIdAsync(ids)}
                hideSaveButton={() => this.hideSaveButton()}
                borderTableRefs={this.borderTableRefs}
                getDeleteLevelConfirmationMessage={(level) => this.getDeleteLevelConfirmationMessage(
                    portalMessages.EXCHANGE_OFFERS.DELETE_OFFERS,
                    this.state.activeFilter.exchange,
                    this.state.activeFilter.counterParty,
                    "",
                    level
                )}
            />
        );
    }

    getServicingOffers() {
        return (
            <ServicingOffers
                border={this.totalDataTag}
                totalDataTag={this.totalDataTag}
                showBorderOption={this.state.selectedCounterPartyConfig?.requiresBorderOnServicing}
                offerType={this.servicingDataTag}
                currency={this.getCurrentCurrency()}
                exchange={this.state.activeFilter.exchange}
                counterParty={this.state.activeFilter.counterParty}
                date={this.state.activeFilter.date}
                companies={Object.keys(this.state.servicingCompanies)}
                companyBorderPairs={this.state.servicingCompanies}
                servicingList={this.state.servicingList}
                jsonToTableConverter={(...params) => this.convertBorderedJsonToTable(...params)}
                showErrorMessage={(...params) => this.showErrorMessage(...params)}
                scalesByIndices={columCount => this.getBorderScales(columCount, this.servicingDataTag)}
                specialWordsByIndices={columCount => this.getSpecialWords(columCount, this.servicingDataTag)}
                readonlyColumnIndices={columnCount => this.getBorderReadonlyColumnIndices(columnCount)}
                ignoredColumnsForFiles={columnCount => this.getBorderIgnoredColumnsForFiles(columnCount)}
                inputHooks={columnCount => this.getBorderInputHooks(columnCount)}
                editEnabled={this.addNewControlLevelEnabled() && !this.state.selectedCounterPartyConfig?.disableServicing}
                onEditModeOpened={() => this.onOpenBorderTableEditMode()}
                onEditModeClosed={() => this.onCloseBorderTableEditMode()}
                onSaveAsync={(...params) => this.onSaveBorderTableAsync(...params)}
                onDeleteAsync={(ids) => this.deleteOfferTablesByIdAsync(ids)}
                hideSaveButton={() => this.hideSaveButton()}
                borderTableRefs={this.borderTableRefs}
                editingInProgress={this.state.editingTableCount > 0}
                getDeleteLevelConfirmationMessage={(level, servicingCompany) => this.getDeleteLevelConfirmationMessage(
                    portalMessages.EXCHANGE_OFFERS.DELETE_OFFERS,
                    this.state.activeFilter.exchange,
                    this.state.activeFilter.counterParty,
                    "",
                    level,
                    servicingCompany
                )}
                anyPriceSpecialValue={this.anyPriceSpecialValue}
            />
        );
    }

    getBorderedView() {
        return (
            <React.Fragment>
                <div className='row'>
                    <div className='col-12'
                        style={{ textAlign: 'right', margin: '5px 0 0 0', padding: '0' }}>
                        {
                            this.getBorderOperations()
                        }
                    </div>
                </div>
                {
                    (this.getCounterPartyConfigForActiveFilter()?.type === this.viewTypes.bordered) &&
                    <div className='row'>
                        <div className='col-12'
                            style={{ textAlign: 'right', margin: '5px 0 0 0', padding: '0' }}>
                            <div className='col-md-12'>
                                <span
                                    className={`${this.addNewControlLevelEnabled() ? 'v-link-button' : 'v-disabled-link-button'}`}
                                    onClick={() => this.addNewControlLevel()}>
                                    <i aria-hidden="true" className="fa fa-plus fa-fw" />
                                    Add Control Level
                                </span>
                                <VFileUploadIcon
                                    accept={['.xlsx', '.xls']}
                                    labeltext={`Upload Control Level`}
                                    title='Upload File'
                                    key='importTableIcon'
                                    onFileChanged={(e) => this.addNewControlLevelFromFile(e)}
                                />
                            </div>
                        </div>
                    </div>
                }
                <div className='row'>
                    <div className='v-infinite-width v-table-list'>
                        {

                            this.createBorderedTables()
                        }
                        {
                            (this.getCounterPartyConfigForActiveFilter()?.showObliged
                                && this.state.obligedAmounts
                                && this.state.obligedAmounts.length > 0) &&
                            this.createBorderedObligedTable()
                        }
                        {
                            this.getControlLevels()
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    addNewLevel(operationType) {
        let initialValuesListed = false;

        if (this.state.borderList && this.state.borderList.length > 0) {
            const allLevelsForOpType = this.state.borderList.filter(b => b.operation_type?.toLowerCase() === operationType.key);
            const savedLevels = allLevelsForOpType.filter(b => b.offer_id);
            initialValuesListed = allLevelsForOpType.length > 0 && savedLevels.length === 0;
        }

        if (initialValuesListed) {
            this.editingOperationType = operationType;
            this.setState({ showAddLevelModal: true });
        }
        else {
            this.addNewLevelAsync(operationType);
        }
    }

    async addNewLevelAsync(operationType) {
        const spinnerKey = this.spinner.showSpinner();

        try {
            let response;

            try {
                response = await getNextLevelOffers({
                    filter: {
                        date: this.state.activeFilter.date,
                        counter_party: this.state.activeFilter.counterParty,
                        exchange: this.state.activeFilter.exchange,
                        operation_type: operationType.key
                    }
                });
            } catch (error) {
                handleApiError(error);
                return;
            }

            if (!response.data.success) {
                this.showErrorMessage(response.data.error || {}, "addLevel");
                return;
            }

            const newOfferLevel = response.data.success.border_list.filter(b => b.operation_type?.toLowerCase() === operationType.key)[0];

            this.setState({ newOfferLevel }, () => {
                const scrollTo = document.getElementById(`endOf${operationType.title}Levels`);
                if (scrollTo)
                    scrollTo.scrollIntoView(false);
            });
        }
        finally {
            this.spinner.hideSpinner(spinnerKey);
        }
    }

    createNewOfferTable(operationType) {
        if (operationType.key !== this.state.newOfferLevel.operation_type.toLowerCase())
            return null;

        const borderDetail = this.state.newOfferLevel;
        const ref = React.createRef();
        this.borderTableRefs.push(ref);
        return this.createBorderTable(
            borderDetail,
            ref,
            this.state.borderList.length / 2,
            borderDetail.level,
            operationType,
            true
        );
    }

    newOfferTableExists() {
        return this.state.newOfferLevel && !isEmpty(this.state.newOfferLevel);
    }

    addNewLevelEnabled(operationType) {
        return !this.newOfferTableExists()
            && this.editBorderEnabled(operationType)
            && this.state.editingTableCount === 0
            && !this.state.editingTables.editingObliged;
    }

    getLevelledViewForOperationType(operationType) {
        return (
            <React.Fragment>
                <div className='row'>
                    <div className='col-12'
                        style={{ textAlign: 'right', margin: '5px 0 0 0', padding: '0' }}>
                        {
                            this.getBorderOperations(operationType)
                        }
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'
                        style={{ textAlign: 'right', margin: '5px 0 0 0', padding: '0' }}>
                        <div className='col-md-12'>
                            <span className={`${!this.addNewLevelEnabled(operationType) ? 'v-disabled-link-button' : 'v-link-button'}`}
                                onClick={() => this.addNewLevel(operationType)}>
                                <i aria-hidden="true" className="fa fa-plus fa-fw" />
                                Add {operationType.title} Level
                        </span>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='v-infinite-width v-table-list'>
                        {
                            (this.getCounterPartyConfigForActiveFilter()?.showObliged
                                && this.state.obligedAmounts
                                && this.state.obligedAmounts.length > 0) &&
                            this.createLevelledObligedTable(operationType)
                        }
                        {
                            this.createLevelledTables(operationType)
                        }
                        {
                            this.newOfferTableExists() &&
                            this.createNewOfferTable(operationType)
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    getLevelledView() {
        return (
            <React.Fragment>
                {this.getLevelledViewForOperationType(this.operationTypes.sell)}
                <br />
                {this.getLevelledViewForOperationType(this.operationTypes.buy)}
            </React.Fragment>
        );
    }

    renderVitusTab() {
        this.borderTableRefs = [];

        return (
            <VTab key={this.tabKeys.vitus}
                eventKey='Vitus'
                title='Vitus'>
                <div className='container'>
                    {
                        (this.getCounterPartyConfigForActiveFilter()?.type === this.viewTypes.bordered) &&
                        this.getBorderedView()
                    }
                    {
                        (this.getCounterPartyConfigForActiveFilter()?.type === this.viewTypes.levelled) &&
                        this.getLevelledView()
                    }
                </div>
            </VTab>
        );
    }

    renderServicingTab() {
        return (
            <VTab
                disabled={this.state.selectedCounterPartyConfig?.disableServicing}
                key={this.tabKeys.servicing}
                eventKey='Servicing'
                title='Servicing'>
                {this.getServicingOffers()}
            </VTab>
        );
    }

    addThousandsDelimeterAndScale(num) {
        return Number(num).toLocaleString('en-US', { 'minimumFractionDigits': 2, 'maximumFractionDigits': 2 });
    }

    createCollateralGuaranteeTable() {
        const otherCurrency = 'ron';
        const otherCurrencyTitle = 'RON';

        const totals = {
            buy: { eur: 0, [otherCurrency]: 0 },
            sell: { eur: 0, [otherCurrency]: 0 }
        }

        this.state.allTablesList.forEach(b => {
            const currency = b.currency.toLowerCase();
            let opTypes;

            if (b.operation_type.toLowerCase() === this.operationTypes.all.key) {
                // servicing levels have buy and sell together
                opTypes = [this.operationTypes.buy.key, this.operationTypes.sell.key];
            } else {
                // opcom levels have seperate buy and sell arrays
                opTypes = [b.operation_type.toLowerCase()];
            }

            opTypes.forEach(opType => {
                b.data.forEach(d => {
                    totals[opType][currency] += d[opType].price_offer * d[opType].profile;
                });

                if (b.other_currency_data
                    && b.other_currency_data.length === 1
                    && b.other_currency_data[0].data
                    && !isEmpty(b.other_currency_data[0].data)) {

                    b.other_currency_data[0].data.forEach(d => {
                        totals[opType][otherCurrency] += d[opType].price_offer * d[opType].profile;
                    });
                }
                else {
                    b.data.forEach(d => {
                        totals[opType][otherCurrency] += Number(this.getConvertedCurrency(d[opType].price_offer, this.columns.eur.title)) * d[opType].profile;
                    });
                }
            });
        });

        const items = {
            headers: [["", this.columns.eur.title, otherCurrencyTitle]],
            values: [
                ["Sell", this.addThousandsDelimeterAndScale(totals.sell.eur), this.addThousandsDelimeterAndScale(totals.sell[otherCurrency])],
                ["Buy", this.addThousandsDelimeterAndScale(totals.buy.eur), this.addThousandsDelimeterAndScale(totals.buy[otherCurrency])]
            ]
        };

        return (
            <div style={{ marginLeft: '50px' }}>
                <VTable
                    key="CollateralGuarantee"
                    title="Collateral Guarantee"
                    simpleNumbers
                    customColumnClasses={{ 0: "v-column-narrow-bold" }}
                    inputType='number'
                    exportFileName={`ExchangeOffers_CollateralGuarantee_${this.state.activeFilter.exchange}_${this.state.activeFilter.counterParty}_${this.state.activeFilter.date}`}
                    items={items}
                    headerButtons={{
                        showExportExcelButton: true
                    }}
                />
            </div>
        );
    }

    createNonLeveledCollateralGuaranteeTable() {
        if (this.state.activeFilter.exchange === 'IBEX' && this.state.activeFilter.counterParty === 'Energovia'){
            var otherCurrency = 'bgn';
            var otherCurrencyTitle = 'BGN';
            var totals = {
                buy: { eur: 0, [otherCurrency]: 0 },
                sell: { eur: 0, [otherCurrency]: 0 }
            }
            var is_convertible = true;
        }
        else{
            var totals = {
                buy: { eur: 0},
                sell: { eur: 0}
            }
        }
        this.state.allTablesList.forEach(b => {
            const currency = b.currency.toLowerCase();
            let opTypes;
            if (b.operation_type.toLowerCase() === this.operationTypes.all.key) {
                // servicing levels have buy and sell together
                opTypes = [this.operationTypes.buy.key, this.operationTypes.sell.key];
            } else {
                opTypes = [this.operationTypes.buy.key, this.operationTypes.sell.key];
            }
            opTypes.forEach(opType => {
                b.data.forEach(d => {
                    if (!(d[opType].price_offer === 'Any Price')){
                        totals[opType][currency] += d[opType].price_offer * d[opType].profile;
                    }
                });
                if (b.other_currency_data
                    && b.other_currency_data.length === 1
                    && b.other_currency_data[0].data
                    && !isEmpty(b.other_currency_data[0].data)) {
                    b.other_currency_data[0].data.forEach(d => {
                        if (!(d[opType].price_offer === 'Any Price')){
                            totals[opType][otherCurrency] += d[opType].price_offer * d[opType].profile;
                        }
                    });
                }
                else {
                    b.data.forEach(d => {
                        if (!(d[opType].price_offer === 'Any Price')){
                    
                            totals[opType][otherCurrency] += Number(this.getConvertedCurrency(d[opType].price_offer, this.columns.eur.title)) * d[opType].profile;
                        }
                    });
                }
            });
        });
        if (is_convertible){
            var items = {
                headers: [["", this.columns.eur.title, otherCurrencyTitle]],
                values: [
                    ["Sell", this.addThousandsDelimeterAndScale(totals.sell.eur), this.addThousandsDelimeterAndScale(totals.sell[otherCurrency])],
                    ["Buy", this.addThousandsDelimeterAndScale(totals.buy.eur), this.addThousandsDelimeterAndScale(totals.buy[otherCurrency])]
                ]
            };
        }
        else{
            var items = {
                headers: [["", this.columns.eur.title]],
                values: [
                    ["Sell", this.addThousandsDelimeterAndScale(totals.sell.eur)],
                    ["Buy", this.addThousandsDelimeterAndScale(totals.buy.eur)]
                ]
            };
        }
        return (
            <div style={{ marginLeft: '50px' }}>
                <VTable
                    key="CollateralGuarantee"
                    title="Collateral Guarantee"
                    simpleNumbers
                    customColumnClasses={{ 0: "v-column-narrow-bold" }}
                    inputType='number'
                    exportFileName={`ExchangeOffers_CollateralGuarantee_${this.state.activeFilter.exchange}_${this.state.activeFilter.counterParty}_${this.state.activeFilter.date}`}
                    items={items}
                    headerButtons={{
                        showExportExcelButton: true
                    }}
                />
            </div>
        );
    }


    renderTotalTab() {
        // ignore servicing on obliged because servicing does not have obliged values but end point returns it anyway
        const totalObliged = this.state.totalObliged.filter(d => d.type !== this.columns.total.servicing.key);

        return (
            <VTab
                key={this.tabKeys.total}
                eventKey='Total'
                title='Total'>
                <div className='container'>
                    <div className='row'>
                        <div className='v-infinite-width v-table-list'>
                            {
                                this.getCounterPartyConfigForActiveFilter()?.type !== this.viewTypes.levelled
                                && this.createTotalTable("Total Obliged Amount", totalObliged)
                            }
                            {
                                this.createTotalTable("Total Amount", this.state.total)
                            }
                            {
                                this.getCounterPartyConfigForActiveFilter()?.type === this.viewTypes.levelled
                                && this.createCollateralGuaranteeTable()
                            }
                            {
                                this.getCounterPartyConfigForActiveFilter()?.type !== this.viewTypes.levelled
                                && this.createNonLeveledCollateralGuaranteeTable()
                            }
                        </div>
                    </div>
                </div>
            </VTab>
        );
    }

    renderResultTab(operationType) {
        const currentParams = this.getCurrentExchange();

        let title, componentIsReady = false, items, childComponent = null;

        const resultKey = `${this.tabKeys.result}_${operationType.key}`;

        switch (currentParams.format) {
            case this.resultFormats.matrix.format:
                if (operationType === this.operationTypes.buy) // there is a single matrix for both operation types, it will be created on sell request
                    return null;

                //matrix
                title = this.resultFormats.matrix.title;
                componentIsReady = this.state.matrixData && !isEmpty(this.state.matrixData);
                items = this.matrixJsonToTable(this.state.matrixData);
                if (!isEmpty(items))
                    childComponent = (
                        <div className='v-infinite-width'>
                            <VMatrix items={items} />
                        </div>
                    );
                break;

            case this.resultFormats.horizontalTable.format:
                //horizontal
                title = `${this.resultFormats.horizontalTable.title}-${operationType.title}`;
                componentIsReady = this.state.horizontalTableData && !isEmpty(this.state.horizontalTableData);
                if (!isEmpty(this.state.horizontalTableData)) {
                    const horizontalTableDetails = this.state.horizontalTableData;
                    const horizontalTableData = horizontalTableDetails.horizontal_table.filter(h => h.operation_type?.toLowerCase() === operationType.key);

                    if (!horizontalTableData.length > 0)
                        break;

                    childComponent = (
                        <div className='v-infinite-width'>
                            <VHorizontalTable
                                messageIdentifier={this.state.activeFilter.date.replaceAll('-', '')}
                                messageVersion={horizontalTableDetails.message_version}
                                offerType={horizontalTableData[0].operation_type}
                                transactionZone={horizontalTableDetails.transaction_zone}
                                participantCode={horizontalTableDetails.participant_code}
                                data={horizontalTableData[0].data}
                            />
                        </div>
                    );
                }
                break;

            case this.resultFormats.verticalTable.format:
            case this.resultFormats.verticalTableNoControl.format:
                return null;

            default:
                console.log("Unexpexted format", currentParams.format); // warn the developer
                return null;
        }

        return (
            <VTab
                className={componentIsReady ? '' : 'v-component-spinner-loader'}
                key={resultKey}
                eventKey={resultKey}
                title={title}>

                {componentIsReady ?
                    childComponent
                    :
                    <VComponentSpinner />
                }
            </VTab>
        );
    }

    onTabChanged(key) {
        this.onTabChangedAsync(key);
    }

    onTabChangedAsync = async (key) => {
        if (!key?.includes(this.tabKeys.result))
            return;

        await this.prepareResultDataAsync();

        this.setState({ activeTab: key });
    }

    async prepareResultDataAsync() {
        const currentParams = this.getCurrentExchange();

        switch (currentParams?.format) {
            case this.resultFormats.matrix.format:
                if (!this.state.matrixData || isEmpty(this.state.matrixData))
                    await this.getMatrixData();
                break;

            case this.resultFormats.horizontalTable.format:
                if (!this.state.horizontalTableData || isEmpty(this.state.horizontalTableData))
                    await this.getHorizontalTable();
                break;

            default:
                return;
        }

    }

    prepareCurrentDataForResultTab() {
        const currentData = {
            border_list: this.state.allTablesList.map(b => {
                const { data, other_currency_data, ...rest } = b;
                const borderToSend = { ...rest };
                borderToSend.previous_data = data;
                return borderToSend;
            }),
            obliged_amounts: this.state.obligedAmounts.map(o => {
                const { data, ...rest } = o;
                const obligedToSend = { ...rest };
                obligedToSend.previous_data = data;
                return obligedToSend;
            })
        };

        const customCurrency = this.getCurrentCurrency();
        const includeConverted = this.currencyConversionExists(customCurrency);

        if (includeConverted) {
            this.state.allTablesList.forEach(borderDetail => {
                const { otherCurrencyData, convertedBorder } = this.prepareOtherCurrencyDetail(borderDetail, customCurrency);

                convertedBorder.previous_data = otherCurrencyData.data;

                currentData.border_list.push(convertedBorder);
            });
        }

        return currentData;
    }

    getHorizontalTable = async () => {
        let response;

        try {
            response = await getHorizontalTable({
                date: this.state.activeFilter.date,
                counter_party: this.state.activeFilter.counterParty,
                exchange: this.state.activeFilter.exchange,
                ...this.prepareCurrentDataForResultTab()
            });
        } catch (error) {
            handleApiError(error);
            return;
        }

        if (response.data.success)
            this.setState({ horizontalTableData: response.data.success });
        else
            this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Getting Horizontal Table)');
    }

    getMatrixData = async () => { //caller shows spinner
        let response;

        try {
            response = await getExchangeMatrix({
                date: this.state.activeFilter.date,
                counter_party: this.state.activeFilter.counterParty,
                exchange: this.state.activeFilter.exchange,
                ...this.prepareCurrentDataForResultTab()
            });
        } catch (error) {
            handleApiError(error);
            return;
        }

        if (response.data.success)
            this.setState({ matrixData: response.data.success.matrix_data });
        else
            this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Getting Matrix)');
    }

    latestConvertion = { result: null, owner: null };
    matrixJsonToTable(matrixData) {
        if (!matrixData || isEmpty(matrixData))
            return {};

        if (this.latestConvertion.result && this.latestConvertion.owner === matrixData)
            return this.latestConvertion.result;

        const rows = {};
        const headers = ['Period'];

        const prices = [...Object.keys(matrixData)];
        prices.sort((a, b) => matrixData[a].price - matrixData[b].price)

        for (let priceColKey of prices) {
            let priceCol = matrixData[priceColKey];

            headers.push(Number(priceCol.price).toFixed(this.matrixOfferScale))

            for (let cell of priceCol.data) {
                if (!rows[cell.h])
                    rows[cell.h] = []
                rows[cell.h].push(Number(cell.v).toFixed(this.matrixCapacityScale))
            }
        }

        const values = [];

        for (let k of Object.keys(rows)) {
            values.push([Number(k) + 1, ...rows[k]]);
        }

        let maxValue = Number(values[0][1]);
        let minValue = Number(values[0][1]);

        for (let i = 0; i < values.length; i++) {
            if (values[i][1] > maxValue)
                maxValue = Number(values[i][1]);

            if (Number(values[i][values[i].length - 1]) < minValue)
                minValue = Number(values[i][values[i].length - 1]);

        }

        const result = {
            values, headers, minValue, maxValue,
        };

        this.latestConvertion = { result, owner: matrixData };

        return result;
    }

    selectFirstAvailableCounterParty(counterPartyOptions) {
        //would throw an error if all counter parties are disabled. Disable the exchange if all need to be disabled.
        return counterPartyOptions.filter(c => !c.isDisabled)[0];
    }

    sendTestMail() {
        return this.sendMail(true);
    }

    sendCounterPartyMail() {
        return this.sendMail(false);
    }

    getOtherCurrencyData(borderDetail) {
        let otherCurrencyData = borderDetail.other_currency_data;
        let otherCurrencyDataExist;

        if (!otherCurrencyData
            || otherCurrencyData.filter(o => o.currency === this.getCurrentCurrency()).length === 0) {
            otherCurrencyDataExist = false;
        }
        else {
            otherCurrencyData = otherCurrencyData.filter(o => o.currency === this.getCurrentCurrency());
            otherCurrencyData = otherCurrencyData[0];
            otherCurrencyDataExist = true;
        }

        return { otherCurrencyDataExist, otherCurrencyData };
    }

    // servicing and control offers should not be included to below validation
    validateIbexEnergoviaResults(isTestMail) {
        if (!this.getCounterPartyConfigForActiveFilter().validateExchangeResults)
            return true;

        const exchangeResultDict = {};
        this.state.exchangeResultList.forEach(e => {
            exchangeResultDict[e.hour] = { buy: Number(e.buy), sell: Number(e.sell) };
        });

        const { totalBuyAllocations, totalSellAllocations } = this.getTotalsDictForIntraday(this.state.obligedAmounts, this.state.borderList);

        let differentSellHours = [], differentBuyHours = [];

        Object.keys(exchangeResultDict).forEach(hour => {
            if (exchangeResultDict[hour].sell !== totalSellAllocations[hour])
                differentSellHours.push(Number(hour) + 1);

            if (exchangeResultDict[hour].buy !== totalBuyAllocations[hour])
                differentBuyHours.push(Number(hour) + 1);
        });

        if (differentBuyHours?.length || differentSellHours?.length) {
            this.setState({
                showIrregularMailModal: true,
                irregularMailBody: this.getIrregularMailBodyForIbexEnergoviaExchangeResults({ irregularBuys: differentBuyHours, irregularSells: differentSellHours }),
                irregularMailAction: () => this.sendMail(isTestMail)
            });
            return false;
        }

        return true;
    }

    // gets total obliged + border from arrays with operation type "All" (i.e all except levelled opcom)
    getTotalsDictForIntraday(obligedAmounts, borderList) {
        const totalBuyAllocations = {};
        const totalSellAllocations = {};

        obligedAmounts[0].data.forEach(o => {
            totalSellAllocations[o.hour] = 0;
            totalBuyAllocations[o.hour] = 0;
        });

        // TODO: burada şu anda LT obliged'ın da hesaba katılması gerekiyor, ileride değişebilir
        obligedAmounts.filter(ob => ob.type === this.columns.obligedAmounts.lt.key || ob.type === this.columns.obligedAmounts.st.key)
            .forEach(o => {
                o.data.forEach(h => {
                    totalSellAllocations[h.hour] += Number(h.sell);
                    totalBuyAllocations[h.hour] += Number(h.buy);
                })
            });

        // WARNING: below calculation is not wrong. expected validation is:
        // obliged_BUY + border_SELL = result_BUY
        // obliged_SELL + border_BUY = result_SELL
        borderList.forEach(b => {
            b.data.forEach(h => {
                totalSellAllocations[h.hour] += Number(h.buy.profile);
                totalBuyAllocations[h.hour] += Number(h.sell.profile);
            })
        });

        return { totalBuyAllocations, totalSellAllocations };
    }

    /**
     * Compares sell prices or buy prices inclusively and validates if sell prices are in increasing order or buy prices are in decreasing order
     */
    validateBordersInclusively(operationType, levelList) {
        if (this.getCounterPartyConfigForActiveFilter()?.type !== this.viewTypes.levelled)
            return "";

        const filteredLevels = sortBy(levelList.filter(l => l.operation_type?.toLowerCase() === operationType.key && l.currency === this.columns.eur.title), ['level']);

        if (filteredLevels.length < 2)
            return "";

        let isValid;
        let errorMessage;

        if (operationType.key === this.operationTypes.sell.key) {
            errorMessage = portalMessages.EXCHANGE_OFFERS.SELL_PRICE_ORDER;

            isValid = (p1, p2) => { return (p2 === 0 || (p1 !== 0 && p1 < p2)) ? true : false; };
        }
        else {
            errorMessage = portalMessages.EXCHANGE_OFFERS.BUY_PRICE_ORDER;

            isValid = (p1, p2) => { return (p2 === 0 || (p1 !== 0 && p1 > p2)) ? true : false; };
        }

        const erroronousHours = [];

        for (let i = 0; i < filteredLevels.length - 1; i++) {
            const level1 = filteredLevels[i];
            const level2 = filteredLevels[i + 1];

            for (let k = 0; k < level1.updated_data.length; k++) {
                if (!isValid(level1.updated_data[k][operationType.key].price_offer, level2.updated_data[k][operationType.key].price_offer))
                    erroronousHours.push(Number(level1.updated_data[k].hour) + 1);
            }
        }

        if (erroronousHours && erroronousHours.length > 0)
            return `${errorMessage} Hour: ${sortBy([...new Set(erroronousHours)]).join(', ')}`;
    }

    validateAndWarnBordersExclusively(levelList) {
        const result = this.validateBordersExclusively(levelList);

        if (result) {
            this.showErrorMessage(result);
            return false;
        }

        return true;
    }

    /**
     * Compares buy and sell values with eachother and validates if sell prices are greater than buy prices
     */
    validateBordersExclusively(levelList) {
        if (this.getCounterPartyConfigForActiveFilter()?.type !== this.viewTypes.levelled)
            return;

        const sellOffers = sortBy(levelList.filter(l => l.operation_type?.toLowerCase() === this.operationTypes.sell.key), ['level']);
        const buyOffers = sortBy(levelList.filter(l => l.operation_type?.toLowerCase() === this.operationTypes.buy.key), ['level']);

        const erroronousHours = [];

        const sellData = sellOffers[0]?.updated_data || sellOffers[0]?.data;
        const buyData = buyOffers[0]?.updated_data || buyOffers[0]?.data;

        if (!sellData || !buyData)
            return;

        for (let i = 0; i < sellData.length; i++) {
            const sellPrice = sellData[i].sell.price_offer;
            const buyPrice = buyData[i].buy.price_offer;

            if (sellPrice !== 0 && buyPrice !== 0 && sellPrice <= buyPrice)
                erroronousHours.push(sellData[i].hour + 1);
        }

        if (erroronousHours && erroronousHours.length > 0)
            return `${portalMessages.EXCHANGE_OFFERS.BUY_SELL_PRICE_ORDER} Hour: ${sortBy([...new Set(erroronousHours)]).join(', ')}`;

        return;
    }

    checkIfAllocationsMatch(obligedAmounts, levelList, isTestMail) {
        if (this.getCounterPartyConfigForActiveFilter()?.type !== this.viewTypes.levelled)
            return true;

        const totalSellAllocations = this.getTotalAllocations(obligedAmounts, this.operationTypes.sell);
        const totalBuyAllocations = this.getTotalAllocations(obligedAmounts, this.operationTypes.buy);

        const sellOffers = levelList.filter(l => l.operation_type?.toLowerCase() === this.operationTypes.sell.key);
        const buyOffers = levelList.filter(l => l.operation_type?.toLowerCase() === this.operationTypes.buy.key);

        const totalSellOffers = this.getTotalOffers(sellOffers, this.operationTypes.sell.key);
        const totalBuyOffers = this.getTotalOffers(buyOffers, this.operationTypes.buy.key);

        let irregularSells = [], irregularBuys = [];

        for (let i = 0; i < totalSellAllocations.length; i++) {
            if (totalSellAllocations[i] !== totalSellOffers[i])
                irregularSells.push(i + 1);
        }

        for (let i = 0; i < totalBuyAllocations.length; i++) {
            if (totalBuyAllocations[i] !== totalBuyOffers[i])
                irregularBuys.push(i + 1);
        }

        if (irregularBuys?.length > 0 || irregularSells?.length > 0) {
            this.setState({
                showIrregularMailModal: true,
                irregularMailBody: this.getIrregularMailBodyForOpcomTotals({ irregularBuys, irregularSells }),
                irregularMailAction: () => this.sendMail(isTestMail)
            });
            return false;
        }

        return true;
    }

    // gets total offers for a specific operation type where operation types are seperate (i.e levelled opcom)
    getTotalOffers(offers, operationTypeKey) {
        let totalOffers = [];

        for (let i = 0; i < 24; i++)
            totalOffers.push([0]);

        if (offers.length > 0) {
            offers.forEach(o => o.data.forEach((d, idx) => totalOffers[idx].push(d[operationTypeKey].profile)));
            totalOffers = totalOffers.map(s => s.reduce((a, b) => a + b, 0));
        }

        return totalOffers;
    }

    //calculates total obligeds for a specific operation type where obliged amounts are seperate by operation type (i.e levelled opcom)
    getTotalAllocations(obligedAmounts, operationType) {
        const expectedBorders = this.getExpextedLevelledBorders();

        let values = [];

        for (let i = 0; i < 24; i++)
            values.push([]);

        let dataObj = {};
        obligedAmounts.filter(d => d.operation_type?.toLowerCase() === operationType.key)
            .forEach(d => dataObj[this.getBorderKey(d.border, d.type)] = d);

        expectedBorders.forEach((e, idx) => {
            if (dataObj[e.key]?.data) {
                this.columns.levelledObligedAmounts.borders[e.key].index = idx + 1;

                dataObj[e.key].data.forEach(h => {
                    values[h.hour].push(h[operationType.key]);
                });
            }
            else {
                values.forEach(v => {
                    v.push(0);
                });
            }
        });

        return values.map(v => {
            return v.reduce((a, b) => a + b, 0);
        });
    }

    prepareOtherCurrencyDetail(borderDetail, customCurrency) {
        let { otherCurrencyDataExist, otherCurrencyData } = this.getOtherCurrencyData(borderDetail);

        const convertedBorder = {
            border: borderDetail.border,
            offer_type: borderDetail.offer_type,
            currency: customCurrency,
            offer_id: otherCurrencyDataExist ? otherCurrencyData.offer_id : null,
            level: otherCurrencyData?.level ?? borderDetail?.level,
            operation_type: otherCurrencyData?.operation_type ?? borderDetail?.operation_type,
            servicing_company: borderDetail.servicing_company
        };

        if (!otherCurrencyData)
            otherCurrencyData = {};

        if (!otherCurrencyData?.data) {
            if (borderDetail.operation_type && borderDetail.operation_type.toLowerCase() !== 'all') {
                const operationType = borderDetail.operation_type.toLowerCase();

                otherCurrencyData.data = borderDetail.data.map(d => {
                    return {
                        hour: d.hour,
                        [operationType]: {
                            price_offer: this.getConvertedCurrency(d[operationType].price_offer, this.columns.eur.title),
                            profile: d[operationType].profile
                        }
                    };
                });
            }
            else {
                otherCurrencyData.data = borderDetail.data.map(d => {
                    return {
                        hour: d.hour,
                        buy: {
                            price_offer: this.getConvertedCurrency(d.buy.price_offer, this.columns.eur.title),
                            profile: d.buy.profile
                        },
                        sell: {
                            price_offer: this.getConvertedCurrency(d.sell.price_offer, this.columns.eur.title),
                            profile: d.sell.profile
                        }
                    };
                });
            }
        }

        return { otherCurrencyData, otherCurrencyDataExist, convertedBorder };
    }

    sendMail = async (isTestMail) => {
        const spinnerKey = this.spinner.showSpinner();

        try {
            if (!this.validateAndWarnBordersExclusively(this.state.borderList))
                return;

            let response;

            const allTables = this.state.allTablesList;

            const body = {
                date: this.state.activeFilter.date,
                counter_party: this.state.activeFilter.counterParty,
                exchange: this.state.activeFilter.exchange,
                mail_sent: this.state.mailSent,
                is_test: isTestMail,
                currency_list: this.state.currencyList,
                ignore_obliged_check: this.state.ignoreObligedCheck,
                border_list: allTables.map(b => {
                    const { data, ...rest } = b;
                    const borderToSend = { ...rest };

                    if (borderToSend.offer_id)
                        borderToSend.previous_data = data;
                    else
                        borderToSend.updated_data = data;

                    return borderToSend;
                }),
                obliged_amounts: this.state.obligedAmounts.map(o => {
                    const { data, ...rest } = o;
                    const obligedToSend = { ...rest };

                    if (obligedToSend.obliged_id)
                        obligedToSend.previous_data = data;
                    else
                        obligedToSend.updated_data = data;

                    return obligedToSend;
                }),
            };

            const customCurrency = this.getCurrentCurrency();
            const includeConverted = this.currencyConversionExists(customCurrency);

            if (includeConverted) {
                allTables.forEach(borderDetail => {
                    const { otherCurrencyData, otherCurrencyDataExist, convertedBorder } = this.prepareOtherCurrencyDetail(borderDetail, customCurrency);

                    if (otherCurrencyDataExist && otherCurrencyData.offer_id)
                        convertedBorder.previous_data = otherCurrencyData.data;
                    else
                        convertedBorder.updated_data = otherCurrencyData.data;

                    body.border_list.push(convertedBorder);
                });
            }

            try {
                response = await sendExchangeOffers(body);
            } catch (error) {
                handleApiError(error);
                return;
            }


            if (response.data.success) {
                if (response.data.success.check_list?.length) {

                    this.setState({
                        obligedCheckList: response.data.success.check_list,
                        showSendEmailCheckListModal: true,
                        isTestMail: isTestMail
                    })

                } else {
                    this.onCloseObligedCheckConfirmationModal()
                    await this.refreshBorderTablesAsync();
                    alertSuccess(portalMessages.MAIL_SENT_SUCCESSFULLY);
                }
            }
            else {
                this.setState({ ignoreObligedCheck: false })
                this.showErrorMessage(response.data.error || {}, "sendMail");
            }
        }
        finally {
            this.setState({ irregularMailConfirmed: false });
            this.spinner.hideSpinner(spinnerKey);
        }
    }

    prepareMailModalBody() {
        return (
            <div style={{ padding: "0", paddingRight: "15px" }}>
                <div className='container'>
                    <div className='row'>
                        <div className='col'>
                            {this.state.mailSent ? portalMessages.RESEND_SENT_MAIL : portalMessages.EXCHANGE_OFFERS.SEND_ALL_OFFERS}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            Exchange:
                                    </div>
                        <div className='col-7'>
                            <strong>{this.state.activeFilter.exchange}</strong>
                        </div>
                    </div>
                    <div className='row'>
                        <div style={{ whiteSpace: 'nowrap' }} className='col'>
                            Counter Party:
                                    </div>
                        <div className='col-7'>
                            <strong>{this.state.activeFilter.counterParty}</strong>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    onSetZeroBorder(borderDetail) {
        const newData = this.convertBorderedJsonToTable(borderDetail.data, borderDetail.border);

        newData.values.forEach(row => {
            for (let i = 1; i < row.length; i++) //i==0 is hour column
            {
                row[i] = 0;
            }
        });

        this.onSaveBorderTableAsync([{ newData, borderDetail }]);

        this.onCancelDeletingBorder();
    }

    onSetZeroSeepexLikeObliged(operationType) {
        let obligedAmounts = this.state.obligedAmounts;
        let newData;

        if (!operationType) {
            newData = this.convertObligedAmountsJsonToTable(obligedAmounts);
        }
        else {
            obligedAmounts = this.state.obligedAmounts.filter(b => b.operation_type?.toLowerCase() === operationType.key);
            newData = this.convertLevelledObligedAmountsJsonToTable(obligedAmounts, this.editingOperationType);
        }

        newData.values.forEach(row => {
            for (let i = 1; i < row.length; i++) //i==0 is hour column
            {
                row[i] = 0;
            }
        });


        if (!operationType)
            this.onSaveObligedAmountsAsync(newData, obligedAmounts);
        else
            this.onSaveLevelledObligedAmountsAsync(newData, obligedAmounts, operationType);

        this.onCancelDeletingSeepexObliged();
    }

    async onSetInitialBorderAsync(borderDetail) {
        this.onCancelDeletingBorder();

        if (borderDetail.offer_id) {
            const idsToDelete = [borderDetail.offer_id];

            if (borderDetail.other_currency_data && borderDetail.other_currency_data.length > 0 && borderDetail.other_currency_data[0].offer_id)
                idsToDelete.push(borderDetail.other_currency_data[0].offer_id);

            this.deleteOfferTablesByIdAsync(idsToDelete);
        }
        else {
            await this.refreshBorderTablesAsync();
            alertWarning(portalMessages.EXCHANGE_OFFERS.DEFAULT_LISTED);
        }
    }

    onSetInitialSeepexLikeObliged(operationType) {
        const spinnerKey = this.spinner.showSpinner();

        const successMessage = operationType ? portalMessages.EXCHANGE_OFFERS.TOTAL_UPDATED : portalMessages.EXCHANGE_OFFERS.OBLIGED_UPDATED;

        const filter = {
            obliged_id_list: this.state.obligedAmounts
                .filter(b => !operationType || b.operation_type?.toLowerCase() === operationType.key)
                .map(o => o.obliged_id)
        }

        deleteObligedAmounts(filter).then(async response => {
            if (response.data.success) {
                await this.refreshBorderTablesAsync();
                alertSuccess(successMessage);
            }
            else if (!response.data.error) {
                this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Deleting obliged)');
            }

            if (response.data.error)
                this.showErrorMessage(response.data.error);

        }, error => {
            handleApiError(error);
        }).finally(() => {
            this.spinner.hideSpinner(spinnerKey);
        });

        this.onCancelDeletingSeepexObliged();
    }

    onCancelDeletingSeepexObliged() {
        this.editingOperationType = "";
        this.setState({ showResetSeepexObligedModal: false, showDeleteLevelledObligedDeleteModal: false });
    }

    onCancelDeletingBorder() {
        this.setState({ showResetBorderModal: false });
    }

    onHideExportModal() {
        this.editingOperationType = '';
        if (this.state.showExportOptions)
            this.setState({ showExportOptions: false });
    }

    onCloseSaveConfirmationModal() {
        this.editingOperationType = "";
        this.setState({ showAddLevelModal: false });
    }

    async onConfirmAddLevelAsync() {
        const operationType = this.editingOperationType;

        this.onCloseSaveConfirmationModal();

        const saved = await this.saveAllBordersAsync(operationType);

        if (saved)
            this.addNewLevelAsync(operationType);
    }

    async deleteLevelsIfExist(operationType) {
        const previouslySavedTables = this.getPreviouslySavedLevelTables(operationType);

        if (previouslySavedTables.length > 0 && !await this.onConfirmDeleteAllLevelsAsync(operationType))
            return false;

        return true;
    }

    onSetZeroLevelledObliged(operationType) {
        this.onCancelDeletingSeepexObliged();

        this.deleteLevelsIfExist(operationType).then(result => {
            if (result)
                this.onSetZeroSeepexLikeObliged(operationType);
        });
    }

    onSetInitialLevelledObliged(operationType) {
        this.onCancelDeletingSeepexObliged();

        this.deleteLevelsIfExist(operationType).then(result => {
            if (result)
                this.onSetInitialSeepexLikeObliged(operationType);
        });
    }

    onCloseIrregularMailConfirmationModal() {
        this.setState({
            irregularMailBody: null,
            showIrregularMailModal: false,
            irregularMailConfirmed: false
        });
    }

    onCloseObligedCheckConfirmationModal() {
        this.setState({
            obligedCheckList: [],
            showObligedCheckListModal: false,
            newObligedData: {}, oldObligedData: {},
            ignoreObligedCheck: false
        })
    }

    onCloseLevelObligedCheckConfirmationModal() {
        this.setState({
            obligedCheckList: [],
            showLevelObligedCheckListModal: false,
            newObligedData: {}, oldObligedData: {},
            ignoreObligedCheck: false
        })
    }

    onCloseSendEmailCheckConfirmationModal() {
        this.setState({
            obligedCheckList: [],
            showSendEmailCheckListModal: false,
            isTestMail: null,
            ignoreObligedCheck: false
        })
    }

    async onConfirmIrregularMail() {
        this.setState({
            irregularMailBody: null,
            showIrregularMailModal: false,
            irregularMailConfirmed: true
        }, async () => await this.state.irregularMailAction());
    }

    getIrregularMailBodyForOpcomTotals(irregularProfiles) {
        return (
            <div style={{ padding: "0", paddingRight: "15px" }}>
                <div className='container'>
                    <div className='row'>
                        <div className='col'>
                            {portalMessages.EXCHANGE_OFFERS.PROFILES_DOES_NOT_MATCH}
                            <br />
                            {portalMessages.EXCHANGE_OFFERS.SEND_MAIL_ANYWAY}
                        </div>
                    </div>
                    {this.getIrregularMailHourDetails(irregularProfiles)}
                </div>
            </div>
        );
    }

    getIrregularMailBodyForIbexEnergoviaExchangeResults(irregularProfiles) {
        return (
            <div style={{ padding: "0", paddingRight: "15px" }}>
                <div className='container'>
                    <div className='row'>
                        <div className='col'>
                            {portalMessages.EXCHANGE_OFFERS.PROFILES_DOES_NOT_MATCH_RESULTS}
                            <br />
                            {portalMessages.EXCHANGE_OFFERS.SEND_MAIL_ANYWAY}
                        </div>
                    </div>
                    {this.getIrregularMailHourDetails(irregularProfiles)}
                </div>
            </div>
        );
    }

    getIrregularMailHourDetails(irregularProfiles) {
        return (
            <React.Fragment>
                <div className='row'>
                    <div className='col'>
                        Sell Hours:
                    </div>
                    <div className='col-7'>
                        <strong>{irregularProfiles?.irregularSells?.join(', ') || "-"}</strong>
                    </div>
                </div>
                <div className='row'>
                    <div style={{ whiteSpace: 'nowrap' }} className='col'>
                        Buy Hours:
                            </div>
                    <div className='col-7'>
                        <strong>{irregularProfiles?.irregularBuys?.join(', ') || "-"}</strong>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    onObligedConfirmed() {
        if (this.obligedTableRef?.current) {
            this.setState({ showObligedCheckListModal: false, ignoreObligedCheck: true },
                () => this.obligedTableRef.current.onSaveChanges())
        } else {
            this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Obliged)');
        }
    }

    onLevelObligedConfirmed() {
        if (this.editingObligedTableRefs[this.editingOperationType.key]?.current) {
            this.setState({ showLevelObligedCheckListModal: false, ignoreObligedCheck: true },
                () => this.editingObligedTableRefs[this.editingOperationType.key].current.onSaveChanges())
        } else {
            this.showErrorMessage(portalMessages.UNEXPECTED_ERROR_OCCURED + ' (Obliged)');
        }
    }

    onSendEmailConfirmed() {
        this.setState({ showSendEmailCheckListModal: false, ignoreObligedCheck: true },
            () => this.sendMail(this.state.isTestMail));
    }

    prepareObligedCheckModalBody() {
        return (
            <div style={{ padding: "0", paddingRight: "15px" }}>
                <div className='container'>
                    <div className='row'>
                        <div className='col'>
                            {"Obliged Amount excedeed!!"}
                        </div>
                    </div>
                    {
                        this.state.obligedCheckList.map(item => {
                            return (
                                <div className='row' key={`${item.hour}_${item.type}`}>
                                    <div className='col'>
                                        Hour:{item.hour}
                                    </div>
                                    <div className='col'>
                                        Type:{item.type}
                                    </div>
                                    <div className='col'>
                                        Excedeed Value:{item.exceeded_value}
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <VContentContainer title="Exchange Offers">
                    <VFilterContainer
                        showActiveFilter
                        activeFilter={this.state.activeFilterToDisplay}
                    >
                        <div className="v-filter-group">
                            <div className="v-filter-label v-label">
                                Date
                                </div>
                            <div>
                                <VDatePicker
                                    disabled={!this.showButtonEnabled()}
                                    selectedDate={this.state.selectedDate}
                                    onSelectedDateChange={(selectedDate) => this.setState({ selectedDate })}
                                    maxDate={getDate(2)}
                                />
                            </div>
                        </div>

                        <div className="v-filter-group">
                            <div className="v-filter-label v-label">
                                Exchange
                                </div>
                            <div>
                                <VDropdown
                                    disabled={!this.showButtonEnabled()}
                                    width="large"
                                    options={this.state.exchangeOptions}
                                    value={this.state.selectedExchangeOption}
                                    onSelectedOptionChange={(selectedExchangeOption) => {
                                        const counterPartyOptions = this.getAvailableCounterParties(selectedExchangeOption.value, this.state.exchanges[selectedExchangeOption.value]);
                                        const selectedCounterPartyOption = this.selectFirstAvailableCounterParty(counterPartyOptions);

                                        this.setState({
                                            selectedExchange: selectedExchangeOption.value,
                                            selectedExchangeOption: selectedExchangeOption,
                                            counterPartyOptions,
                                            selectedCounterPartyOption,
                                            selectedCounterParty: selectedCounterPartyOption.value
                                        })
                                    }
                                    }
                                />
                            </div>
                        </div>
                        <div className="v-filter-group">
                            <div className="v-filter-label v-label">
                                Counter Party
                                </div>
                            <div>
                                <VDropdown
                                    disabled={!this.showButtonEnabled()}
                                    width="large"
                                    options={this.state.counterPartyOptions}
                                    value={this.state.selectedCounterPartyOption}
                                    onSelectedOptionChange={(selectedCounterPartyOption) => {
                                        this.setState({
                                            selectedCounterParty: selectedCounterPartyOption.value,
                                            selectedCounterPartyOption: selectedCounterPartyOption
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="v-filter-buttons">
                            <button
                                disabled={!this.showButtonEnabled()}
                                className="btn v-cancel-button v-filter-button"
                                onClick={() => this.onClearButtonClick()}>
                                <i aria-hidden="true" className="fa fa-eraser fa-fw" />Clear
                            </button>
                            <button
                                disabled={!this.showButtonEnabled()}
                                tabIndex={0}
                                className="btn v-button v-filter-button"
                                onClick={() => this.onShowButtonClick()}>
                                <i aria-hidden="true" className="fa fa-search fa-fw" />Show
                             </button>
                        </div>
                    </VFilterContainer>
                    {this.state.selectedCounterPartyConfig &&
                        <VMainContainer>
                            <div className='col-12' style={{ textAlign: 'right', margin: '5px 0 0 0', padding: '0' }}>
                                <button className="btn v-button v-tab-button"
                                    disabled={!this.sendMailEnabled()}
                                    onClick={() => this.sendTestMail()}>
                                    <i aria-hidden="true" className={`fa ${this.mailAlreadySent() ? "fa-envelope-o" : "fa-envelope"} fa-fw`} />
                                    Send Mail To Me
                                </button>
                                <button className="btn v-button v-tab-button"
                                    disabled={!this.sendMailEnabled()}
                                    onClick={() => this.setState({ showSendMailModal: true })}>
                                    <i aria-hidden="true" className={`fa ${this.mailAlreadySent() ? "fa-envelope-o" : "fa-envelope"} fa-fw`} />
                                    Send Mail To Counter Party
                                </button>
                            </div>
                            {this.createOtherCurrencyRateRow()}
                            <VTabs
                                activeKey={this.state.activeTab}
                                onSelect={(key) => this.onTabChanged(key)}>
                                {this.state.selectedCounterPartyConfig.showSelf
                                    && this.renderVitusTab()}
                                {this.state.selectedCounterPartyConfig.showServicing
                                    && this.renderServicingTab()}
                                {this.state.selectedCounterPartyConfig.showTotal
                                    && this.renderTotalTab()}
                                {this.state.selectedCounterPartyConfig.showResult
                                    && this.renderResultTab(this.operationTypes.sell)}
                                {this.state.selectedCounterPartyConfig.showResult
                                    && this.renderResultTab(this.operationTypes.buy)}
                            </VTabs>
                        </VMainContainer>
                    }
                </VContentContainer>
                <NeedRefreshModal
                    show={this.state.showNeedsRefresModal}
                    message={portalMessages.COULD_NOT_GET_EXCHANGES_C_PARTIES}
                />
                <ExportFileModal
                    show={this.state.showExportOptions}
                    onHide={() => this.onHideExportModal()}
                    onExportEdited={() => this.exportAllBorders(false)}
                    onExportOriginal={() => this.exportAllBorders(true)}
                />
                <ConfirmationModal
                    show={this.state.showConfirmCancelModal}
                    message={portalMessages.VTABLE.UNSAVED_CHANGES}
                    cancelText='Discard changes and cancel'
                    onCancel={() => this.confirmCancelAllBorders()}
                    confirmText='Stay on edit mode'
                    onConfirm={() => this.setState({ showConfirmCancelModal: false })}
                />
                <ConfirmationModal
                    show={this.state.showSendMailModal}
                    message={this.prepareMailModalBody()}
                    cancelText='Cancel'
                    onCancel={() => this.setState({ showSendMailModal: false })}
                    onHide={() => this.setState({ showSendMailModal: false })}
                    confirmText='Send'
                    onConfirm={() => {
                        this.sendCounterPartyMail();
                        this.setState({ showSendMailModal: false });
                    }}
                />
                <ResetTableModal
                    show={this.state.showResetSeepexObligedModal}
                    title='Reset Obliged Amounts'
                    message={portalMessages.EXCHANGE_OFFERS.HOW_TO_DELETE}
                    exchange={this.state.activeFilter.exchange}
                    counterParty={this.state.activeFilter.counterParty}
                    onCancel={() => { this.onCancelDeletingSeepexObliged(); }}
                    onSetZero={() => { this.onSetZeroSeepexLikeObliged(); }}
                    onSetInitial={() => { this.onSetInitialSeepexLikeObliged(); }}
                />
                <ResetTableModal
                    show={this.state.showResetBorderModal}
                    title='Reset Border'
                    message={portalMessages.EXCHANGE_OFFERS.HOW_TO_DELETE_BORDER}
                    exchange={this.state.activeFilter.exchange}
                    counterParty={this.state.activeFilter.counterParty}
                    border={this.state.borderToReset?.border}
                    onCancel={() => { this.onCancelDeletingBorder(); }}
                    onSetZero={() => { this.onSetZeroBorder(this.state.borderToReset); }}
                    onSetInitial={() => { this.onSetInitialBorderAsync(this.state.borderToReset); }}
                />
                <ConfirmationModal
                    show={this.state.showAddLevelModal}
                    message={portalMessages.EXCHANGE_OFFERS.INITIALS_LISTED}
                    cancelText='Cancel'
                    onCancel={() => this.onCloseSaveConfirmationModal()}
                    onHide={() => this.onCloseSaveConfirmationModal()}
                    confirmText='Save'
                    onConfirm={() => this.onConfirmAddLevelAsync()}
                />
                <ResetTableModal
                    show={this.state.showDeleteLevelledObligedDeleteModal}
                    title='Reset Total Amounts'
                    message={portalMessages.EXCHANGE_OFFERS.DELETE_LEVELLED_OBLIGED}
                    exchange={this.state.activeFilter.exchange}
                    counterParty={this.state.activeFilter.counterParty}
                    onCancel={() => { this.onCancelDeletingSeepexObliged(); }}
                    onSetZero={() => { this.onSetZeroLevelledObliged(this.editingOperationType); }}
                    onSetInitial={() => { this.onSetInitialLevelledObliged(this.editingOperationType); }}
                />
                <ConfirmationModal
                    show={this.state.showEditLevelledObligedDeleteModal}
                    message={portalMessages.EXCHANGE_OFFERS.EDIT_LEVELLED_OBLIGED}
                    cancelText='Cancel'
                    onCancel={() => {
                        this.editingOperationType = "";
                        this.setState({ showEditLevelledObligedDeleteModal: false });
                    }}
                    onHide={() => {
                        this.editingOperationType = "";
                        this.setState({ showEditLevelledObligedDeleteModal: false })
                    }}
                    confirmText='Continue'
                    onConfirm={async () => {
                        this.setState({ showEditLevelledObligedDeleteModal: false });
                        if (await this.onConfirmDeleteAllLevelsAsync(this.editingOperationType))
                            await this.editingObligedTableRefs[this.editingOperationType.key].current?.onSaveChanges();
                        else
                            this.editingOperationType = "";
                    }}
                />
                <ConfirmationModal
                    show={this.state.showIrregularMailModal}
                    dialogClassName="v-modal-60w"
                    message={this.state.irregularMailBody}
                    cancelText='Cancel'
                    onCancel={() => this.onCloseIrregularMailConfirmationModal()}
                    onHide={() => this.onCloseIrregularMailConfirmationModal()}
                    confirmText='Send'
                    onConfirm={async () => await this.onConfirmIrregularMail()}
                />

                <ConfirmationModal
                    show={this.state.showObligedCheckListModal}
                    dialogClassName="v-modal-60w"
                    message={this.prepareObligedCheckModalBody()}
                    cancelText='Cancel'
                    onCancel={() => this.onCloseObligedCheckConfirmationModal()}
                    onHide={() => this.onCloseObligedCheckConfirmationModal()}
                    confirmText='Confirm'
                    onConfirm={async () => this.onObligedConfirmed()}
                />

                <ConfirmationModal
                    show={this.state.showLevelObligedCheckListModal}
                    dialogClassName="v-modal-60w"
                    message={this.prepareObligedCheckModalBody()}
                    cancelText='Cancel'
                    onCancel={() => this.onCloseLevelObligedCheckConfirmationModal()}
                    onHide={() => this.onCloseLevelObligedCheckConfirmationModal()}
                    confirmText='Confirm'
                    onConfirm={async () => this.onLevelObligedConfirmed()}
                />

                <ConfirmationModal
                    show={this.state.showSendEmailCheckListModal}
                    dialogClassName="v-modal-60w"
                    message={this.prepareObligedCheckModalBody()}
                    cancelText='Cancel'
                    onCancel={() => this.onCloseSendEmailCheckConfirmationModal()}
                    onHide={() => this.onCloseSendEmailCheckConfirmationModal()}
                    confirmText='Confirm'
                    onConfirm={async () => this.onSendEmailConfirmed()}
                />

            </React.Fragment>
        );
    }
};

export default ExchangeOffers;