import React from "react";
import TContentContainer from "../../components/TContentContainer/TContentContainer";
import TContentDetailContainer from "../../components/TContentDetailContainer/TContentDetailContainer";
import LeftSideContainer from "../../components/TTPageContainers/LeftSideContainer";
import ResaleRevenueFilter from './ResaleRevenueFilter';
import { getCompaniesDetail } from "../../api/services";
import { handleApiError } from "../../utils/errorHandler";
import { SpinnerManager } from "../../components/TSpinner/SpinnerManager";
import history from "../../../history";
import DataGrid from 'react-data-grid';
import FileSaver from 'file-saver';
import { getTSO, getResaleRevenue, getResaleRevenueFile } from '../../../apis/vitusApi';

import './ResaleRevenue.css';

const TOTAL = "Total";
const EXCLUDED_TSO = ["TEIAS", "ESO"];
const VITUS = "Vitus"

class ResaleRevenue extends React.Component {
    title = "Resale Revenue";

    spinner = new SpinnerManager(history.location.pathname);

    state = {
        counterPartyNames: [],
        companyNames: [],
        marketNames: [],
        bookNames: [],
        tsoNames: [],
        directionNames: [],
        filter: {},
        resaleRevenueColumns: [],
        resaleRevenueRows: [],
        monthlyColumns: [],
        monthlyResaleRevenueDetails: [],
        hourlyColumns: [],
        hourlyResaleRevenueDetails: []
    };

    componentDidMount() {
        this.getCompaniesDetail();
        this.getTsoDetails();
    }

    startSpinner() {
        return this.spinner.showSpinner();
    }

    stopSpinnerFor(key) {
        this.spinner.hideSpinner(key);
    }

    onShowButtonClick = async (filter) => {
        
        const spinnerKey = this.startSpinner();

        this.setState({filter: filter});
        
        getResaleRevenue(filter)
        .then((res) => {
            if (res.data.success) {

                this.setState({monthlyColumns: res.data.success.monthly_columns,
                               hourlyColumns: res.data.success.hourly_columns,
                               monthlyResaleRevenueDetails: res.data.success.monthly_resale_revenue_details,
                               hourlyResaleRevenueDetails: res.data.success.hourly_resale_revenue_details,
                            });
            }

        },
        (error) => {
            handleApiError(error);
        })
        .finally(() =>  {
            
            this.prepareResaleRevenueResult();
            this.stopSpinnerFor(spinnerKey);
        });
       
    }

    prepareResaleRevenueResult(){

        if (this.state.monthlyColumns.length > 0 || this.state.monthlyResaleRevenueDetails){
            
            let directionColumn = {
                key: "Direction",
                name: "Direction",
                width: 150,
                height: 150,
            }
            
            let monthColumns = []
            this.state.monthlyColumns.forEach((month) => {
    
                if (month === TOTAL){
                    monthColumns.push({
                        key: month, 
                        name: month, 
                        width: 150, 
                        height: 150, 
                        formatter({row}){
                            return (<div className='bold-summary'>{row["Total"].toLocaleString(undefined, { maximumFractionDigits: 2 })}</div>);
                        }
                    })   
                }
                else{
                    monthColumns.push({
                        key: month, 
                        name: month, 
                        width: 150, 
                        height: 150,
                        formatter({row}){
                            return row[month].toLocaleString(undefined, { maximumFractionDigits: 2 });
                        }
                    })
                }
            })
    
            let rowList = this.state.monthlyResaleRevenueDetails.map((resaleRevenue) => {
                let value_list = {
                    "Direction": resaleRevenue["Direction"]
                }
    
                this.state.monthlyColumns.forEach((month) => {
                    value_list[month] = resaleRevenue[month];
                })
    
                return value_list;
            })
    
            this.setState({
                resaleRevenueColumns: [directionColumn, ...monthColumns],
                resaleRevenueRows: [...rowList]
            })

        }

    }
    
    downloadResaleRevenue = async () => {
        const spinnerKey = this.spinner.showSpinner();
        
        let body = {
            monthly_data: {monthlyColumns: this.state.monthlyColumns, monthlyResaleRevenueDetails: this.state.monthlyResaleRevenueDetails},
            hourly_data: {hourlyColumns: this.state.hourlyColumns, hourlyResaleRevenueDetails: this.state.hourlyResaleRevenueDetails}
        }

        getResaleRevenueFile(body).then(async response => {
            
            const filename = `Resale Revenue Report.xlsx`;
            const contentType = response.headers["content-type"];

            const blob = new Blob([response.data], { type: contentType });

            if (contentType === "application/json") {
                //const error = blob.text();
                return;
            }

            FileSaver.saveAs(blob, filename);
            return;
        }, error => {
            handleApiError(error);
        }).finally(() => {
            this.spinner.hideSpinner(spinnerKey);
        });
    }

    async getCompaniesDetail() {
        this.spinner.showSpinner("getCompanyNames");
        getCompaniesDetail()
            .then(
                (response) => {
                    if (response.data.success) {
                        let successData = response.data.success;
                        this.setState({
                            companyNames: successData.company_list.filter(company => company.type === VITUS).map((x) => ({
                                value: x.name,
                                label: x.name
                            }))
                        });
                    }
                },
                (error) => {
                    handleApiError(error);
                }
            )
            .finally(() => {
                this.spinner.hideSpinner("getCompanyNames");
            });
    }

    async getTsoDetails() {
        this.spinner.showSpinner("getTsoDetails");
        getTSO()
            .then(
                (response) => {
                    if (response.data.success) {
                        let counter_party_details = response.data.success.tso_list;
                        
                        const counter_party_set = new Set();
                        const direction_set = new Set();
                        const tso_set = new Set();
                        counter_party_details.forEach(counter_party => {    
                            counter_party_set.add(counter_party["counter_party"]);
                            counter_party.directions.forEach(direction => {
                                if (! EXCLUDED_TSO.includes(direction["tso"])){
                                    direction_set.add(direction["direction"]);
                                    tso_set.add(direction["tso"]);    
                                }
                            })    
                        })
    
                        this.setState({
                            tsoNames: Array.from(tso_set).sort().map((tso) => ({
                                value: tso,
                                label: tso,
                            })),
                            directionNames: Array.from(direction_set).sort().map((direction) => ({
                                value: direction,
                                label: direction
                            })),
                            counterPartyNames: Array.from(counter_party_set).sort().map((counter_party) => ({
                                value: counter_party,
                                label: counter_party
                            }))
                        });
    
                    }
                },
                (error) => {
                    handleApiError(error);
                }
            )
            .finally(() => {
                this.spinner.hideSpinner("getTsoDetails");
            });
    }

    getTableComponent() {
        return (
            <LeftSideContainer
                contentLabel={this.title}
                contentDefaultExpanded={true}
                contentComponent={
                    <div>   
                        <div className="t-table-toolbar">
                            <button type="button" className="btn t-orange-button" onClick={() => { this.downloadResaleRevenue() }}>
                                Export to XLSX
                            </button>
                        </div>
                        {
                            this.state.resaleRevenueRows.length ? (
                                <DataGrid
                                    columns={this.state.resaleRevenueColumns}
                                    rows={this.state.resaleRevenueRows.filter(s => s)}
                                    defaultColumnOptions={{ resizable: true }}
                                    style={{height: `${(this.state.resaleRevenueRows.length + 1) * 50}px`}}
                                />        
                            ) : null
                        }
                    </div>
                }
                filterComponent={
                    this.state.tsoNames.length &&
                        this.state.directionNames.length && 
                            this.state.companyNames.length && 
                                this.state.counterPartyNames.length ? (
                                    <ResaleRevenueFilter
                                        counterPartyNames={this.state.counterPartyNames}
                                        companyNames={this.state.companyNames}
                                        tsoNames={this.state.tsoNames}
                                        directionNames={this.state.directionNames}
                                        onShowButtonClick={this.onShowButtonClick}
                                    ></ResaleRevenueFilter>
                                ) : null
                }
            ></LeftSideContainer>
        );
    }

    render() {
        return (
            <TContentContainer>
                <TContentDetailContainer
                    leftSideComponent={this.getTableComponent()}
                ></TContentDetailContainer>
            </TContentContainer>
        );
    }
}

export default ResaleRevenue;
