import React from 'react';
import DataGrid from 'react-data-grid';
import { groupBy as rowGrouper, sortBy } from 'lodash';
import TContentContainer from '../../components/TContentContainer/TContentContainer';
import TContentDetailContainer from '../../components/TContentDetailContainer/TContentDetailContainer';
import LeftSideContainer from '../../components/TTPageContainers/LeftSideContainer';
import TotalPnlFilter from './TotalPnlFilter';
import {
    getMarketNames, getBookDetails, getCompaniesDetail,
    getCompanySummaryPnl, getPnlStatus, getEtrmCurrencies,
    downloadTotalSummaryPnl, sendTotalSummaryPnl
} from '../../api/services';
import { handleApiError, alertError } from '../../utils/errorHandler';
import { SpinnerManager } from '../../components/TSpinner/SpinnerManager';
import history from '../../../history';
import { messages } from '../../utils/messages';
import { getPnl } from '../../../apis/vitusApi';
import Radio from '@material-ui/core/Radio';
import InfoIcon from '@material-ui/icons/Info';
import TDialogBox from '../../components/TDialogBox';
import FileSaver from 'file-saver';
import moment from 'moment';
import { alertSuccess } from '../../../helpers/errorHelper';
import MuiAlert from '@material-ui/lab/Alert';


const month_names = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const company_summary_columns = [
    {
        key: 'jan',
        name: 'Jan',
        formatter({ row }) {
            return <>{valueFormatter(row.jan)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCompanySummaryGroupFormatter(childRows, "jan")
        }
    },
    {
        key: 'feb',
        name: 'Feb',
        formatter({ row }) {
            return <>{valueFormatter(row.feb)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCompanySummaryGroupFormatter(childRows, "feb")
        }
    },
    {
        key: 'mar',
        name: 'Mar',
        formatter({ row }) {
            return <>{valueFormatter(row.mar)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCompanySummaryGroupFormatter(childRows, "mar")
        }
    },
    {
        key: 'apr',
        name: 'Apr',
        formatter({ row }) {
            return <>{valueFormatter(row.apr)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCompanySummaryGroupFormatter(childRows, "apr")
        }
    },
    {
        key: 'may',
        name: 'May',
        formatter({ row }) {
            return <>{valueFormatter(row.may)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCompanySummaryGroupFormatter(childRows, "may")
        }
    },
    {
        key: 'jun',
        name: 'Jun',
        formatter({ row }) {
            return <>{valueFormatter(row.jun)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCompanySummaryGroupFormatter(childRows, "jun")
        }
    },
    {
        key: 'jul',
        name: 'Jul',
        formatter({ row }) {
            return <>{valueFormatter(row.jul)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCompanySummaryGroupFormatter(childRows, "jul")
        }
    },
    {
        key: 'aug',
        name: 'Aug',
        formatter({ row }) {
            return <>{valueFormatter(row.aug)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCompanySummaryGroupFormatter(childRows, "aug")
        }
    },
    {
        key: 'sep',
        name: 'Sep',
        formatter({ row }) {
            return <>{valueFormatter(row.sep)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCompanySummaryGroupFormatter(childRows, "sep")
        }
    },
    {
        key: 'oct',
        name: 'Oct',
        formatter({ row }) {
            return <>{valueFormatter(row.oct)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCompanySummaryGroupFormatter(childRows, "oct")
        }
    },
    {
        key: 'nov',
        name: 'Nov',
        formatter({ row }) {
            return <>{valueFormatter(row.nov)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCompanySummaryGroupFormatter(childRows, "nov")
        }
    },
    {
        key: 'dec',
        name: 'Dec',
        formatter({ row }) {
            return <>{valueFormatter(row.dec)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCompanySummaryGroupFormatter(childRows, "dec")
        }
    },
    {
        key: 'total',
        name: 'Total',
        formatter({ row }) {
            const sum = row.jan + row.feb + row.mar + row.apr + row.may + row.jun + row.jul + row.aug + row.sep + row.oct + row.nov + row.dec
            return <>{valueFormatter(sum)}</>;
        }
    }
];

const curr_columns = [
    {
        key: 'currency',
        name: 'Currency',
        width: 100,
    },
    {
        key: 'type',
        name: 'Type'
    },
    {
        key: 'jan',
        name: 'Jan',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { jan }) => prev + jan, 0);
            const avg = (sum / childRows.length) || 0;
            return <>{avg.toFixed(5)}</>;
        }
    },
    {
        key: 'feb',
        name: 'Feb',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { feb }) => prev + feb, 0);
            const avg = (sum / childRows.length) || 0;
            return <>{avg.toFixed(5)}</>;
        }
    },
    {
        key: 'mar',
        name: 'Mar',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { mar }) => prev + mar, 0);
            const avg = (sum / childRows.length) || 0;
            return <>{avg.toFixed(5)}</>;
        }
    },
    {
        key: 'apr',
        name: 'Apr',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { apr }) => prev + apr, 0);
            const avg = (sum / childRows.length) || 0;
            return <>{avg.toFixed(5)}</>;
        }
    },
    {
        key: 'may',
        name: 'May',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { may }) => prev + may, 0);
            const avg = (sum / childRows.length) || 0;
            return <>{avg.toFixed(5)}</>;
        }
    },
    {
        key: 'jun',
        name: 'Jun',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { jun }) => prev + jun, 0);
            const avg = (sum / childRows.length) || 0;
            return <>{avg.toFixed(5)}</>;
        }
    },
    {
        key: 'jul',
        name: 'Jul',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { jul }) => prev + jul, 0);
            const avg = (sum / childRows.length) || 0;
            return <>{avg.toFixed(5)}</>;
        }
    },
    {
        key: 'aug',
        name: 'Aug',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { aug }) => prev + aug, 0);
            const avg = (sum / childRows.length) || 0;
            return <>{avg.toFixed(5)}</>;
        }
    },
    {
        key: 'sep',
        name: 'Sep',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { sep }) => prev + sep, 0);
            const avg = (sum / childRows.length) || 0;
            return <>{avg.toFixed(5)}</>;
        }
    },
    {
        key: 'oct',
        name: 'Oct',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { oct }) => prev + oct, 0);
            const avg = (sum / childRows.length) || 0;
            return <>{avg.toFixed(5)}</>;
        }
    },
    {
        key: 'nov',
        name: 'Nov',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { nov }) => prev + nov, 0);
            const avg = (sum / childRows.length) || 0;
            return <>{avg.toFixed(5)}</>;
        }
    },
    {
        key: 'dec',
        name: 'Dec',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { dec }) => prev + dec, 0);
            const avg = (sum / childRows.length) || 0;
            return <>{avg.toFixed(5)}</>;
        }
    },
    {
        key: 'average',
        name: 'Average',
        formatter({ row }) {
            return <>{((row.jan + row.feb + row.mar + row.apr + row.may + row.jun + row.jul + row.aug + row.sep + row.oct + row.nov + row.dec) / 12).toFixed(4)}</>;
        },
        groupFormatter({ childRows }) {
            childRows = childRows.filter(r => r.type === "Last")
            const sum = childRows.reduce((prev, row) => prev + row.jan + row.feb + row.mar + row.apr + row.may + row.jun + row.jul + row.aug + row.sep + row.oct + row.nov + row.dec, 0)
            const avg = (sum / 12) || 0;
            return <>{avg.toFixed(4)}</>;
        }

    }
];

const source_columns = [
    {
        key: 'country',
        name: 'Market',

    },
    {
        key: 'vitus',
        name: 'Vitus',
        formatter: ({ row }) => {
            return (<Radio checked={row.vitus} color='primary' />)
        }
    },
    {
        key: 'ice',
        name: 'ICE',
        formatter: ({ row }) => {
            return (<Radio checked={row.ice} color='primary' />)
        }
    },
    {
        key: 'ice_last_trades',
        name: 'ICE LT',
        formatter: ({ row }) => {
            return (<Radio checked={row.ice_last_trades} color='primary' />)
        }
    },
    {
        key: 'eex',
        name: 'EEX',
        formatter: ({ row }) => {
            return (<Radio checked={row.eex} color='primary' />)
        }
    },
    {
        key: 'viop',
        name: 'VIOP',
        formatter: ({ row }) => {
            return (<Radio checked={row.viop} color='primary' />)
        }
    },
]

function pnlCompanySummaryGroupFormatter(childRows, column) {

    childRows = childRows.filter(r => r.type === 'Last')
    const sum = childRows.reduce((prev, row) => prev + row[column], 0)

    return <>{valueFormatter(sum)}</>

}

function valueFormatter(value) {
    return value.toLocaleString(undefined, { maximumFractionDigits: 2 })
}


function rowKeyGetter(row) {
    return row.id;
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const options = ['country', 'period'];

class TotalPnl extends React.Component {
    title = "Total PNL"

    spinner = new SpinnerManager(history.location.pathname);

    state = {
        currencyRows: [],
        sourceRows: [],
        selectedCurrencyRows: new Set(),
        expandedGroupIds: new Set([]),
        expandedCurrencyGroupIds: new Set([]),
        counterPartyNames: [],
        marketNames: [],
        bookNames: [],
        openInfoDialogBox: false,
        filter: {},
        companySummaryTables: [],
        companySummaryResponse: {},
        cbCompanyUpdated: {},
        cbPnlUpdated: false
    }

    componentDidMount() {
        this.getCompaniesDetail();
        this.getMarketDetails()
        this.getBookDetails()
        this.getPnlStatus()
        setInterval(this.getPnlStatus, 50000);
    }

    onShowButtonClick = async (filter) => {
        this.spinner.showSpinner('getPnl');

        let usdCompanies = [];
        let eurCompanies = [];
        let tryCompanies = [];

        if (filter.has_vitus_commodities) {
            usdCompanies.push("03a272ac-e3f9-4c6e-b8fa-e2747d5ead6b")
            tryCompanies.push("03a272ac-e3f9-4c6e-b8fa-e2747d5ead6b")
        }
        if (filter.has_vitus_capital) {
            usdCompanies.push("03a272ac-e3f9-4c6e-b8fa-e2747d5ead6a")
            eurCompanies.push("03a272ac-e3f9-4c6e-b8fa-e2747d5ead6a")
        }
        if (filter.has_monolith_capital) {
            usdCompanies.push("")
            eurCompanies.push("")
        }

        this.setState({ filter: filter, companySummaryTables: [], companySummaryResponse: [] });
        this.getCompanySummaryPnl(filter, eurCompanies, "EUR")
        this.getCompanySummaryPnl(filter, usdCompanies, "USD")
        this.getCompanySummaryPnl(filter, tryCompanies, "TRY")

        getEtrmCurrencies(filter)
            .then(response => {
                if (response.data.success) {
                    this.setState({
                        rows: [],
                        currencyRows: response.data.success.currency_list,
                        sourceRows: []
                    })
                } else {
                    alertError(messages.UNEXPECTED_ERROR_OCCURED);
                }
            }, error => {
                handleApiError(error);
            }).finally(async () => {
                this.spinner.hideSpinner('getPnl');

                if (filter.fetch_cb_pnl) {
                    if (filter.has_vitus_commodities) {
                        await this.getCBPnl(filter, 'Vitus', ["st", "lt"], "USD")
                        await this.getCBPnl(filter, 'Vitus', ["st", "lt"], "TRY")
                    }
                    if (filter.has_vitus_capital) {
                        await this.getCBPnl(filter, 'Vitus Capital', ["lt"], "USD")
                        await this.getCBPnl(filter, 'Vitus Capital', ["st"], "USD")
                        await this.getCBPnl(filter, 'Vitus Capital', ["lt"], "EUR")
                        await this.getCBPnl(filter, 'Vitus Capital', ["st"], "EUR")
                    }
                    if (filter.has_monolith_capital) {
                        await this.getCBPnl(filter, 'Monolith Capital', ["lt"], "USD")
                        await this.getCBPnl(filter, 'Monolith Capital', ["st"], "USD")
                        await this.getCBPnl(filter, 'Monolith Capital', ["lt"], "EUR")
                        await this.getCBPnl(filter, 'Monolith Capital', ["st"], "EUR")
                    }
                }
                this.checkPnlReady();
            });

    }

    checkPnlReady = async () => {
        let isCbPnlUpdated = true;
        let cbCompanyUpdated = this.state.cbCompanyUpdated;

        isCbPnlUpdated = Object.values(cbCompanyUpdated).every(value => value)
        this.setState({ cbPnlUpdated: isCbPnlUpdated })
    }

    downloadCompanySummary = async () => {
        const spinnerKey = this.spinner.showSpinner();
        let body = {
            summary_data: { result_list: this.state.companySummaryResponse, currency_list: this.state.currencyRows, year: this.state.filter.year }

        }

        downloadTotalSummaryPnl(body).then(async response => {

            const filename = `Vitus Group Total PnL ${this.state.filter.year} (USD) - ${moment(new Date()).format('DD-MM-YYYY')}.xlsx`;
            const contentType = response.headers["content-type"];

            const blob = new Blob([response.data], { type: contentType });

            if (contentType === 'application/json') {
                //const error = blob.text();
                return;
            }

            FileSaver.saveAs(blob, filename);
            return;
        }, error => {
            handleApiError(error);
        }).finally(() => {
            this.spinner.hideSpinner(spinnerKey);
        });
    }

    sendCompanySummary = async () => {
        const spinnerKey = this.spinner.showSpinner();

        const filename = `Vitus Group Total PnL ${this.state.filter.year} (USD) - ${moment(new Date()).format('DD-MM-YYYY')}.xlsx`;

        let body = {
            summary_data: {
                result_list: this.state.companySummaryResponse, currency_list: this.state.currencyRows, file_name: filename,
                year: this.state.filter.year
            }
        }

        sendTotalSummaryPnl(body).then(async response => {
            if (response.data.success) {
                alertSuccess(messages.PNL.SEND_PNL_SUCCESS);
            }
            else {
                alertError(messages.PNL.SEND_PNL_ERROR);
            }
        }, error => {
            handleApiError(error);
        }).finally(() => {
            this.spinner.hideSpinner(spinnerKey);
        });
    }

    getCompanySummaryPnl = async (filter, company_list, currency) => {
        let curr_column = {
            key: 'type',
            name: currency,
            frozen: true
        }


        let request_filter = {};
        for (let key in filter) {
            if (!["currency", "company_list"].includes(filter[key])) {
                request_filter[key] = filter[key];
            }
        }
        request_filter = { ...request_filter, company_list: company_list, currency: currency };

        if (company_list?.length > 0) {
            getCompanySummaryPnl(request_filter)
                .then(response => {
                    if (response.data.success) {

                        let successData = response.data.success.result_list
                        this.setState({ companySummaryResponse: [...this.state.companySummaryResponse, ...successData] })

                        let companySet = new Set(successData.map(i => i.company))
                        let companyList = Array.from(companySet)

                        let company_data_list = companyList.map(current_company => {
                            let currentCompanyData = successData.filter(d => d.company === current_company && d.currency === currency)
                            let company_column = {
                                key: 'book_name',
                                name: current_company,
                                frozen: true,
                                width: 170
                            }
                            let summaryColumns = [company_column, curr_column, ...company_summary_columns]
                            let companyWithoutTotal = currentCompanyData.filter(i => i.book_name !== "Total")
                            let companyTotal = currentCompanyData.filter(i => i.book_name === "Total")

                            return {
                                companyName: current_company,
                                companyCurrency: currency,
                                companyColumn: summaryColumns,
                                companyRows: [...companyWithoutTotal, ...companyTotal],
                            }
                        })
                        this.setState({
                            companySummaryTables: [...this.state.companySummaryTables, ...company_data_list]
                        })

                        this.calculateTotalTable();
                    }
                }, error => {
                    handleApiError(error);
                })

        }
    }

    getCBPnl = async (filter, company, period_list, currency) => {
        let now = new Date();
        now.setUTCHours(19, 0, 0, 0)
        now.setDate(now.getDate() + 1);

        let request_filter = {};

        if (now > new Date(filter.start_date)) {

            request_filter = {
                year: filter.year,
                start_date: filter.start_date,
                end_date: moment(now).utcOffset(0, true).format("YYYY-MM-DD"),
                currency: currency,
                grouping: "Monthly",
                company_list: [company],
                period_list: period_list
            }

        }

        else {
            request_filter = {
                year: filter.year,
                start_date: filter.start_date,
                end_date: filter.end_date,
                currency: currency,
                grouping: "Monthly",
                company_list: [company],
                period_list: period_list
            }
        }

        let book_name = period_list.includes("st") ? "Crossborder" : "FTR";

        if (company === "Vitus") {
            company = "Vitus Commodities"
        }

        return this.getLatestCBPnl(request_filter, company, book_name, currency)

    }

    getLatestCBPnl = async (request_filter, company, book_name, currency) => {
        let cb_pnl_identifier = `${company}_${book_name}_${currency}`;

        let year = request_filter.year.toString().substr(2)
        let month_map = {}
        month_names.forEach(m => month_map[`${m}-${year}`] = m.toLowerCase())

        let cb_pre_row = {
            "id": `${company}_${book_name}_${currency}_pre`,
            "company": company,
            "period": "PNL",
            "type": "Previous",
            "country": `${company} - ${book_name} - ${currency}`,
            "jan": { "value": 0, "source": "Vitus" },
            "feb": { "value": 0, "source": "Vitus" },
            "mar": { "value": 0, "source": "Vitus" },
            "apr": { "value": 0, "source": "Vitus" },
            "may": { "value": 0, "source": "Vitus" },
            "jun": { "value": 0, "source": "Vitus" },
            "jul": { "value": 0, "source": "Vitus" },
            "aug": { "value": 0, "source": "Vitus" },
            "sep": { "value": 0, "source": "Vitus" },
            "oct": { "value": 0, "source": "Vitus" },
            "nov": { "value": 0, "source": "Vitus" },
            "dec": { "value": 0, "source": "Vitus" },
        }

        let cb_last_row = {
            "id": `${company}_${book_name}_${currency}_last`,
            "company": company,
            "period": "PNL",
            "type": "Last",
            "country": `${company} - ${book_name} - ${currency}`,
            "jan": { "value": 0, "source": "Vitus" },
            "feb": { "value": 0, "source": "Vitus" },
            "mar": { "value": 0, "source": "Vitus" },
            "apr": { "value": 0, "source": "Vitus" },
            "may": { "value": 0, "source": "Vitus" },
            "jun": { "value": 0, "source": "Vitus" },
            "jul": { "value": 0, "source": "Vitus" },
            "aug": { "value": 0, "source": "Vitus" },
            "sep": { "value": 0, "source": "Vitus" },
            "oct": { "value": 0, "source": "Vitus" },
            "nov": { "value": 0, "source": "Vitus" },
            "dec": { "value": 0, "source": "Vitus" },
        }

        let cb_change_row = {
            "id": `${company}_${book_name}_${currency}_change`,
            "company": company,
            "period": "PNL",
            "type": "Change",
            "country": `${company} - ${book_name} - ${currency}`,
            "jan": { "value": 0, "source": "Vitus" },
            "feb": { "value": 0, "source": "Vitus" },
            "mar": { "value": 0, "source": "Vitus" },
            "apr": { "value": 0, "source": "Vitus" },
            "may": { "value": 0, "source": "Vitus" },
            "jun": { "value": 0, "source": "Vitus" },
            "jul": { "value": 0, "source": "Vitus" },
            "aug": { "value": 0, "source": "Vitus" },
            "sep": { "value": 0, "source": "Vitus" },
            "oct": { "value": 0, "source": "Vitus" },
            "nov": { "value": 0, "source": "Vitus" },
            "dec": { "value": 0, "source": "Vitus" },
        }

        let cb_summary_pre_row = {
            "id": `${company}_${book_name}_${currency}_summary_pre`,
            "company": company,
            "book_name": book_name,
            "trading_venue": "otc_physical",
            "currency": request_filter.currency,
            "type": "Previous",
            "jan": 0,
            "feb": 0,
            "mar": 0,
            "apr": 0,
            "may": 0,
            "jun": 0,
            "jul": 0,
            "aug": 0,
            "sep": 0,
            "oct": 0,
            "nov": 0,
            "dec": 0,
        }

        let cb_summary_last_row = {
            "id": `${company}_${book_name}_${currency}_summary_last`,
            "company": company,
            "book_name": book_name,
            "trading_venue": "otc_physical",
            "currency": request_filter.currency,
            "type": "Last",
            "jan": 0,
            "feb": 0,
            "mar": 0,
            "apr": 0,
            "may": 0,
            "jun": 0,
            "jul": 0,
            "aug": 0,
            "sep": 0,
            "oct": 0,
            "nov": 0,
            "dec": 0,
        }

        let cb_summary_change_row = {
            "id": `${company}_${book_name}_${currency}_summary_change`,
            "company": company,
            "book_name": book_name,
            "trading_venue": "otc_physical",
            "currency": request_filter.currency,
            "type": "Change",
            "jan": 0,
            "feb": 0,
            "mar": 0,
            "apr": 0,
            "may": 0,
            "jun": 0,
            "jul": 0,
            "aug": 0,
            "sep": 0,
            "oct": 0,
            "nov": 0,
            "dec": 0,
        }

        return getPnl(request_filter)
            .then(response => {
                if (response.data.success) {
                    let successData = response.data.success
                    let sumOfBorders = successData.pnl_summary?.sum_of_borders
                    let additionalCustomPnl = successData.pnl_summary?.additional_custom_pnl[company]

                    if (additionalCustomPnl) {
                        sumOfBorders = [...sumOfBorders, ...additionalCustomPnl]
                    }


                    if (sumOfBorders) {
                        sumOfBorders.map(cb => {
                            if (cb_pre_row[month_map[cb.dt]]) {
                                cb_pre_row[month_map[cb.dt]].value = cb.previous
                                cb_last_row[month_map[cb.dt]].value = cb.today
                                cb_change_row[month_map[cb.dt]].value = cb.today - cb.previous

                                cb_summary_pre_row[month_map[cb.dt]] = cb.previous
                                cb_summary_last_row[month_map[cb.dt]] = cb.today
                                cb_summary_change_row[month_map[cb.dt]] = cb.today - cb.previous
                            }

                        })

                        let company_summary = this.state.companySummaryTables.find(s => s.companyName === company && s.companyCurrency === currency)
                        let other_company_summary = this.state.companySummaryTables.filter(s => (s.companyName !== company) || (s.companyCurrency !== currency))

                        let companyWithoutTotal = []
                        let companyTotal = []
                        if (company_summary) {
                            companyWithoutTotal = company_summary.companyRows.filter(i => i.book_name !== "Total")
                            companyTotal = company_summary.companyRows.filter(i => i.book_name === "Total")
                        }


                        let companyPreviousTotal = companyTotal.find(i => i.type === "Previous")
                        if (!companyPreviousTotal) {
                            companyPreviousTotal = {
                                "id": `${company}_${currency}_summary_pre_total`,
                                "company": company,
                                "book_name": "Total",
                                "trading_venue": "otc_physical",
                                "currency": request_filter.currency,
                                "type": "Previous",
                                "jan": 0,
                                "feb": 0,
                                "mar": 0,
                                "apr": 0,
                                "may": 0,
                                "jun": 0,
                                "jul": 0,
                                "aug": 0,
                                "sep": 0,
                                "oct": 0,
                                "nov": 0,
                                "dec": 0,
                            }

                        }
                        let companyLastTotal = companyTotal.find(i => i.type === "Last")

                        if (!companyLastTotal) {
                            companyLastTotal = {
                                "id": `${company}_${currency}_summary_last_total`,
                                "company": company,
                                "book_name": "Total",
                                "trading_venue": "otc_physical",
                                "currency": request_filter.currency,
                                "type": "Last",
                                "jan": 0,
                                "feb": 0,
                                "mar": 0,
                                "apr": 0,
                                "may": 0,
                                "jun": 0,
                                "jul": 0,
                                "aug": 0,
                                "sep": 0,
                                "oct": 0,
                                "nov": 0,
                                "dec": 0,

                            }
                        }
                        let companyChangeTotal = companyTotal.find(i => i.type === "Change")

                        if (!companyChangeTotal) {
                            companyChangeTotal = {
                                "id": `${company}_${currency}_summary_change_total`,
                                "company": company,
                                "book_name": "Total",
                                "trading_venue": "otc_physical",
                                "currency": request_filter.currency,
                                "type": "Change",
                                "jan": 0,
                                "feb": 0,
                                "mar": 0,
                                "apr": 0,
                                "may": 0,
                                "jun": 0,
                                "jul": 0,
                                "aug": 0,
                                "sep": 0,
                                "oct": 0,
                                "nov": 0,
                                "dec": 0,

                            }
                        }

                        company_summary_columns.map(m => {
                            let key = m.key
                            companyPreviousTotal[key] = companyPreviousTotal[key] ? companyPreviousTotal[key] + cb_summary_pre_row[key] : cb_summary_pre_row[key]
                            companyLastTotal[key] = companyLastTotal[key] ? companyLastTotal[key] + cb_summary_last_row[key] : cb_summary_last_row[key]
                            companyChangeTotal[key] = companyChangeTotal[key] ? companyChangeTotal[key] + cb_summary_change_row[key] : cb_summary_change_row[key]
                        })

                        let company_column = {
                            key: 'book_name',
                            name: company,
                            frozen: true,
                            width: 170
                        }

                        let curr_column = {
                            key: 'type',
                            name: currency,
                            frozen: true
                        }

                        let company_data_list = [
                            {
                                companyName: company_summary ? company_summary.companyName : company,
                                companyCurrency: company_summary ? company_summary.companyCurrency : currency,
                                companyColumn: company_summary ? company_summary.companyColumn : [company_column, curr_column, ...company_summary_columns],
                                companyRows: [...companyWithoutTotal, cb_summary_pre_row, cb_summary_last_row, cb_summary_change_row, companyPreviousTotal, companyLastTotal, companyChangeTotal],
                            }

                        ]

                        let other_company_summary_rows = []
                        let other_company_summary_response = [];
                        if (other_company_summary?.length) {
                            company_data_list = [...company_data_list, ...other_company_summary]
                            other_company_summary_rows = [...other_company_summary.map(s => s.companyRows.map(c => c))]
                            other_company_summary_rows.map(h => {
                                h.map(d => {
                                    other_company_summary_response.push(d);
                                })
                            })
                        }

                        this.setState({
                            companySummaryTables: company_data_list,
                            companySummaryResponse: [...other_company_summary_response, ...companyWithoutTotal, cb_summary_pre_row, cb_summary_last_row, cb_summary_change_row, companyPreviousTotal, companyLastTotal, companyChangeTotal]
                        })
                        this.calculateTotalTable();
                    }
                }
            }, error => {
                handleApiError(error);
                this.setState({ cbCompanyUpdated: { ...this.state.cbCompanyUpdated, cb_pnl_identifier: false } })

            }).finally(() => {
                alertSuccess(messages.PNL.CROSSBORDER_PNL_WITH_CURRENCY.replace("[COMPANY]", company).replace("[BOOK]", book_name).replace("[CURRENCY]", currency))

                this.setState({ cbCompanyUpdated: { ...this.state.cbCompanyUpdated, cb_pnl_identifier: true } })
            });
    }

    calculateTotalTable() {

        let companyUsdRows = [];
        let companyUsdSummary = this.state.companySummaryTables.filter(s => s.companyCurrency === "USD" && s.companyName !== 'Total');

        companyUsdSummary.map(c => {
            let book_set = new Set(c.companyRows.map(i => i.book_name))
            let bookList = Array.from(book_set);
            bookList.map(b => {
                let book_data = c.companyRows.filter(d => d.book_name === b);
                let type_set = new Set(book_data.map(t => t.type))
                let typeList = Array.from(type_set);

                typeList.map(t => {
                    let book_type_data = book_data.find(e => e.type === t);
                    let book_total = companyUsdRows.find(u => u.book_name === b && u.type === t);
                    let other_book_total = companyUsdRows.filter(u => u.book_name !== b || u.type !== t);

                    if (book_total) {
                        company_summary_columns.map(m => {
                            let key = m.key
                            book_total[key] = book_total[key] ? book_total[key] + book_type_data[key] : book_type_data[key]
                        })

                        if (other_book_total?.length) {
                            companyUsdRows = [...other_book_total, book_total]
                        }

                    }
                    else {
                        let book_first_total = {
                            "id": `Mixed_${b}_${t}`,
                            "company": "Total",
                            "book_name": `${b}`,
                            "trading_venue": "",
                            "currency": "USD",
                            "type": `${t}`,
                        }

                        company_summary_columns.map(m => {
                            let key = m.key
                            book_first_total[key] = book_type_data[key]
                        })
                        companyUsdRows.push(book_first_total);
                    }

                })
            })
        })

        let company_column = {
            key: 'book_name',
            name: "Vitus Commodities & Vitus Capital & Monolith Capital",
            frozen: true,
            width: 170
        }

        let curr_column = {
            key: 'type',
            name: "USD",
            frozen: true
        }

        let otherCompanyTables = this.state.companySummaryTables.filter(c => c.companyName !== "Total")
        let otherCompanySummaries = this.state.companySummaryResponse.filter(c => c.company !== "Total")

        let company_data_list = [
            {
                companyName: "Total",
                companyCurrency: "USD",
                companyColumn: [company_column, curr_column, ...company_summary_columns],
                companyRows: [...companyUsdRows],
            }
        ]

        this.setState({
            companySummaryTables: [...company_data_list, ...otherCompanyTables],
            companySummaryResponse: [...otherCompanySummaries, ...companyUsdRows]
        });
    }

    getTotalPnlStatus() {

        if (this.state.pnlStatus?.is_pnl_updated) {
            if (this.state.cbPnlUpdated) {
                return <Alert severity="success">PNL up-to-date</Alert>
            }

            return <Alert severity="success">PNL up-to-date excluding CB & FTR</Alert>

        }
        
        if (this.state.cbPnlUpdated) {
            return <Alert severity="error" animation="wave">
                <div>
                    <span>Calculating including CB & FTR</span>
                </div>
            </Alert>
        }
        return <Alert severity="error" animation="wave">
            <div>
                <span>Calculating excluding CB & FTR</span>
            </div>
        </Alert>

        

    }

    getPnlStatus = async () => {
        getPnlStatus()
            .then(response => {
                if (response.data.success) {
                    this.setState({
                        pnlStatus: response.data.success
                    })
                }
            }, error => {
                console.log(error);
            }).finally(() => {

            });
    }

    async getCompaniesDetail() {
        this.spinner.showSpinner('getCompanyNames');
        getCompaniesDetail()
            .then(response => {
                if (response.data.success) {
                    let successData = response.data.success
                    this.setState({
                        counterPartyNames: successData.company_list.map(x => (
                            {
                                id: x.id,
                                value: x.name,
                                label: x.name,
                                type: x.type,
                            }
                        )),
                    });

                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getCompanyNames');
            });
    }

    async getMarketDetails() {
        this.spinner.showSpinner('getMarketNames');
        getMarketNames()
            .then(response => {
                if (response.data.success) {
                    let successData = response.data.success
                    this.setState({
                        marketNames: successData.market_name_list.map(x => ({
                            id: x.id,
                            value: x.name,
                            label: x.name,
                            currency: x.currency
                        })),
                    });

                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getMarketNames');
            });
    }

    async getBookDetails() {
        this.spinner.showSpinner('getBookNames');
        getBookDetails()
            .then(response => {
                if (response.data.success) {
                    let successData = response.data.success
                    this.setState({
                        bookNames: successData.book_list.map(x => ({
                            id: x.id,
                            value: x.name,
                            label: x.name
                        })),
                    });

                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getBookNames');
            });
    }

    onInfoButtonClick() {
        this.setState({ openInfoDialogBox: true })
    }

    closeInfoDialogBox() {
        this.setState({ openInfoDialogBox: false })
    }

    getTableComponent() {
        return (
            <LeftSideContainer
                contentLabel={this.title}
                contentDefaultExpanded={true}
                contentComponent={
                    <div>
                        {this.state.sourceRows?.length ? (
                            <InfoIcon style={{ margin: "5px" }} onClick={() => this.onInfoButtonClick()}></InfoIcon>
                        ) : null
                        }
                        {
                            this.state.currencyRows?.length ? (
                                <DataGrid
                                    columns={curr_columns}
                                    rows={this.state.currencyRows}
                                    defaultColumnOptions={{ resizable: true }}
                                    groupBy={["currency"]}
                                    rowGrouper={rowGrouper}
                                    rowKeyGetter={rowKeyGetter}
                                    selectedRows={this.state.selectedCurrencyRows}
                                    onSelectedRowsChange={rows => this.setState({ selectedCurrencyRows: rows })}
                                    expandedGroupIds={this.state.expandedCurrencyGroupIds}
                                    onExpandedGroupIdsChange={ids => this.setState({ expandedCurrencyGroupIds: ids })}
                                    style={{ height: "300px" }}
                                />
                            ) : null
                        }
                        <div className="t-table-toolbar">
                            <button type="button" className="btn t-orange-button" onClick={() => { this.downloadCompanySummary() }}>
                                Export to XLSX
                            </button>
                            <button type="button" className="btn t-orange-button" onClick={() => { this.sendCompanySummary() }}>
                                Send to Slack
                            </button>
                        </div>

                        {
                            this.state.companySummaryTables.length ? (
                                this.state.companySummaryTables.map(table => {
                                    let expandedGroupIds = new Set([])
                                    if (this.state[`expandedCompany${table.companyName}_${table.companyCurrency}`]) {
                                        expandedGroupIds = this.state[`expandedCompany${table.companyName}_${table.companyCurrency}`]
                                    } else {
                                        expandedGroupIds = new Set(table.companyRows.map(i => i.book_name))
                                    }

                                    return (
                                        table.companyRows.length ? (
                                            <DataGrid
                                                key={`tableCompanySummary_${table.companyName}_${table.companyCurrency}`}
                                                style={{ height: `${(expandedGroupIds.size + 1) * 150}px` }}
                                                columns={table.companyColumn}
                                                rows={table.companyRows}
                                                defaultColumnOptions={{ resizable: true }}
                                                groupBy={["book_name"]}
                                                rowGrouper={rowGrouper}
                                                expandedGroupIds={expandedGroupIds}
                                                onExpandedGroupIdsChange={ids => {
                                                    this.setState({
                                                        [`expandedCompany${table.companyName}_${table.companyCurrency}`]: ids
                                                    })
                                                }}
                                            />
                                        ) : null

                                    )
                                })
                            ) : null

                        }

                        <TDialogBox
                            openAddDialogBox={this.state.openInfoDialogBox}
                            handleClose={() => this.closeInfoDialogBox()}
                            title={"Price Sources"}
                        >
                            <DataGrid
                                columns={source_columns}
                                rows={this.state.sourceRows}
                                defaultColumnOptions={{ resizable: true }}
                                style={{ height: "500px" }}
                            />
                        </TDialogBox>
                    </div>
                }
                filterComponent={
                    this.state.counterPartyNames.length &&
                        this.state.marketNames.length &&
                        this.state.bookNames.length ? (
                        <TotalPnlFilter
                            counterPartyNames={this.state.counterPartyNames}
                            marketNames={this.state.marketNames}
                            bookNames={this.state.bookNames}
                            onShowButtonClick={this.onShowButtonClick}
                        ></TotalPnlFilter>
                    ) : null

                }
            >
            </LeftSideContainer>
        )
    }

    render() {
        return (
            <TContentContainer>

                <div >
                    {
                        this.getTotalPnlStatus()
                    }

                </div>
                <TContentDetailContainer
                    leftSideComponent={this.getTableComponent()}
                >
                </TContentDetailContainer>
            </TContentContainer>
        );
    }
}

export default TotalPnl;