import React from "react";
import TContentContainer from "../../components/TContentContainer/TContentContainer";
import TContentDetailContainer from "../../components/TContentDetailContainer/TContentDetailContainer";
import LeftSideContainer from "../../components/TTPageContainers/LeftSideContainer";
import DailyLiquidationFilter from './DailyLiquidationFilter';
import {sortBy} from 'lodash';
import {
    getMarketNames,
    getBookDetails,
    getCompaniesDetail,
    getEtrmLiquidation,
    downloadEtrmLiquidation,
    sendEtrmLiquidation
} from "../../api/services";
import { handleApiError, alertError } from "../../utils/errorHandler";
import { SpinnerManager } from "../../components/TSpinner/SpinnerManager";
import history from "../../../history";
import DataGrid from 'react-data-grid';
import FileSaver from 'file-saver';
import { getPnl, getLTAllocationMaxCapacities } from '../../../apis/vitusApi';
import { messages } from '../../utils/messages';
import { alertSuccess } from '../../../helpers/errorHelper';
import moment from 'moment';

import './DailyLiquidation.css';

const capitalStTotal = "ST Total"
const capitalLtTotal = "LT Total"
const month_names = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
const totalRowList = ['EUR Subtotal', 'USD Subtotal', 'TRY Subtotal', 'LT Total', 'ST Total'];
const overallTotalList = ["USD Total", "Total"];
const companyTotalList = ["Monolith Capital Crossborder", "Monolith Capital FTR", "Vitus Capital EU Futures",  "Vitus Capital Crossborder", 
                          "Vitus Capital FTR", "Vitus Commodities Crossborder", "Vitus Commodities OTC Futures", "Vitus Capital OTC Futures"]
const capitalDefaultLT = {}
                           
const DirectionFormatter = ({ row }) => {
    if (totalRowList.includes(row["direction"])) {
        return (<div className={`${"table-summary"}`}>{row["direction"]}</div>);
    }
    else if (overallTotalList.includes(row["direction"])) {
        return (<div className={`${"overall-table-summary"}`}>{row["direction"]}</div>);
    }
    return row["direction"];
};

const columns = [
    {
        key: 'Date',
        name: 'Liquidation Date',
        width: 125,
    },
    {
        key: 'Position',
        name: 'Position',
        width: 100,
        formatter ({row}) {
            if (row.Position){
                return row.Position.toLocaleString(undefined, { maximumFractionDigits: 2 })
            }
            else{
                return 0
            }
        }
    },
    {
        key: 'Mark',
        name: 'Mark',
        width: 100,
        formatter ({row}) {
            if (row.Mark){
                return row.Mark.toLocaleString(undefined, { maximumFractionDigits: 2 })
            }
            else{
                return 0
            }
        }
    },
    {
        key: 'Price',
        name: 'Price',
        width: 100,
        formatter ({row}) {
            if (row){
                return row.Price.toLocaleString(undefined, { maximumFractionDigits: 2 })
            }
            else{
                return 0
            }
        }
    },
    {
        key: 'Liquidation',
        name: 'Liquidation',
        width: 100,
        formatter ({row}) {
            if (row){
                return row.Liquidation.toLocaleString(undefined, { maximumFractionDigits: 2 })
            }
            else{
                return 0
            }
        }
}]


class DailyLiquidation extends React.Component {
    title = "Daily Liquidation";

    spinner = new SpinnerManager(history.location.pathname);

    state = {
        currencies: [],
        counterPartyNames: [],
        marketNames: [],
        bookNames: [],
        companySummaryTables: [],
        companySummaryResponse: [],
        companyEtrmSummaryTables: [],
        companyEtrmSummaryResponse: [],
        totalSummaryTable: [],
        totalSummaryTableColumns: [],
        filter: {},
        etrmLiquidation: [],
        currencyColumns: [],
        currencyRows: [],
        companyMonthlyValues: [],
        companyMonthlySummaryTables: [],
        maxDates: [],
        directionMaxDates: [],
        totalExcelColumns: [],
        currencyMonthColumns: [],
        vitusData: []
    };

    componentDidMount() {
        this.getCompaniesDetail();
        this.getMarketDetails();
        this.getBookDetails();
    }

    startSpinner() {
        return this.spinner.showSpinner();
    }

    stopSpinnerFor(key) {
        this.spinner.hideSpinner(key);
    }

    onShowButtonClick = async (filter) => {
        
        const spinnerKey = this.startSpinner();

        this.setState({filter: filter});
        
        getEtrmLiquidation(filter)
        .then((res) => {
            if (res.data.success) {

                this.setState({etrmLiquidation: res.data.success.result_list,
                               otcResult: res.data.success.otc_result_list,
                               currencies: res.data.success.currency_list,
                               companySummaryTables: [],
                               companyEtrmSummaryTables: [],
                               companySummaryResponse: [],
                               companyEtrmSummaryResponse: [],
                               totalSummaryTable:[],
                               totalSummaryTableColumns:[],
                               companyMonthlySummaryTables:[],
                               maxDates: []});
            }

        },
        (error) => {
            handleApiError(error);
        })
        .finally(async () =>  {
            
            this.prepareCurrencyResult(this.state.currencies);
            this.prepareEtrmLiquidationDetail(filter);

            if (filter.fetch_cb_pnl){
                if (filter.has_vitus_commodities) {
                    await this.getDailyLiquidation(filter, 'TRY', 'Vitus', ['st','lt']);
                }
                if (filter.has_vitus_capital) {
                    await this.getDailyLiquidation(filter, 'EUR', 'Vitus Capital', ['lt']);
                    await this.getDailyLiquidation(filter, 'EUR', 'Vitus Capital', ['st']);
                }
                if (filter.has_monolith_capital) {
                    await this.getDailyLiquidation(filter, 'EUR', 'Monolith Capital', ['lt']);
                    await this.getDailyLiquidation(filter, 'EUR', 'Monolith Capital', ['st']);
                    
                }

                this.stopSpinnerFor(spinnerKey);

            }
            

        });
       
    }

    calculateTableSubTotal(currency){
        
        let subTotalName = `${currency} Subtotal`
        let rows = this.state.totalSummaryTable.filter(s => s.direction !== subTotalName && s.currency === currency && s.direction !== "USD Total")
        
        if (rows?.length){
            let value_list = {
                direction: subTotalName,
                currency: currency,
                Empty: "",
                months_liquidation: []
            }

            rows.map(h => {
                let dateList = (Object.keys(h)).filter(s => s!== "direction" && s !== "currency" && s!== "Empty" && s!== "months_liquidation" && s!== "total");      
                dateList.forEach(t => {
                    value_list[t] = value_list[t] ? value_list[t] + h[t] : h[t];
                }) 
            })
        
            let rowsWithSubtotal = [...(sortBy(rows, ['direction'])), value_list];
            return rowsWithSubtotal;
        }

        return [];     
    }

    calculateTableTotal(){   
        let rows = this.state.totalSummaryTable.filter(s => s.direction == "EUR Subtotal" || s.direction === "TRY Subtotal" || s.direction === "USD Subtotal");
        if (rows?.length){
            
            let value_list = {
                direction: 'USD Total',
                currency: 'USD',
                Empty: '',
                months_liquidation: []
            }

            rows.map(h => {
                let dateList = (Object.keys(h)).filter(s => s!== "direction" && s !== "currency" && s!== "total" && s !== "company" && s!== "Empty" && s!== "months_liquidation");

                dateList.forEach(t => {
                    if (h.currency === "USD"){
                        value_list[t] = value_list[t] ? value_list[t] + h[t] : h[t];
                    }
                    else {
                        if (h.currency === "EUR"){
                            let currency = this.state.currencies.find(s => s.Currency === "EUR/USD")?.data;
                            let currency_value = currency.find(s => s.Date === t);
                            if (currency_value){
                                value_list[t] = value_list[t] ? value_list[t] + (h[t] * currency_value['Value']) : (h[t] * currency_value['Value'])
                            }
                            else{
                                 alertError('USD Conversion for EUR values was unsuccessful!')
                            }
                        }
                        
                        else if (h.currency === 'TRY'){
                            let currency = this.state.currencies.find(s => s.Currency === "USD/TRY")?.data;
                            let currency_value = currency.find(s => s.Date === t);
                            if (currency_value){
                                value_list[t] = value_list[t] ? value_list[t] + (h[t] / currency_value['Value']) : (h[t] / currency_value['Value'])
                            }
                            else{
                                alertError('USD Conversion for TRY values was unsuccessful!')
                            }
                        }
                    }
                }) 

            })
            this.setState({totalSummaryTable: [...this.state.totalSummaryTable, value_list]})
        }
    }

    prepareCurrencyResult(currencies){
        let currency_list = []
        
        let currency_column = {
            key: 'currency',
            name: 'Currency',
            width: 150,
            height: 150,
            frozen: true
        }
        
        let currency_date_column = []
        currencies.map(h => {
            
            sortBy(h.data, ['Date']).reverse().forEach(d => {
                if (! currency_list.includes(d.Date)){
                    currency_list.push(d.Date);
                    currency_date_column.push({key: d.Date, name: d.Date, width: 150, height: 150})
                }
            })
        }).filter(s => s)

        let row_list = currencies.map(h => {
            let value_list = {
                currency: h.Currency
            }

            h.data.forEach(d => {
                value_list[d.Date] = d.Value
            })
            return value_list;
        })

        this.setState({currencyColumns: [currency_column, ...currency_date_column],
                       currencyRows: [...row_list]})
    }

    calculateTotalLiquidationTable(){
        
        let companies_totals = [];
        companies_totals = this.state.totalSummaryTable.map(c => {
            let monthly_liquidations = c['months_liquidation'];
            let company_total = {};

            Object.keys(c).forEach(k => {
                if (k !== 'months_liquidation'){
                    company_total[k] = c[k]
                    company_total['Empty'] = ''
                }                 
            })

            monthly_liquidations.map(m => {
                let current_month = m['month'];
                let current_max_dates = this.state.maxDates.find(d => d.month === current_month);
                if (current_max_dates){
                    let min_date = current_max_dates['min_date'].getDate();
                    let max_date = current_max_dates['max_date'].getDate();
                    let temp_max_date = new Date(current_max_dates['max_date']);
                    
                    let current_max_date = new Date(temp_max_date)
                    current_max_date = moment(current_max_date).utcOffset(0, true).format("YYYY-MM-DD")
                    
                    let d1_before_max_date = new Date(temp_max_date.setDate(temp_max_date.getDate() - 1))
                    d1_before_max_date = moment(d1_before_max_date).utcOffset(0, true).format("YYYY-MM-DD")
                    
                    let d1_after_max_date = new Date(temp_max_date.setDate(temp_max_date.getDate() + 2))
                    d1_after_max_date = moment(d1_after_max_date).utcOffset(0, true).format("YYYY-MM-DD")
                    
                    let month_row_name = `${min_date} - ${max_date} ${month_names[current_month-1]}`;
                    company_total[month_row_name] = m['total']; 
                    this.state.currencies.map(h => {
                        let current_day_ex_rate = h.data.find(d => d.Date === current_max_date);
                        if (current_day_ex_rate){
                            let month_rate = {'Date': month_row_name, 'Value': current_day_ex_rate['Value']};
                            let current_month = h.data.find(d => d.Date === month_row_name);
                            if (! current_month){
                                h.data.push(month_rate);
                            }
                        }
                        
                    })

                }
            })
            
            company_total['months_liquidation'] = monthly_liquidations;
            return company_total;
        })        

        this.setState({totalSummaryTable: companies_totals});
        this.calculateOverallTotal();
    }

    calculateCompanyTotalLiquidation(){
        let companies_totals = [];
        let cb_summary_rows = [];
        companies_totals = this.state.companySummaryTables.map(c => {

            let company_rows = c.rows.map(d => {
                let monthly_liquidations = d['month_liquidations'];
                let direction_total = {};
                Object.keys(d).forEach(k => {
                    if (k !== 'month_liquidations'){
                        direction_total[k] = d[k]
                        direction_total['Empty'] = ''
                    }                 
                })

                monthly_liquidations.map(m => {
                    let current_month = m['month'];
                    let current_max_dates = this.state.maxDates.find(t => t.month === current_month);
                    if (current_max_dates){
                        let min_date = current_max_dates['min_date'].getDate();
                        let max_date = current_max_dates['max_date'].getDate();

                        let month_row_name = `${min_date} - ${max_date} ${month_names[current_month-1]}`;
                        direction_total[month_row_name] = m['total']; 
                    }
                })


                direction_total['month_liquidations'] = monthly_liquidations;
                return direction_total;
            })
            
            let company_data = 
                {
                    company_name: c.company_name,
                    columns: c.columns,
                    rows: company_rows
                }
            
            cb_summary_rows = [...cb_summary_rows, ...company_rows]

            return company_data;
        })  

        this.setState({companySummaryTables: companies_totals,
                       companySummaryResponse: cb_summary_rows});
    }

    getVitusLiquidation(filter){
        let vitus_sum_of_borders = [];
        let vitus_direction_data = []; 
        return getPnl({...filter, is_timezone_cet: "True"}).then((res) => {
            if (res.data.success) {
                let success_data = res.data.success;
                vitus_sum_of_borders = success_data.pnl_summary?.sum_of_borders;
                vitus_direction_data = success_data.pnl_summary?.directions;
                return [vitus_sum_of_borders, vitus_direction_data];
            }
        },
        (error) => {
            handleApiError(error);
        });  
            
    }

    getLtMaxCapacities(filter, company){
        
        let request_filter = {
            'start_date': moment(filter.start_date).utcOffset(0, true).format("YYYY-MM-DD"), 
            'end_date': moment(filter.end_date).utcOffset(0, true).format("YYYY-MM-DD"), 
            'company_list': [company]
        }
        
        
        return getLTAllocationMaxCapacities(request_filter).then((res) => {
            if (res.data.success) {
                let success_data = res.data.success;
                
                return success_data;
            }
        },
        (error) => {
            handleApiError(error);
        });  
            
    }

    prepareEtrmLiquidationDetail(filter){

        let company_list = []
        if (filter.has_vitus_commodities) {
            company_list.push('Vitus Commodities');
        }
        if (filter.has_vitus_capital) {
            company_list.push('Vitus Capital');
        }
        if (filter.has_monolith_capital) {
            company_list.push('Monolith Capital');
        }

        company_list.forEach(company => {
            
            let company_column = {}
            
            this.state.etrmLiquidation.map(h => {
                for (var market in h){
                    
                    let market_currency = '';
                    let company_with_currency = '';
                    let row_list = sortBy(h[market], ['Date']).reverse().map(t => {
                        
                        let value_list = {
                            Market: market
                        }

                        if (t.Company === company){
                            
                            company_column = {
                                key: 'Market',
                                name: `${company} (${t.Currency})`,
                                width: 200,
                                frozen: true}

                            market_currency = t.Currency;
                            company_with_currency = `${company} (${t.Currency})`;
                            
                            value_list['company'] =  company
                            value_list['currency'] = t.Currency
                            value_list['company_with_currency'] = `${company} (${t.Currency})`
                            value_list['Date'] = t.Date
                            value_list['Position'] = t.Position
                            value_list['Mark'] = t.Mark
                            value_list['Price'] = t.Price
                            value_list['Liquidation'] = t.Liquidation

                            return value_list
                        }
                    }).filter(s => s)

                    if (row_list?.length){
                        
                        let other_company_summary = this.state.companyEtrmSummaryResponse.filter(s => s.Market !== market || s.company_with_currency !== company_with_currency)

                        let total_etrm_company_response = []
                        if (other_company_summary?.length){
                            other_company_summary.map(s => {
                                total_etrm_company_response = [...total_etrm_company_response, s]  
                            })
                            total_etrm_company_response = [...row_list, ...total_etrm_company_response]
                        }
                        else{
                            total_etrm_company_response = row_list
                        }

                        let etrm_company_list = [
                            {company: company,
                             market: market,
                             currency: market_currency,
                             rows: row_list, 
                             columns: [company_column, ...columns]
                            }
                        ]    
                        
                        this.setState({
                            companyEtrmSummaryTables: [...this.state.companyEtrmSummaryTables, ...etrm_company_list],
                            companyEtrmSummaryResponse: total_etrm_company_response
                        });
                    }                 
                }

            })
        })
        
        let start_dt = new Date(filter.start_date);
        start_dt.setDate(1);
        let filter_start_dt = moment(start_dt).utcOffset(0, true).format("YYYY-MM-DD");

        let pnl_company_list = new Set(this.state.otcResult.map(r =>  r.Company));
        pnl_company_list.forEach(pnl_company => {
            let is_all_zero = false;


            let companyOtcResult = this.state.otcResult.filter(result => result.Company === pnl_company);
            
            let companyCurrencyList = new Set(companyOtcResult.map(result =>  result.Currency));
            companyCurrencyList.forEach(companyCurrency => {

                let companyCurrencyOtcResults = companyOtcResult.filter(result => result.Currency === companyCurrency);
                // Companies each month total calculated
                let company_month_pnls = [];

                companyCurrencyOtcResults.forEach(result => {
                    let date = result["Date"];
                    let pnl = result["PnL"];
                    
                    if ((new Date(date) >= new Date(filter_start_dt)) && (new Date(date) <= new Date(filter.end_date))){
                        let date_dt = new Date(date);
                        let month_no = date_dt.getMonth() + 1
                        let month_summary = company_month_pnls.find(s => s.month === month_no)
                        let other_month_summary = company_month_pnls.filter(s => s.month !== month_no)
                        if (month_summary){
                            month_summary['total'] = month_summary['total'] ? month_summary['total'] + pnl : pnl
                            month_summary['month_dates'].push(date_dt);
                            company_month_pnls = [month_summary, ...other_month_summary]
                        }
                        else{
                            let new_month = {month: month_no, total: pnl, month_dates: [date_dt]}
                            company_month_pnls = [new_month, ...other_month_summary]                       
                        }                                   
                    }
    
                })

                company_month_pnls.map(m => {
                    let month_data = this.state.maxDates.find(d => d.month === m.month);
                    let other_months_data = this.state.maxDates.filter(d => d.month !== m.month);
    
                    let minimum_date = m['month_dates'].reduce(function (a, b) {
                        return a < b ? a : b;
                    });
                
                    let maximum_date = m['month_dates'].reduce(function (a, b) {
                        return a > b ? a : b;
                    });
    
                    if (month_data){
                        month_data['max_date'] = month_data['max_date'] < maximum_date ? maximum_date : month_data['max_date']
                        let months_max_dates = [month_data, ...other_months_data]
                        this.setState({maxDates: months_max_dates})
                    }
                    else{
                        let new_month = {month: m.month, max_date: maximum_date, min_date: minimum_date}
                        let months_max_dates = [new_month, ...other_months_data]   
                        this.setState({maxDates: months_max_dates})     
                    }
    
                })

                let overall_company_total = {'direction': `${pnl_company} OTC Futures`, 'currency': companyCurrency}
                let pnl_check_list = [];
                companyCurrencyOtcResults.map(result => {
                    let date = result["Date"];
                    let pnl = result["PnL"];

                    if ((new Date(date) >= new Date(filter.start_date)) && (new Date(date) <= new Date(new Date(filter.end_date)))){
                        overall_company_total[date] = overall_company_total[date] ? overall_company_total[date] + pnl : pnl
                        pnl_check_list.push({
                            'Date': date,
                            'PnL': pnl
                        })
                    }
                    
                });
                
                pnl_check_list = pnl_check_list.filter(data => data.PnL !== 0);
                overall_company_total['months_liquidation'] = company_month_pnls;

                if (pnl_check_list?.length > 0){
                    this.setState({totalSummaryTable: [...this.state.totalSummaryTable, overall_company_total]});
                }
            })

        })

    }

    getDailyLiquidation = async (filter, currency, company, period_list) => {
        
        let start_dt = new Date(filter.start_date);
        start_dt.setDate(1);
        let filter_start_dt = moment(start_dt).utcOffset(0, true).format("YYYY-MM-DD");

        let request_filter = {
            year: filter.year,
            start_date: filter_start_dt,
            end_date: moment(filter.end_date).utcOffset(0, true).format("YYYY-MM-DD"),
            currency: currency,
            grouping: "Daily",
            company_list: [company],
            period_list: period_list
        }
        
        let book_name = period_list.includes("st") ? "Crossborder" : "FTR";

        if (company === "Vitus"){
            company = "Vitus Commodities"
        }
        
        let vitus_sum_of_borders = [];
        let vitus_direction_data = [];
        if (company === "Vitus Commodities"){
            [vitus_sum_of_borders, vitus_direction_data] = await this.getVitusLiquidation(request_filter)
        }
        
        // Max capacity for LT Allocations calculated for Vitus Capital and Monolith Capital 
        let lt_directions = [];
        if (company === "Monolith Capital" || company === "Vitus Capital"){
            lt_directions = await this.getLtMaxCapacities(filter, company)
        }


        return getPnl(request_filter)
                .then((res) => {
                    if (res.data.success) {
                        let success_data = res.data.success;
                        let sum_of_borders = success_data.pnl_summary?.sum_of_borders;
                        
                        if (sum_of_borders?.length){
                            
                            sum_of_borders = sum_of_borders.map(h => {
                                if ((new Date(h.dt) >= new Date(filter_start_dt)) && (new Date(h.dt) <= new Date(filter.end_date))){
                                    return h;
                                }
                            }).filter(s => s)
                            
                            let sum_of_borders_daily = sum_of_borders.map(h => {
                                if ((new Date(h.dt) >= new Date(filter.start_date)) && (new Date(h.dt) <= new Date(filter.end_date))){
                                    return h;
                                }
                            }).filter(s => s);
                            
                            
                            let company_summary = this.state.companySummaryTables.find(s => s.company_name === company)
                            let other_company_summary = this.state.companySummaryTables.filter(s => s.company_name !== company)
                            
                            let direction_data = success_data.pnl_summary?.directions;
                            direction_data = sortBy(direction_data, ['direction']);
                            direction_data = direction_data.map(h => {
                                let v = {
                                    direction: h.direction,
                                    data: []
                                }
                                let month_liquidations = []
        
                                h.data.forEach(d => {
                                    if ((new Date(d.dt) >= new Date(filter.start_date)) && (new Date(d.dt) <= new Date(filter.end_date))){
                                        v.data.push({'dt': d.dt, 'v': d.v});                                 
                                    }
                                })

                                if (company === "Vitus Commodities"){
                                    let border_data = vitus_direction_data.find(b => b.direction === h.direction)
                                    if (border_data){
                                        border_data.data.forEach(t => {
                                            if ((new Date(t.dt) >= new Date(filter_start_dt)) && (new Date(t.dt) <= new Date(filter.end_date))){
                                                let date_dt = new Date(t.dt);
                                                let month_no = date_dt.getMonth() + 1
                                                let month_summary = month_liquidations.find(s => s.month === month_no)
                                                let other_month_summary = month_liquidations.filter(s => s.month !== month_no)
                                                if (month_summary){
                                                    month_summary['total'] = month_summary['total'] ? month_summary['total'] + t.v : t.v
                                                    month_summary['month_dates'].push(date_dt);
                                                    month_liquidations = [month_summary, ...other_month_summary]
                                                }
                                                else{
                                                    let new_month = {month: month_no, total: t.v, month_dates: [date_dt]}
                                                    month_liquidations = [new_month, ...other_month_summary]                       
                                                }                                   
                                            }
                                        })
                                    }
                                    
                                }
                                else{
                                    h.data.forEach(t => {
                                        if ((new Date(t.dt) >= new Date(filter_start_dt)) && (new Date(t.dt) <= new Date(filter.end_date))){
                                            let date_dt = new Date(t.dt);
                                            let month_no = date_dt.getMonth() + 1
                                            let month_summary = month_liquidations.find(s => s.month === month_no)
                                            let other_month_summary = month_liquidations.filter(s => s.month !== month_no)
                                            if (month_summary){
                                                month_summary['total'] = month_summary['total'] ? month_summary['total'] + t.v : t.v
                                                month_summary['month_dates'].push(date_dt);
                                                month_liquidations = [month_summary, ...other_month_summary]
                                            }
                                            else{
                                                let new_month = {month: month_no, total: t.v, month_dates: [date_dt]}
                                                month_liquidations = [new_month, ...other_month_summary]                       
                                            }                                   
                                        }
                                    })    
                                }
                                v['month_liquidations'] = month_liquidations;


                                month_liquidations.map(m => {
                                    let month_data = this.state.maxDates.find(d => d.month === m.month);
                                    let other_months_data = this.state.maxDates.filter(d => d.month !== m.month);
            
                                    let minimum_date = m['month_dates'].reduce(function (a, b) {
                                        return a < b ? a : b;
                                    });
                                
                                    let maximum_date = m['month_dates'].reduce(function (a, b) {
                                        return a > b ? a : b;
                                    });
            
                                    if (month_data){
                                        month_data['max_date'] = month_data['max_date'] < maximum_date ? maximum_date : month_data['max_date']
                                        let months_max_dates = [month_data, ...other_months_data]
                                        this.setState({maxDates: months_max_dates})
                                    }
                                    else{
                                        let new_month = {month: m.month, max_date: maximum_date, min_date: minimum_date}
                                        let months_max_dates = [new_month, ...other_months_data]   
                                        this.setState({maxDates: months_max_dates})     
                                    }
            
                                })

                                return v;
                            })
                            
                            let excel_columns = [];
                            let company_column = {
                                key: 'direction',
                                name: `${company} (${currency})`,
                                width: 200,
                                frozen: true,
                                formatter: DirectionFormatter
                            }
                            
                            let direction_column = {
                                key: 'direction',
                                name: 'Company',
                                width: 200,
                                frozen: true,
                                formatter: DirectionFormatter
                            }

                            excel_columns.push("direction", "currency");
        
                            let currency_column = {
                                key: 'currency',
                                name: 'Currency',
                                width: 150,
                                height: 150,
                                formatter ({row}){  
                                    if (totalRowList.includes(row["direction"])){
                                        return (<div className={`${"summary"}`}>{row["currency"]}</div>);
                                    }      
                                    else if (overallTotalList.includes(row["direction"])){
                                        return (<div className={`${"overall-summary"}`}>{row["currency"]}</div>);
                                    }
                                    return row["currency"]
                                }  
                            }
                                                    
                            let columnList = sortBy(sum_of_borders_daily, ['dt']).reverse().map(h => {

                                    excel_columns.push(h["dt"]);
                                    return {
                                        key: h["dt"],
                                        name: h["dt"],
                                        width: 100,
                                        height: 150,
                                        formatter ({row}){  
                                            if (row[h.dt]){
                                                if (totalRowList.includes(row["direction"])){
                                                    return (<div className={`${"summary"}`}>{row[h.dt].toLocaleString(undefined, { maximumFractionDigits: 2 })}</div>);
                                                }      
                                                if (overallTotalList.includes(row["direction"])){
                                                    return (<div className={`${"overall-summary"}`}>{row[h.dt].toLocaleString(undefined, { maximumFractionDigits: 2 })}</div>);
                                                }
                                                return row[h.dt].toLocaleString(undefined, { maximumFractionDigits: 2 })
                                            }
                                            else{
                                                if (totalRowList.includes(row["direction"])){
                                                    return (<div className={`${"summary"}`}>0</div>);
                                                }      
                                                if (overallTotalList.includes(row["direction"])){
                                                    return (<div className={`${"overall-summary"}`}>0</div>);
                                                }
                                                return 0;
                                            }
                                        }
                                }
                                
                            })

                            let empty_column = {
                                key: 'Empty',
                                name: '',
                                width: 150,
                                height: 150,
                                formatter({row}){
                                    return (<div className='empty'>{row["Empty"]}</div>)
                                }
                            }
                            excel_columns.push("Empty");

                            let currency_max_columns = [];
                            let total_columns = sortBy(this.state.maxDates, ['month']).reverse().map(m => {
                                let min_date = m['min_date'].getDate();
                                let max_date = m['max_date'].getDate();   
                                let month = m['month'];
                                let month_total_name = `${min_date} - ${max_date} ${month_names[month-1]}`
                                excel_columns.push(month_total_name);
                                currency_max_columns.push(moment(m['max_date']).utcOffset(0, true).format("YYYY-MM-DD"));

                                return {
                                    key: `${min_date} - ${max_date} ${month_names[month-1]}`,
                                    name: `${min_date} - ${max_date} ${month_names[month-1]}`,
                                    width: 100,
                                    height: 150,
                                    formatter ({row}){  
                                        if (row[month_total_name]){
                                            if (totalRowList.includes(row["direction"])){
                                                return (<div className={`${"summary"}`}>{row[month_total_name].toLocaleString(undefined, { maximumFractionDigits: 2 })}</div>);
                                            }      
                                            else if (overallTotalList.includes(row["direction"])){
                                                return (<div className={`${"overall-summary"}`}>{row[month_total_name].toLocaleString(undefined, { maximumFractionDigits: 2 })}</div>);
                                            }
                                            else if (((!companyTotalList.includes(row["direction"])) && (!totalRowList.includes(row["direction"])) && (!overallTotalList.includes(row["direction"]))) || (companyTotalList.includes(row["direction"]))){
                                                return (<div className={`${"bold-summary"}`}>{row[month_total_name].toLocaleString(undefined, { maximumFractionDigits: 2 })}</div>);
                                            }
                                            return row[month_total_name].toLocaleString(undefined, { maximumFractionDigits: 2 })
                
                                        }                                
                                        else{
                                            if (totalRowList.includes(row["direction"])){
                                                return (<div className={`${"summary"}`}>0</div>);
                                            }      
                                            else if (overallTotalList.includes(row["direction"])){
                                                return (<div className={`${"overall-summary"}`}>0</div>);
                                            }
                                            else if (((!companyTotalList.includes(row["direction"])) && (!totalRowList.includes(row["direction"])) && (!overallTotalList.includes(row["direction"]))) || (companyTotalList.includes(row["direction"]))){
                                                return (<div className={`${"bold-summary"}`}>0</div>);
                                            }
                                            return 0;
                                        }
                                    }         
                                }              
                            })

                            let rowList = direction_data.map(h => {
                                
                                let all_zero = h.data.map(d => d.v === 0).every(d => d)
                                if (all_zero){
                                    return 
                                }
        
                                let value_list = {
                                    direction: h.direction,
                                    company: company,
                                    currency: currency,
                                    company_with_currency: `${company} (${currency})`
                                }
        
                                h.data.forEach(d => {
                                    if ((company === "Monolith Capital" || company === "Vitus Capital") && book_name === "FTR"){
                                        if (lt_directions.find(s => s.direction === h.direction)){
                                            let max_mw_info = lt_directions.find(s => s.direction === h.direction)['max']
                                            value_list['direction'] = `${h.direction} (${max_mw_info} MW)`
                                        }
                                        else{
                                            if (h.direction in capitalDefaultLT){
                                                value_list['direction'] = `${h.direction} (${capitalDefaultLT[h.direction]} MW)`
                                            }
                                            else{
                                                value_list['direction'] = `${h.direction}`
                                            }
                                        }                   
                                    }
                                    value_list[d.dt] = d.v                                
                                })
                                value_list['month_liquidations'] = h['month_liquidations'];
                                return value_list
                                
                            }).filter(s => s)

                            let company_total = {}
                            let company_without_total_list = []
                            let capital_total_list = {}
                            if (company_summary){
                                company_total = company_summary.rows.find(s => s.direction === "Total");
                                company_without_total_list = company_summary.rows.filter(s => s.direction !== "Total");
                            }
                            else{
                                company_total = {direction: 'Total', company: company, currency: currency, company_with_currency: `${company} (${currency})`}
                            }
        
                            if ((company === "Monolith Capital" || company === "Vitus Capital") && book_name === "FTR"){
                                capital_total_list = {direction: capitalLtTotal, company: company, currency: currency, company_with_currency: `${company} (${currency})`}
                            }
                            if ((company === "Monolith Capital" || company === "Vitus Capital") && book_name === "Crossborder"){
                                capital_total_list = {direction: capitalStTotal, company: company, currency: currency, company_with_currency: `${company} (${currency})`}
                            }

                            
                            let company_month_liquidations = [];
                            let capital_month_liquidations = [];
                            company_month_liquidations = company_total['month_liquidations'];
                            if (! company_month_liquidations){
                                company_month_liquidations = [];
                            }

                            sum_of_borders.map(h => {
                                if ((new Date(h.dt) >= new Date(filter.start_date)) && (new Date(h.dt) <= new Date(filter.end_date))){ 
                                    company_total[h.dt] = company_total[h.dt] ? company_total[h.dt] + h.today : h.today
                                }
                                if (company === "Monolith Capital" || company === "Vitus Capital"){
                                    if ((new Date(h.dt) >= new Date(filter.start_date)) && (new Date(h.dt) <= new Date(filter.end_date))){
                                        capital_total_list[h.dt] = capital_total_list[h.dt] ? capital_total_list[h.dt] + h.today : h.today
                                    }
                                    if ((new Date(h.dt) >= new Date(filter_start_dt)) && (new Date(h.dt) <= new Date(filter.end_date))){
                                        let date_dt = new Date(h.dt);
                                        let month_no = date_dt.getMonth() + 1
                                        let month_summary = capital_month_liquidations.find(s => s.month === month_no)
                                        let other_month_summary = capital_month_liquidations.filter(s => s.month !== month_no)
                                        if (month_summary){
                                            month_summary['total'] = month_summary['total'] ? month_summary['total'] + h.today : h.today
                                            month_summary['month_dates'].push(date_dt);
                                            capital_month_liquidations = [month_summary, ...other_month_summary]
                                        }
                                        else{
                                            let new_month = {month: month_no, total: h.today, month_dates: [date_dt]}
                                            capital_month_liquidations = [new_month, ...other_month_summary]                       
                                        }                                   
                                    }
                                }
                                if (company === "Vitus Commodities"){
                                    if ((new Date(h.dt) >= new Date(filter_start_dt)) && (new Date(h.dt) <= new Date(filter.end_date))){
                                        let day_data = vitus_sum_of_borders.find(b => b.dt === h.dt);
                                        if (day_data){
                                            let date_dt = new Date(h.dt);
                                            let month_no = date_dt.getMonth() + 1
                                            let month_summary = company_month_liquidations.find(s => s.month === month_no)
                                            let other_month_summary = company_month_liquidations.filter(s => s.month !== month_no)
                                            if (month_summary){
                                                month_summary['total'] = month_summary['total'] ? month_summary['total'] + day_data.today : day_data.today
                                                month_summary['month_dates'].push(date_dt);
                                                company_month_liquidations = [month_summary, ...other_month_summary]
                                            }
                                            else{
                                                let new_month = {month: month_no, total: day_data.today, month_dates: [date_dt]}
                                                company_month_liquidations = [new_month, ...other_month_summary]                       
                                            }       
                                    }
                                        
                                                                
                                    }
                                }
                                else{
                                    if ((new Date(h.dt) >= new Date(filter_start_dt)) && (new Date(h.dt) <= new Date(filter.end_date))){
                                        let date_dt = new Date(h.dt);
                                        let month_no = date_dt.getMonth() + 1
                                        let month_summary = company_month_liquidations.find(s => s.month === month_no)
                                        let other_month_summary = company_month_liquidations.filter(s => s.month !== month_no)
                                        if (month_summary){
                                            month_summary['total'] = month_summary['total'] ? month_summary['total'] + h.today : h.today
                                            month_summary['month_dates'].push(date_dt);
                                            company_month_liquidations = [month_summary, ...other_month_summary]
                                        }
                                        else{
                                            let new_month = {month: month_no, total: h.today, month_dates: [date_dt]}
                                            company_month_liquidations = [new_month, ...other_month_summary]                       
                                        }                                   
                                    }
                                }
                                            
                                company_total['month_liquidations'] = company_month_liquidations;
                                if (company === "Monolith Capital" || company === "Vitus Capital"){
                                    capital_total_list['month_liquidations'] = capital_month_liquidations;
                                }
        
                            })
                            
                            let company_with_total_list = []
                            if (company === "Monolith Capital" || company === "Vitus Capital"){
                                company_with_total_list = [...company_without_total_list, ...rowList, capital_total_list, company_total]
                            }
                            else{
                                company_with_total_list = [...company_without_total_list, ...rowList, company_total]
                            }
        
                            let overall_company_total = {'direction': `${company} ${book_name}`, 'currency': currency}
                            sum_of_borders.map(h => {
                                if ((new Date(h.dt) >= new Date(filter.start_date)) && (new Date(h.dt) <= new Date(filter.end_date))){
                                    overall_company_total[h.dt] = overall_company_total[h.dt] ? overall_company_total[h.dt] + h.today : h.today
                                }
                                
                            })
                            if (company === "Monolith Capital" || company === "Vitus Capital"){
                                overall_company_total['months_liquidation'] = capital_month_liquidations;
                            }
                            else{
                                overall_company_total['months_liquidation'] = company_month_liquidations;
                            }

                            let company_list = [
                                {
                                    company_name: company_summary ? company_summary.company_name : company,
                                    columns: [company_column, ...columnList, empty_column, ...total_columns],
                                    rows: company_with_total_list
                                }
                            ]
                            
                            let total_company_response = []
                            if (other_company_summary?.length){
                                company_list = [...company_list, ...other_company_summary]
                                other_company_summary.map(s => {
                                    total_company_response = [...total_company_response, ...s.rows]   
                                    }
                                )
                                total_company_response = [...company_with_total_list, ...total_company_response]
                            }
                            else{
                                total_company_response = company_with_total_list
                            }
                            
                            this.setState({
                                totalSummaryTable: [...this.state.totalSummaryTable, overall_company_total],
                                totalSummaryTableColumns: [direction_column, currency_column, ...columnList, empty_column, ...total_columns],
                                companySummaryTables: company_list,
                                totalExcelColumns: excel_columns,
                                currencyMonthColumns: currency_max_columns
                                })   
                            
                            this.calculateCompanyTotalLiquidation();
                            this.calculateTotalLiquidationTable();
                        }
        
                        }
                    
                },
                (error) => {
                    handleApiError(error);
                })
                .finally(() =>  {
                    alertSuccess(messages.LIQUIDATION.SUCCESSFUL_LIQUIDATION.replace("[COMPANY]", company).replace("[BOOK]", book_name))});
                

    };
    
    calculateOverallTotal(){
        let eurSubTotalList = this.calculateTableSubTotal('EUR');
        let trySubTotalList = this.calculateTableSubTotal('TRY');
        let usdSubTotalList = this.calculateTableSubTotal('USD');
    
        this.setState({totalSummaryTable: [...eurSubTotalList, ...trySubTotalList, ...usdSubTotalList]})
        this.calculateTableTotal();
                    
    }
    
    downloadEtrmLiquidation = async () => {
        const spinnerKey = this.spinner.showSpinner();
        let fileCompanyList = []
        if (this.state.filter.has_vitus_commodities) {
            fileCompanyList.push("Vitus Commodities") 
        }
        if (this.state.filter.has_vitus_capital) {
            fileCompanyList.push("Vitus Capital")
        }
        if (this.state.filter.has_monolith_capital) {
            fileCompanyList.push("Monolith Capital")
        } 

        let body = {
            summary_data: { total_result_list: this.state.totalSummaryTable, etrm_result_list: this.state.companyEtrmSummaryResponse,
                            cb_result_list: this.state.companySummaryResponse, currency_list: this.state.currencyRows,
                            company_list: fileCompanyList, excel_columns: this.state.totalExcelColumns, 
                            currency_columns: this.state.currencyMonthColumns},
            start_date: this.state.filter.start_date,
            end_date: this.state.filter.end_date
        }

        downloadEtrmLiquidation(body).then(async response => {
            

            const filename = `${fileCompanyList.join("-")} Total Daily Liquidation Report ${moment(new Date()).format('DD-MM-YYYY')}.xlsx`;
            const contentType = response.headers["content-type"];

            const blob = new Blob([response.data], { type: contentType });

            if (contentType === 'application/json') {
                //const error = blob.text();
                return;
            }

            FileSaver.saveAs(blob, filename);
            return;
        }, error => {
            handleApiError(error);
        }).finally(() => {
            this.spinner.hideSpinner(spinnerKey);
        });
    }

    sendEtrmLiquidation = async () => {
        const spinnerKey = this.spinner.showSpinner();
        let fileCompanyList = []
        if (this.state.filter.has_vitus_commodities) {
            fileCompanyList.push("Vitus Commodities") 
        }
        if (this.state.filter.has_vitus_capital) {
            fileCompanyList.push("Vitus Capital")
        }
        if (this.state.filter.has_monolith_capital) {
            fileCompanyList.push("Monolith Capital")
        } 

        const filename = `${fileCompanyList.join("-")} Total Daily Liquidation Report ${moment(new Date()).format('DD-MM-YYYY')}.xlsx`;
            
        let body = {
            summary_data: { total_result_list: this.state.totalSummaryTable, etrm_result_list: this.state.companyEtrmSummaryResponse,
                            cb_result_list: this.state.companySummaryResponse, currency_list: this.state.currencyRows,
                            company_list: fileCompanyList, excel_columns: this.state.totalExcelColumns,
                            currency_columns: this.state.currencyMonthColumns},
            start_date: this.state.filter.start_date,
            end_date: this.state.filter.end_date,
            file_name: filename
        }

        sendEtrmLiquidation(body).then(async response => {
            if (response.data.success){
                alertSuccess(messages.LIQUIDATION.SEND_LIQUIDATION_SUCCESS);
            }
            else{
                alertError(messages.LIQUIDATION.SEND_LIQUIDATION_ERROR);
            }
        }, error => {
            handleApiError(error);
        }).finally(() => {
            this.spinner.hideSpinner(spinnerKey);
        });
    }


    async getCompaniesDetail() {
        this.spinner.showSpinner("getCompanyNames");
        getCompaniesDetail()
            .then(
                (response) => {
                    if (response.data.success) {
                        let successData = response.data.success;
                        this.setState({
                            counterPartyNames: successData.company_list.map((x) => ({
                                id: x.id,
                                value: x.name,
                                label: x.name,
                                type: x.type,
                            })),
                        });
                    }
                },
                (error) => {
                    handleApiError(error);
                }
            )
            .finally(() => {
                this.spinner.hideSpinner("getCompanyNames");
            });
    }

    async getMarketDetails() {
        this.spinner.showSpinner("getMarketNames");
        getMarketNames()
            .then(
                (response) => {
                    if (response.data.success) {
                        let successData = response.data.success;
                        this.setState({
                            marketNames: successData.market_name_list.map((x) => ({
                                id: x.id,
                                value: x.name,
                                label: x.name,
                                currency: x.currency,
                            })),
                        });
                    }
                },
                (error) => {
                    handleApiError(error);
                }
            )
            .finally(() => {
                this.spinner.hideSpinner("getMarketNames");
            });
    }

    async getBookDetails() {
        this.spinner.showSpinner("getBookNames");
        getBookDetails()
            .then(
                (response) => {
                    if (response.data.success) {
                        let successData = response.data.success;
                        this.setState({
                            bookNames: successData.book_list.map((x) => ({
                                id: x.id,
                                value: x.name,
                                label: x.name,
                            })),
                        });
                    }
                },
                (error) => {
                    handleApiError(error);
                }
            )
            .finally(() => {
                this.spinner.hideSpinner("getBookNames");
            });
    }

    getTableComponent() {
        return (
            <LeftSideContainer
                contentLabel={this.title}
                contentDefaultExpanded={true}
                contentComponent={
                    <div>   
                        <div className="t-table-toolbar">
                            <button type="button" className="btn t-orange-button" onClick={() => { this.downloadEtrmLiquidation() }}>
                                Export to XLSX
                            </button>
                            <button type="button" className="btn t-orange-button" onClick={() => { this.sendEtrmLiquidation() }}>
                                Send PDF to Slack
                            </button>
                        </div>
                        {
                            this.state.currencyRows.length ? (
                                <DataGrid
                                    columns={this.state.currencyColumns}
                                    rows={this.state.currencyRows.filter(s => s)}
                                    defaultColumnOptions={{ resizable: true }}
                                    style={{height: `${(this.state.currencyRows.length + 1) * 50}px`}}
                                />               
                            ) : null
                        }
                        {                  
                            this.state.totalSummaryTable.length &&
                                this.state.totalSummaryTableColumns.length ? (
                                <DataGrid
                                    columns={this.state.totalSummaryTableColumns}
                                    rows={this.state.totalSummaryTable.filter(s => s)}
                                    defaultColumnOptions={{ resizable: true }}
                                    style={{height: `${(this.state.totalSummaryTable.length + 1) * 50}px`}}
                                />            
                            ) : null
                        }                              
                        {
                            this.state.companySummaryTables.length ? (
                                    this.state.companySummaryTables.map(table => {
                                        return (
                                            table.rows.length ? (
                                                <DataGrid
                                                    title = {table.company_name}
                                                    style={{ height: `${(table.rows.length + 1) * 50}px` }}
                                                    columns={table.columns}
                                                    rows={table.rows}
                                                    defaultColumnOptions={{ resizable: true }}
                                                />
                                            ) : null   
                                        )
                                    })
                                ) : null
                        }      
                        {    
                            this.state.companyEtrmSummaryTables.length ? (
                                <div style={{ "margin": "12px" }}>
                                    <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                                        {"Daily Liquidation in OTC Positions"}
                                    </span>
                                </div>
                            ) : null
                        }
                        {
                            this.state.companyEtrmSummaryTables.length ? (
                                this.state.companyEtrmSummaryTables.map(table => {
                                    return (
                                        table.rows.length ? (
                                            <DataGrid
                                                title = {table.company}
                                                style={{ height: `${(table.rows.length + 1) * 50}px` }}
                                                columns={table.columns}
                                                rows={table.rows}
                                                defaultColumnOptions={{ resizable: true }}
                                            />
                                            
                                        ) : null
                                    )
                                })
                            ) : null
                        }
                    </div>
                }
                filterComponent={
                    this.state.counterPartyNames.length &&
                        this.state.marketNames.length &&
                        this.state.bookNames.length ? (
                            <DailyLiquidationFilter
                                counterPartyNames={this.state.counterPartyNames}
                                marketNames={this.state.marketNames}
                                bookNames={this.state.bookNames}
                                onShowButtonClick={this.onShowButtonClick}
                            ></DailyLiquidationFilter>
                        ) : null
                }
            ></LeftSideContainer>
        );
    }

    render() {
        return (
            <TContentContainer>
                <TContentDetailContainer
                    leftSideComponent={this.getTableComponent()}
                ></TContentDetailContainer>
            </TContentContainer>
        );
    }
}

export default DailyLiquidation;
