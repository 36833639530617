import React from 'react';
import DataGrid from 'react-data-grid';
import { groupBy as rowGrouper, sortBy } from 'lodash';
import TContentContainer from '../../components/TContentContainer/TContentContainer';
import TContentDetailContainer from '../../components/TContentDetailContainer/TContentDetailContainer';
import LeftSideContainer from '../../components/TTPageContainers/LeftSideContainer';
import PnlFilter from './PnlFilter';
import {
    getMarketNames, getBookDetails, getCompaniesDetail, getEtrmPnlV2,
    getCompanySummaryPnl, downloadCompanySummaryPnl, getPnlStatus, sendCompanySummaryPnl
} from '../../api/services';
import { handleApiError, alertError } from '../../utils/errorHandler';
import { SpinnerManager } from '../../components/TSpinner/SpinnerManager';
import history from '../../../history';
import { messages } from '../../utils/messages';
import { getPnl } from '../../../apis/vitusApi';
import Radio from '@material-ui/core/Radio';
import InfoIcon from '@material-ui/icons/Info';
import TDialogBox from '../../components/TDialogBox';
import Tooltip from '@material-ui/core/Tooltip';
import FileSaver from 'file-saver';
import moment from 'moment';
import { alertSuccess } from '../../../helpers/errorHelper';
import MuiAlert from '@material-ui/lab/Alert';


const month_names = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const columns = [
    {
        key: 'country',
        name: 'Country',
    },
    {
        key: 'period',
        name: 'Data',
        width: 100,
    },
    {
        key: 'type',
        name: 'Type',
        width: 130,
    },
    {
        key: 'jan',
        name: 'Jan',
        formatter({ row }) {
            return (<Tooltip title={row.jan.source} arrow>
                <div>{valueFormatter(row.jan.value)}</div>
            </Tooltip>);
        },
        groupFormatter({ childRows }) {
            return pnlCountryGroupFormatter(childRows, "jan")
        }
    },
    {
        key: 'feb',
        name: 'Feb',
        formatter({ row }) {
            return (<Tooltip title={row.feb.source} arrow>
                <div>{valueFormatter(row.feb.value)}</div>
            </Tooltip>);
        },
        groupFormatter({ childRows }) {
            return pnlCountryGroupFormatter(childRows, "feb")
        }
    },
    {
        key: 'mar',
        name: 'Mar',
        formatter({ row }) {
            return (<Tooltip title={row.mar.source} arrow>
                <div>{valueFormatter(row.mar.value)}</div>
            </Tooltip>);
        },
        groupFormatter({ childRows }) {
            return pnlCountryGroupFormatter(childRows, "mar")
        }
    },
    {
        key: 'apr',
        name: 'Apr',
        formatter({ row }) {
            return (<Tooltip title={row.apr.source} arrow>
                <div>{valueFormatter(row.apr.value)}</div>
            </Tooltip>);
        },
        groupFormatter({ childRows }) {
            return pnlCountryGroupFormatter(childRows, "apr")
        }
    },
    {
        key: 'may',
        name: 'May',
        formatter({ row }) {
            return (<Tooltip title={row.may.source} arrow>
                <div>{valueFormatter(row.may.value)}</div>
            </Tooltip>);
        },
        groupFormatter({ childRows }) {
            return pnlCountryGroupFormatter(childRows, "may")
        }
    },
    {
        key: 'jun',
        name: 'Jun',
        formatter({ row }) {
            return (<Tooltip title={row.jun.source} arrow>
                <div>{valueFormatter(row.jun.value)}</div>
            </Tooltip>);
        },
        groupFormatter({ childRows }) {
            return pnlCountryGroupFormatter(childRows, "jun")
        }
    },
    {
        key: 'jul',
        name: 'Jul',
        formatter({ row }) {
            return (<Tooltip title={row.jul.source} arrow>
                <div>{valueFormatter(row.jul.value)}</div>
            </Tooltip>);
        },
        groupFormatter({ childRows }) {
            return pnlCountryGroupFormatter(childRows, "jul")
        }
    },
    {
        key: 'aug',
        name: 'Aug',
        formatter({ row }) {
            return (<Tooltip title={row.aug.source} arrow>
                <div>{valueFormatter(row.aug.value)}</div>
            </Tooltip>);
        },
        groupFormatter({ childRows }) {
            return pnlCountryGroupFormatter(childRows, "aug")
        }
    },
    {
        key: 'sep',
        name: 'Sep',
        formatter({ row }) {
            return (<Tooltip title={row.sep.source} arrow>
                <div>{valueFormatter(row.sep.value)}</div>
            </Tooltip>);
        },
        groupFormatter({ childRows }) {
            return pnlCountryGroupFormatter(childRows, "sep")
        }
    },
    {
        key: 'oct',
        name: 'Oct',
        formatter({ row }) {
            return (<Tooltip title={row.oct.source} arrow>
                <div>{valueFormatter(row.oct.value)}</div>
            </Tooltip>);
        },
        groupFormatter({ childRows }) {
            return pnlCountryGroupFormatter(childRows, "oct")
        }
    },
    {
        key: 'nov',
        name: 'Nov',
        formatter({ row }) {
            return (<Tooltip title={row.nov.source} arrow>
                <div>{valueFormatter(row.nov.value)}</div>
            </Tooltip>);
        },
        groupFormatter({ childRows }) {
            return pnlCountryGroupFormatter(childRows, "nov")
        }
    },
    {
        key: 'dec',
        name: 'Dec',
        formatter({ row }) {
            return (<Tooltip title={row.dec.source} arrow>
                <div>{valueFormatter(row.dec.value)}</div>
            </Tooltip>);
        },
        groupFormatter({ childRows }) {
            return pnlCountryGroupFormatter(childRows, "dec")
        }
    },
    {
        key: 'total',
        name: 'Total',
        formatter({ row }) {
            if (row.period === "Mark" || row.period === "Peak" || row.period === "Offpeak") {
                const sum = row.jan.value + row.feb.value + row.mar.value + row.apr.value + row.may.value + row.jun.value + row.jul.value + row.aug.value + row.sep.value + row.oct.value + row.nov.value + row.dec.value
                return <>{(sum / 12).toFixed(2)}</>;
            }
            const sum = row.jan.value + row.feb.value + row.mar.value + row.apr.value + row.may.value + row.jun.value + row.jul.value + row.aug.value + row.sep.value + row.oct.value + row.nov.value + row.dec.value
            return <>{valueFormatter(sum)}</>;
        },
        groupFormatter({ childRows }) {
            let period_list = new Set(childRows.map(r => r.period))

            if (period_list.size === 1) {
                if (period_list.has("Mark") || period_list.has("Peak") || period_list.has("Offpeak")) {
                    childRows = childRows.filter(r => r.type === 'Last')
                    const sum = childRows.reduce((prev, row) => prev + row.jan.value + row.feb.value + row.mar.value + row.apr.value + row.may.value + row.jun.value + row.jul.value + row.aug.value + row.sep.value + row.oct.value + row.nov.value + row.dec.value, 0)
                    return <>{(sum / 12).toFixed(2)}</>;
                }

                if (period_list.has("Positions")) {
                    childRows = childRows.filter(r => r.type === 'Last Peak' || r.type === 'Last Offpeak')
                    const sum = childRows.reduce((prev, row) => prev + row.jan.value + row.feb.value + row.mar.value + row.apr.value + row.may.value + row.jun.value + row.jul.value + row.aug.value + row.sep.value + row.oct.value + row.nov.value + row.dec.value, 0)
                    return <>{(sum / childRows.length).toFixed(2)}</>;
                }

                if (period_list.has("PNL")) {
                    childRows = childRows.filter(r => r.period === "PNL" && r.type === "Last")
                }
                const sum = childRows.reduce((prev, row) => prev + row.jan.value + row.feb.value + row.mar.value + row.apr.value + row.may.value + row.jun.value + row.jul.value + row.aug.value + row.sep.value + row.oct.value + row.nov.value + row.dec.value, 0)
                return <>{valueFormatter(sum)}</>;
            }

            childRows = childRows.filter(r => r.period === "PNL" && r.type === "Last")
            const sum = childRows.reduce((prev, row) => prev + row.jan.value + row.feb.value + row.mar.value + row.apr.value + row.may.value + row.jun.value + row.jul.value + row.aug.value + row.sep.value + row.oct.value + row.nov.value + row.dec.value, 0)
            return <>{valueFormatter(sum)}</>;
        }
    }
];

const company_summary_columns = [
    {
        key: 'jan',
        name: 'Jan',
        formatter({ row }) {
            return <>{valueFormatter(row.jan)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCompanySummaryGroupFormatter(childRows, "jan")
        }
    },
    {
        key: 'feb',
        name: 'Feb',
        formatter({ row }) {
            return <>{valueFormatter(row.feb)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCompanySummaryGroupFormatter(childRows, "feb")
        }
    },
    {
        key: 'mar',
        name: 'Mar',
        formatter({ row }) {
            return <>{valueFormatter(row.mar)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCompanySummaryGroupFormatter(childRows, "mar")
        }
    },
    {
        key: 'apr',
        name: 'Apr',
        formatter({ row }) {
            return <>{valueFormatter(row.apr)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCompanySummaryGroupFormatter(childRows, "apr")
        }
    },
    {
        key: 'may',
        name: 'May',
        formatter({ row }) {
            return <>{valueFormatter(row.may)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCompanySummaryGroupFormatter(childRows, "may")
        }
    },
    {
        key: 'jun',
        name: 'Jun',
        formatter({ row }) {
            return <>{valueFormatter(row.jun)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCompanySummaryGroupFormatter(childRows, "jun")
        }
    },
    {
        key: 'jul',
        name: 'Jul',
        formatter({ row }) {
            return <>{valueFormatter(row.jul)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCompanySummaryGroupFormatter(childRows, "jul")
        }
    },
    {
        key: 'aug',
        name: 'Aug',
        formatter({ row }) {
            return <>{valueFormatter(row.aug)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCompanySummaryGroupFormatter(childRows, "aug")
        }
    },
    {
        key: 'sep',
        name: 'Sep',
        formatter({ row }) {
            return <>{valueFormatter(row.sep)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCompanySummaryGroupFormatter(childRows, "sep")
        }
    },
    {
        key: 'oct',
        name: 'Oct',
        formatter({ row }) {
            return <>{valueFormatter(row.oct)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCompanySummaryGroupFormatter(childRows, "oct")
        }
    },
    {
        key: 'nov',
        name: 'Nov',
        formatter({ row }) {
            return <>{valueFormatter(row.nov)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCompanySummaryGroupFormatter(childRows, "nov")
        }
    },
    {
        key: 'dec',
        name: 'Dec',
        formatter({ row }) {
            return <>{valueFormatter(row.dec)}</>;
        },
        groupFormatter({ childRows }) {
            return pnlCompanySummaryGroupFormatter(childRows, "dec")
        }
    },
    {
        key: 'total',
        name: 'Total',
        formatter({ row }) {
            const sum = row.jan + row.feb + row.mar + row.apr + row.may + row.jun + row.jul + row.aug + row.sep + row.oct + row.nov + row.dec
            return <>{valueFormatter(sum)}</>;
        }
    }
];

const curr_columns = [
    {
        key: 'currency',
        name: 'Currency',
        width: 100,
    },
    {
        key: 'type',
        name: 'Type'
    },
    {
        key: 'jan',
        name: 'Jan',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { jan }) => prev + jan, 0);
            const avg = (sum / childRows.length) || 0;
            return <>{avg.toFixed(5)}</>;
        }
    },
    {
        key: 'feb',
        name: 'Feb',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { feb }) => prev + feb, 0);
            const avg = (sum / childRows.length) || 0;
            return <>{avg.toFixed(5)}</>;
        }
    },
    {
        key: 'mar',
        name: 'Mar',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { mar }) => prev + mar, 0);
            const avg = (sum / childRows.length) || 0;
            return <>{avg.toFixed(5)}</>;
        }
    },
    {
        key: 'apr',
        name: 'Apr',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { apr }) => prev + apr, 0);
            const avg = (sum / childRows.length) || 0;
            return <>{avg.toFixed(5)}</>;
        }
    },
    {
        key: 'may',
        name: 'May',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { may }) => prev + may, 0);
            const avg = (sum / childRows.length) || 0;
            return <>{avg.toFixed(5)}</>;
        }
    },
    {
        key: 'jun',
        name: 'Jun',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { jun }) => prev + jun, 0);
            const avg = (sum / childRows.length) || 0;
            return <>{avg.toFixed(5)}</>;
        }
    },
    {
        key: 'jul',
        name: 'Jul',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { jul }) => prev + jul, 0);
            const avg = (sum / childRows.length) || 0;
            return <>{avg.toFixed(5)}</>;
        }
    },
    {
        key: 'aug',
        name: 'Aug',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { aug }) => prev + aug, 0);
            const avg = (sum / childRows.length) || 0;
            return <>{avg.toFixed(5)}</>;
        }
    },
    {
        key: 'sep',
        name: 'Sep',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { sep }) => prev + sep, 0);
            const avg = (sum / childRows.length) || 0;
            return <>{avg.toFixed(5)}</>;
        }
    },
    {
        key: 'oct',
        name: 'Oct',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { oct }) => prev + oct, 0);
            const avg = (sum / childRows.length) || 0;
            return <>{avg.toFixed(5)}</>;
        }
    },
    {
        key: 'nov',
        name: 'Nov',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { nov }) => prev + nov, 0);
            const avg = (sum / childRows.length) || 0;
            return <>{avg.toFixed(5)}</>;
        }
    },
    {
        key: 'dec',
        name: 'Dec',
        groupFormatter({ childRows }) {
            const sum = childRows.reduce((prev, { dec }) => prev + dec, 0);
            const avg = (sum / childRows.length) || 0;
            return <>{avg.toFixed(5)}</>;
        }
    },
    {
        key: 'average',
        name: 'Average',
        formatter({ row }) {
            return <>{((row.jan + row.feb + row.mar + row.apr + row.may + row.jun + row.jul + row.aug + row.sep + row.oct + row.nov + row.dec) / 12).toFixed(4)}</>;
        },
        groupFormatter({ childRows }) {
            childRows = childRows.filter(r => r.type === "Last")
            const sum = childRows.reduce((prev, row) => prev + row.jan + row.feb + row.mar + row.apr + row.may + row.jun + row.jul + row.aug + row.sep + row.oct + row.nov + row.dec, 0)
            const avg = (sum / 12) || 0;
            return <>{avg.toFixed(4)}</>;
        }

    }
];

const total_columns = [
    {
        key: 'country',
        name: 'Country',
    },
    {
        key: 'period',
        name: 'Data',
        width: 100,
    },
    {
        key: 'type',
        name: 'Type'
    },
    {
        key: 'jan',
        name: 'Jan',
        formatter({ row }) {
            return <>{valueFormatter(row.jan.value)}</>;
        },
        groupFormatter({ childRows }) {
            return totalPnlGroupFormatter(childRows, "jan")
        }
    },
    {
        key: 'feb',
        name: 'Feb',
        formatter({ row }) {
            return <>{valueFormatter(row.feb.value)}</>;
        },
        groupFormatter({ childRows }) {
            return totalPnlGroupFormatter(childRows, "feb")
        }
    },
    {
        key: 'mar',
        name: 'Mar',
        formatter({ row }) {
            return <>{valueFormatter(row.mar.value)}</>;
        },
        groupFormatter({ childRows }) {
            return totalPnlGroupFormatter(childRows, "mar")
        }
    },
    {
        key: 'apr',
        name: 'Apr',
        formatter({ row }) {
            return <>{valueFormatter(row.apr.value)}</>;
        },
        groupFormatter({ childRows }) {
            return totalPnlGroupFormatter(childRows, "apr")
        }
    },
    {
        key: 'may',
        name: 'May',
        formatter({ row }) {
            return <>{valueFormatter(row.may.value)}</>;
        },
        groupFormatter({ childRows }) {
            return totalPnlGroupFormatter(childRows, "may")
        }
    },
    {
        key: 'jun',
        name: 'Jun',
        formatter({ row }) {
            return <>{valueFormatter(row.jun.value)}</>;
        },
        groupFormatter({ childRows }) {
            return totalPnlGroupFormatter(childRows, "jun")
        }
    },
    {
        key: 'jul',
        name: 'Jul',
        formatter({ row }) {
            return <>{valueFormatter(row.jul.value)}</>;
        },
        groupFormatter({ childRows }) {
            return totalPnlGroupFormatter(childRows, "jul")
        }
    },
    {
        key: 'aug',
        name: 'Aug',
        formatter({ row }) {
            return <>{valueFormatter(row.aug.value)}</>;
        },
        groupFormatter({ childRows }) {
            return totalPnlGroupFormatter(childRows, "aug")
        }
    },
    {
        key: 'sep',
        name: 'Sep',
        formatter({ row }) {
            return <>{valueFormatter(row.sep.value)}</>;
        },
        groupFormatter({ childRows }) {
            return totalPnlGroupFormatter(childRows, "sep")
        }
    },
    {
        key: 'oct',
        name: 'Oct',
        formatter({ row }) {
            return <>{valueFormatter(row.oct.value)}</>;
        },
        groupFormatter({ childRows }) {
            return totalPnlGroupFormatter(childRows, "oct")
        }
    },
    {
        key: 'nov',
        name: 'Nov',
        formatter({ row }) {
            return <>{valueFormatter(row.nov.value)}</>;
        },
        groupFormatter({ childRows }) {
            return totalPnlGroupFormatter(childRows, "nov")
        }
    },
    {
        key: 'dec',
        name: 'Dec',
        formatter({ row }) {
            return <>{valueFormatter(row.dec.value)}</>;
        },
        groupFormatter({ childRows }) {
            return totalPnlGroupFormatter(childRows, "dec")
        }
    },
    {
        key: 'total',
        name: 'Total',
        groupFormatter({ childRows }) {
            let type_list = new Set(childRows.map(r => r.type))
            if (type_list.size === 1) {
                const sum = childRows.reduce((prev, row) => prev + row.jan.value + row.feb.value + row.mar.value + row.apr.value + row.may.value + row.jun.value + row.jul.value + row.aug.value + row.sep.value + row.oct.value + row.nov.value + row.dec.value, 0)
                return <>{valueFormatter(sum)}</>;

            }
            childRows = childRows.filter(r => r.period === "PNL" && r.type === "Last")
            const sum = childRows.reduce((prev, row) => prev + row.jan.value + row.feb.value + row.mar.value + row.apr.value + row.may.value + row.jun.value + row.jul.value + row.aug.value + row.sep.value + row.oct.value + row.nov.value + row.dec.value, 0)
            return <>{valueFormatter(sum)}</>;
        }
    }
];

const source_columns = [
    {
        key: 'country',
        name: 'Market',

    },
    {
        key: 'vitus',
        name: 'Vitus',
        formatter: ({ row }) => {
            return (<Radio checked={row.vitus} color='primary' />)
        }
    },
    {
        key: 'ice',
        name: 'ICE',
        formatter: ({ row }) => {
            return (<Radio checked={row.ice} color='primary' />)
        }
    },
    {
        key: 'ice_last_trades',
        name: 'ICE LT',
        formatter: ({ row }) => {
            return (<Radio checked={row.ice_last_trades} color='primary' />)
        }
    },
    {
        key: 'eex',
        name: 'EEX',
        formatter: ({ row }) => {
            return (<Radio checked={row.eex} color='primary' />)
        }
    },
    {
        key: 'viop',
        name: 'VIOP',
        formatter: ({ row }) => {
            return (<Radio checked={row.viop} color='primary' />)
        }
    },
]

function pnlCountryGroupFormatter(childRows, column) {
    let period_list = new Set(childRows.map(r => r.period))

    if (period_list.size === 1) {
        if (period_list.has("Mark") || period_list.has("Peak") || period_list.has("Offpeak") || period_list.has("PNL")) {
            childRows = childRows.filter(r => r.type === 'Last')
            const sum = childRows.reduce((prev, row) => prev + row[column].value, 0)
            return <>{valueFormatter(sum)}</>;
        }

        if (period_list.has("Positions")) {
            childRows = childRows.filter(r => r.type === 'Last Peak' || r.type === 'Last Offpeak')
            const sum = childRows.reduce((prev, row) => prev + row[column].value, 0)
            return <>{valueFormatter(sum / childRows.length)}</>;
        }
        const sum = childRows.reduce((prev, row) => prev + row[column].value, 0)
        return <>{valueFormatter(sum)}</>;
    }
    childRows = childRows.filter(r => r.period === "PNL" && r.type === 'Last')
    const sum = childRows.reduce((prev, row) => prev + row[column].value, 0)
    let source = ""

    if (childRows.length) {
        source = childRows[0][column]?.source
    }

    return (<Tooltip title={source} arrow>
        <div>{valueFormatter(sum)}</div>
    </Tooltip>);
}

function pnlCompanySummaryGroupFormatter(childRows, column) {

    childRows = childRows.filter(r => r.type === 'Last')
    const sum = childRows.reduce((prev, row) => prev + row[column], 0)

    return <>{valueFormatter(sum)}</>

}

function totalPnlGroupFormatter(childRows, column) {
    let type_list = new Set(childRows.map(r => r.type))

    if (type_list.size === 1) {
        const sum = childRows.reduce((prev, row) => prev + row[column].value, 0)
        return <>{valueFormatter(sum)}</>;
    }

    childRows = childRows.filter(r => r.period === "PNL" && r.type === "Last")
    const sum = childRows.reduce((prev, row) => prev + row[column].value, 0)
    return <>{valueFormatter(sum)}</>;
}

function valueFormatter(value) {
    return value.toLocaleString(undefined, { maximumFractionDigits: 2 })
}


function rowKeyGetter(row) {
    return row.id;
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const options = ['country', 'period'];

class Pnl extends React.Component {
    title = "PNL"

    spinner = new SpinnerManager(history.location.pathname);

    state = {
        rows: [],
        currencyRows: [],
        sourceRows: [],
        selectedRows: new Set(),
        selectedCurrencyRows: new Set(),
        selectedTotalRows: new Set(),
        selectedOptions: [
            options[0],
            options[1],
        ],
        expandedGroupIds: new Set([]),
        expandedCurrencyGroupIds: new Set([]),
        expandedTotalGroupIds: new Set([]),
        counterPartyNames: [],
        marketNames: [],
        bookNames: [],
        openInfoDialogBox: false,
        filter: {},
        companySummaryTables: [],
        companySummaryResponse: {},
        crossborderRequestDate: {}
    }

    componentDidMount() {
        this.getCompaniesDetail();
        this.getMarketDetails()
        this.getBookDetails()
        this.getPnlStatus()
        setInterval(this.getPnlStatus, 50000);
    }

    onShowButtonClick = async (filter) => {
        this.spinner.showSpinner('getPnl');
        this.setState({ filter }, () => {
            this.getCompanySummaryPnl(filter, false)
        });

        getEtrmPnlV2(filter)
            .then(response => {
                if (response.data.success) {
                    this.setState({
                        rows: sortBy(response.data.success.result_list, ['country']),
                        currencyRows: response.data.success.currency_list,
                        sourceRows: response.data.success.price_sources
                    })
                } else {
                    alertError(messages.UNEXPECTED_ERROR_OCCURED);
                }
            }, error => {
                handleApiError(error);
            }).finally(async () => {
                this.spinner.hideSpinner('getPnl');

                if (filter.fetch_cb_pnl) {
                    let isMultiCompanyRequest = !filter.company_list.length || filter.company_list.length >= 2

                    if (filter.has_vitus_commodities) {
                        await this.getCBPnl(filter, 'Vitus', isMultiCompanyRequest, ["st", "lt"])
                    }

                    if (filter.has_vitus_capital) {
                        await this.getCBPnl(filter, 'Vitus Capital', true, ["lt"])
                        await this.getCBPnl(filter, 'Vitus Capital', true, ["st"])
                    }

                    if (filter.has_monolith_capital) {
                        await this.getCBPnl(filter, 'Monolith Capital', true, ["lt"])
                        await this.getCBPnl(filter, 'Monolith Capital', true, ["st"])
                    }

                }

            });

    }

    downloadCompanySummary = async () => {
        const spinnerKey = this.spinner.showSpinner();
        let body = {
            summary_data: { result_list: this.state.companySummaryResponse, currency_list: this.state.currencyRows, details_list: this.state.rows }

        }

        downloadCompanySummaryPnl(body).then(async response => {
            let fileCompanyName = []
            if (this.state.filter.has_vitus_commodities) {
                fileCompanyName.push("Vitus Commodities") 
            }
            if (this.state.filter.has_vitus_capital) {
                fileCompanyName.push("Vitus Capital")
            }
            if (this.state.filter.has_monolith_capital) {
                fileCompanyName.push("Monolith Capital")
            } 

            const filename = `${fileCompanyName.join("-")} Total Daily PnL Report Year  ${this.state.filter.year} - ${moment(new Date()).format('DD-MM-YYYY')}.xlsx`;
            const contentType = response.headers["content-type"];

            const blob = new Blob([response.data], { type: contentType });

            if (contentType === 'application/json') {
                //const error = blob.text();
                return;
            }

            FileSaver.saveAs(blob, filename);
            return;
        }, error => {
            handleApiError(error);
        }).finally(() => {
            this.spinner.hideSpinner(spinnerKey);
        });
    }

    sendCompanySummary = async () => {
        const spinnerKey = this.spinner.showSpinner();
        
        let fileCompanyName = [];
        if (this.state.filter.has_vitus_commodities) {
            fileCompanyName.push("Vitus Commodities")
        }
        if (this.state.filter.has_vitus_capital) {
            fileCompanyName.push("Vitus Capital")
        }
        if (this.state.filter.has_monolith_capital) {
            fileCompanyName.push("Monolith Capital")
        }
        const filename = `${fileCompanyName.join("-")} Total Daily PnL Report Year  ${this.state.filter.year} - ${moment(new Date()).format('DD-MM-YYYY')}.xlsx`;
        
        let body = {
            summary_data: { result_list: this.state.companySummaryResponse, currency_list: this.state.currencyRows, details_list: this.state.rows, file_name: filename }
        }

        sendCompanySummaryPnl(body).then(async response => {
            if (response.data.success){
                alertSuccess(messages.PNL.SEND_PNL_SUCCESS);
            }
            else{
                alertError(messages.PNL.SEND_PNL_ERROR);
            }
        }, error => {
            handleApiError(error);
        }).finally(() => {
            this.spinner.hideSpinner(spinnerKey);
        });
    }

    getCompanySummaryPnl = async (filter) => {
        let curr_column = {
            key: 'type',
            name: filter.currency,
            frozen: true
        }


        getCompanySummaryPnl(filter)
            .then(response => {
                if (response.data.success) {

                    let successData = response.data.success.result_list
                    this.setState({ companySummaryResponse: successData })

                    let companySet = new Set(successData.map(i => i.company))
                    let companyList = Array.from(companySet)

                    let company_data_list = companyList.map(company => {
                        let currentCompanyData = successData.filter(d => d.company === company)
                        let company_column = {
                            key: 'book_name',
                            name: company,
                            frozen: true,
                            width: 170
                        }
                        let summaryColumns = [company_column, curr_column, ...company_summary_columns]
                        let companyWithoutTotal = currentCompanyData.filter(i => i.book_name !== "Total")
                        let companyTotal = currentCompanyData.filter(i => i.book_name === "Total")

                        return {
                            companyName: company,
                            companyColumn: summaryColumns,
                            companyRows: [...companyWithoutTotal, ...companyTotal],
                        }
                    })
                    this.setState({
                        companySummaryTables: company_data_list
                    })


                }
            }, error => {
                handleApiError(error);
            })
    }

    getCBPnl = async (filter, company, is_multi_company, period_list) => {
        let now = new Date();
        now.setUTCHours(19, 0, 0, 0)
        now.setDate(now.getDate() + 1);

        let request_filter = {
            year: filter.year,
            start_date: filter.start_date,
            end_date: moment(now).utcOffset(0, true).format("YYYY-MM-DD"),
            currency: filter.currency,
            grouping: "Monthly",
            company_list: [company],
            period_list: period_list
        }

        let book_name = period_list.includes("st") ? "Crossborder" : "FTR";

        if (company === "Vitus") {
            company = "Vitus Commodities"
        }

        // use request date to cancel previous request when new filter available
        let newRequestDate = new Date()
        let cbRequestDate = {}
        if (is_multi_company) {
            cbRequestDate = { ...this.state.crossborderRequestDate }
        }
        cbRequestDate[`${company}_${book_name}`] = newRequestDate

        this.setState({ crossborderRequestDate: cbRequestDate }, () => {
            this.getLatestCBPnl(request_filter, company, book_name, newRequestDate)
        })

    }

    getLatestCBPnl = async (request_filter, company, book_name, newRequestDate) => {
        let year = request_filter.year.toString().substr(2)
        let month_map = {}
        month_names.forEach(m => month_map[`${m}-${year}`] = m.toLowerCase())

        let cb_pre_row = {
            "id": `${company}_${book_name}_pre`,
            "company": company,
            "period": "PNL",
            "type": "Previous",
            "country": `${company} - ${book_name}`,
            "jan": { "value": 0, "source": "Vitus" },
            "feb": { "value": 0, "source": "Vitus" },
            "mar": { "value": 0, "source": "Vitus" },
            "apr": { "value": 0, "source": "Vitus" },
            "may": { "value": 0, "source": "Vitus" },
            "jun": { "value": 0, "source": "Vitus" },
            "jul": { "value": 0, "source": "Vitus" },
            "aug": { "value": 0, "source": "Vitus" },
            "sep": { "value": 0, "source": "Vitus" },
            "oct": { "value": 0, "source": "Vitus" },
            "nov": { "value": 0, "source": "Vitus" },
            "dec": { "value": 0, "source": "Vitus" },
        }

        let cb_last_row = {
            "id": `${company}_${book_name}_last`,
            "company": company,
            "period": "PNL",
            "type": "Last",
            "country": `${company} - ${book_name}`,
            "jan": { "value": 0, "source": "Vitus" },
            "feb": { "value": 0, "source": "Vitus" },
            "mar": { "value": 0, "source": "Vitus" },
            "apr": { "value": 0, "source": "Vitus" },
            "may": { "value": 0, "source": "Vitus" },
            "jun": { "value": 0, "source": "Vitus" },
            "jul": { "value": 0, "source": "Vitus" },
            "aug": { "value": 0, "source": "Vitus" },
            "sep": { "value": 0, "source": "Vitus" },
            "oct": { "value": 0, "source": "Vitus" },
            "nov": { "value": 0, "source": "Vitus" },
            "dec": { "value": 0, "source": "Vitus" },
        }

        let cb_change_row = {
            "id": `${company}_${book_name}_change`,
            "company": company,
            "period": "PNL",
            "type": "Change",
            "country": `${company} - ${book_name}`,
            "jan": { "value": 0, "source": "Vitus" },
            "feb": { "value": 0, "source": "Vitus" },
            "mar": { "value": 0, "source": "Vitus" },
            "apr": { "value": 0, "source": "Vitus" },
            "may": { "value": 0, "source": "Vitus" },
            "jun": { "value": 0, "source": "Vitus" },
            "jul": { "value": 0, "source": "Vitus" },
            "aug": { "value": 0, "source": "Vitus" },
            "sep": { "value": 0, "source": "Vitus" },
            "oct": { "value": 0, "source": "Vitus" },
            "nov": { "value": 0, "source": "Vitus" },
            "dec": { "value": 0, "source": "Vitus" },
        }

        let cb_summary_pre_row = {
            "id": `${company}_${book_name}_summary_pre`,
            "company": company,
            "book_name": book_name,
            "trading_venue": "otc_physical",
            "currency": request_filter.currency,
            "type": "Previous",
            "jan": 0,
            "feb": 0,
            "mar": 0,
            "apr": 0,
            "may": 0,
            "jun": 0,
            "jul": 0,
            "aug": 0,
            "sep": 0,
            "oct": 0,
            "nov": 0,
            "dec": 0,
        }

        let cb_summary_last_row = {
            "id": `${company}_${book_name}_summary_last`,
            "company": company,
            "book_name": book_name,
            "trading_venue": "otc_physical",
            "currency": request_filter.currency,
            "type": "Last",
            "jan": 0,
            "feb": 0,
            "mar": 0,
            "apr": 0,
            "may": 0,
            "jun": 0,
            "jul": 0,
            "aug": 0,
            "sep": 0,
            "oct": 0,
            "nov": 0,
            "dec": 0,
        }

        let cb_summary_change_row = {
            "id": `${company}_${book_name}_summary_change`,
            "company": company,
            "book_name": book_name,
            "trading_venue": "otc_physical",
            "currency": request_filter.currency,
            "type": "Change",
            "jan": 0,
            "feb": 0,
            "mar": 0,
            "apr": 0,
            "may": 0,
            "jun": 0,
            "jul": 0,
            "aug": 0,
            "sep": 0,
            "oct": 0,
            "nov": 0,
            "dec": 0,
        }

        getPnl(request_filter)
            .then(response => {
                if (this.state.crossborderRequestDate[`${company}_${book_name}`] === newRequestDate && response.data.success) {
                    let successData = response.data.success
                    let sumOfBorders = successData.pnl_summary?.sum_of_borders
                    let additionalCustomPnl = successData.pnl_summary?.additional_custom_pnl[company]

                    if (additionalCustomPnl) {
                        sumOfBorders = [...sumOfBorders, ...additionalCustomPnl]
                    }


                    if (sumOfBorders) {
                        sumOfBorders.map(cb => {
                            if (cb_pre_row[month_map[cb.dt]]) {
                                cb_pre_row[month_map[cb.dt]].value = cb.previous
                                cb_last_row[month_map[cb.dt]].value = cb.today
                                cb_change_row[month_map[cb.dt]].value = cb.today - cb.previous

                                cb_summary_pre_row[month_map[cb.dt]] = cb.previous
                                cb_summary_last_row[month_map[cb.dt]] = cb.today
                                cb_summary_change_row[month_map[cb.dt]] = cb.today - cb.previous
                            }

                        })

                        let company_summary = this.state.companySummaryTables.find(s => s.companyName === company)
                        let other_company_summary = this.state.companySummaryTables.filter(s => s.companyName !== company)

                        let companyWithoutTotal = []
                        let companyTotal = []
                        if (company_summary) {
                            companyWithoutTotal = company_summary.companyRows.filter(i => i.book_name !== "Total")
                            companyTotal = company_summary.companyRows.filter(i => i.book_name === "Total")
                        }


                        let companyPreviousTotal = companyTotal.find(i => i.type === "Previous")
                        if (!companyPreviousTotal) {
                            companyPreviousTotal = {
                                "id": `${company}_summary_pre_total`,
                                "company": company,
                                "book_name": "Total",
                                "trading_venue": "otc_physical",
                                "currency": request_filter.currency,
                                "type": "Previous",
                                "jan": 0,
                                "feb": 0,
                                "mar": 0,
                                "apr": 0,
                                "may": 0,
                                "jun": 0,
                                "jul": 0,
                                "aug": 0,
                                "sep": 0,
                                "oct": 0,
                                "nov": 0,
                                "dec": 0,
                            }

                        }
                        let companyLastTotal = companyTotal.find(i => i.type === "Last")

                        if (!companyLastTotal) {
                            companyLastTotal = {
                                "id": `${company}_summary_last_total`,
                                "company": company,
                                "book_name": "Total",
                                "trading_venue": "otc_physical",
                                "currency": request_filter.currency,
                                "type": "Last",
                                "jan": 0,
                                "feb": 0,
                                "mar": 0,
                                "apr": 0,
                                "may": 0,
                                "jun": 0,
                                "jul": 0,
                                "aug": 0,
                                "sep": 0,
                                "oct": 0,
                                "nov": 0,
                                "dec": 0,

                            }
                        }
                        let companyChangeTotal = companyTotal.find(i => i.type === "Change")

                        if (!companyChangeTotal) {
                            companyChangeTotal = {
                                "id": `${company}_summary_change_total`,
                                "company": company,
                                "book_name": "Total",
                                "trading_venue": "otc_physical",
                                "currency": request_filter.currency,
                                "type": "Change",
                                "jan": 0,
                                "feb": 0,
                                "mar": 0,
                                "apr": 0,
                                "may": 0,
                                "jun": 0,
                                "jul": 0,
                                "aug": 0,
                                "sep": 0,
                                "oct": 0,
                                "nov": 0,
                                "dec": 0,

                            }
                        }

                        company_summary_columns.map(m => {
                            let key = m.key
                            companyPreviousTotal[key] = companyPreviousTotal[key] ? companyPreviousTotal[key] + cb_summary_pre_row[key] : cb_summary_pre_row[key]
                            companyLastTotal[key] = companyLastTotal[key] ? companyLastTotal[key] + cb_summary_last_row[key] : cb_summary_last_row[key]
                            companyChangeTotal[key] = companyChangeTotal[key] ? companyChangeTotal[key] + cb_summary_change_row[key] : cb_summary_change_row[key]
                        })

                        let company_column = {
                            key: 'book_name',
                            name: company,
                            frozen: true,
                            width: 170
                        }

                        let curr_column = {
                            key: 'type',
                            name: request_filter.currency,
                            frozen: true
                        }

                        let company_data_list = [
                            {
                                companyName: company_summary ? company_summary.companyName : company,
                                companyColumn: company_summary ? company_summary.companyColumn : [company_column, curr_column, ...company_summary_columns],
                                companyRows: [...companyWithoutTotal, cb_summary_pre_row, cb_summary_last_row, cb_summary_change_row, companyPreviousTotal, companyLastTotal, companyChangeTotal],
                            }

                        ]

                        let other_company_summary_rows = []
                        if (other_company_summary?.length) {
                            company_data_list = [...company_data_list, ...other_company_summary]
                            other_company_summary_rows = [...other_company_summary.map(s => s.companyRows.map(c => c))]
                        }

                        this.setState({
                            rows: [...this.state.rows, cb_pre_row, cb_last_row, cb_change_row],
                            companySummaryTables: company_data_list,
                            companySummaryResponse: [...(other_company_summary_rows[0] ? other_company_summary_rows[0] : []), ...(other_company_summary_rows[1] ? other_company_summary_rows[1] : []), ...companyWithoutTotal, cb_summary_pre_row, cb_summary_last_row, cb_summary_change_row, companyPreviousTotal, companyLastTotal, companyChangeTotal]
                        })

                    }
                }
            }, error => {
                if (this.state.crossborderRequestDate[`${company}_${book_name}`] === newRequestDate) {
                    handleApiError(error);
                }
            }).finally(() => {
                if (this.state.crossborderRequestDate[`${company}_${book_name}`] === newRequestDate) {
                    alertSuccess(messages.PNL.CROSSBORDER_PNL.replace("[COMPANY]", company).replace("[BOOK]", book_name))
                }
            });
    }

    getPnlStatus = async () => {
        getPnlStatus()
            .then(response => {
                if (response.data.success) {
                    this.setState({
                        pnlStatus: response.data.success
                    })
                }
            }, error => {
                console.log(error);
            }).finally(() => {

            });
    }

    async getCompaniesDetail() {
        this.spinner.showSpinner('getCompanyNames');
        getCompaniesDetail()
            .then(response => {
                if (response.data.success) {
                    let successData = response.data.success
                    this.setState({
                        counterPartyNames: successData.company_list.map(x => (
                            {
                                id: x.id,
                                value: x.name,
                                label: x.name,
                                type: x.type,
                            }
                        )),
                    });

                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getCompanyNames');
            });
    }

    async getMarketDetails() {
        this.spinner.showSpinner('getMarketNames');
        getMarketNames()
            .then(response => {
                if (response.data.success) {
                    let successData = response.data.success
                    this.setState({
                        marketNames: successData.market_name_list.map(x => ({
                            id: x.id,
                            value: x.name,
                            label: x.name,
                            currency: x.currency
                        })),
                    });

                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getMarketNames');
            });
    }

    async getBookDetails() {
        this.spinner.showSpinner('getBookNames');
        getBookDetails()
            .then(response => {
                if (response.data.success) {
                    let successData = response.data.success
                    this.setState({
                        bookNames: successData.book_list.map(x => ({
                            id: x.id,
                            value: x.name,
                            label: x.name
                        })),
                    });

                }
            }, error => {
                handleApiError(error);
            }).finally(() => {
                this.spinner.hideSpinner('getBookNames');
            });
    }

    onInfoButtonClick() {
        this.setState({ openInfoDialogBox: true })
    }

    closeInfoDialogBox() {
        this.setState({ openInfoDialogBox: false })
    }

    getTableComponent() {
        return (
            <LeftSideContainer
                contentLabel={this.title}
                contentDefaultExpanded={true}
                contentComponent={
                    <div>
                        {this.state.sourceRows?.length ? (
                            <InfoIcon style={{ margin: "5px" }} onClick={() => this.onInfoButtonClick()}></InfoIcon>
                        ) : null
                        }
                        {
                            this.state.currencyRows?.length ? (
                                <DataGrid
                                    columns={curr_columns}
                                    rows={this.state.currencyRows}
                                    defaultColumnOptions={{ resizable: true }}
                                    groupBy={["currency"]}
                                    rowGrouper={rowGrouper}
                                    rowKeyGetter={rowKeyGetter}
                                    selectedRows={this.state.selectedCurrencyRows}
                                    onSelectedRowsChange={rows => this.setState({ selectedCurrencyRows: rows })}
                                    expandedGroupIds={this.state.expandedCurrencyGroupIds}
                                    onExpandedGroupIdsChange={ids => this.setState({ expandedCurrencyGroupIds: ids })}
                                    style={{ height: "300px" }}
                                />
                            ) : null
                        }
                        <DataGrid
                            columns={total_columns}
                            rows={this.state.rows.filter(r => r.period === "PNL")}
                            rowKeyGetter={rowKeyGetter}
                            selectedRows={this.state.selectedTotalRows}
                            onSelectedRowsChange={rows => this.setState({ selectedTotalRows: rows })}
                            groupBy={["period", "type"]}
                            rowGrouper={rowGrouper}
                            expandedGroupIds={this.state.expandedTotalGroupIds}
                            onExpandedGroupIdsChange={ids => this.setState({ expandedTotalGroupIds: ids })}
                            defaultColumnOptions={{ resizable: true }}
                            style={{ height: "300px" }}
                        />
                        <div className="t-table-toolbar">
                            <button type="button" className="btn t-orange-button" onClick={() => { this.downloadCompanySummary() }}>
                                Export to XLSX
                            </button>
                            <button type="button" className="btn t-orange-button" onClick={() => { this.sendCompanySummary() }}>
                                Send to Slack
                            </button>
                        </div>

                        {
                            this.state.companySummaryTables.length ? (
                                this.state.companySummaryTables.map(table => {
                                    let expandedGroupIds = new Set([])
                                    if (this.state[`expandedCompany${table.companyName}`]) {
                                        expandedGroupIds = this.state[`expandedCompany${table.companyName}`]
                                    } else {
                                        expandedGroupIds = new Set(table.companyRows.map(i => i.book_name))
                                    }

                                    return (
                                        table.companyRows.length ? (
                                            <DataGrid
                                                key={`tableCompanySummary_${table.companyName}`}
                                                style={{ height: `${(expandedGroupIds.size + 1) * 150}px` }}
                                                columns={table.companyColumn}
                                                rows={table.companyRows}
                                                defaultColumnOptions={{ resizable: true }}
                                                groupBy={["book_name"]}
                                                rowGrouper={rowGrouper}
                                                expandedGroupIds={expandedGroupIds}
                                                onExpandedGroupIdsChange={ids => {
                                                    this.setState({
                                                        [`expandedCompany${table.companyName}`]: ids
                                                    })
                                                }}
                                            />
                                        ) : null

                                    )
                                })
                            ) : null

                        }

                        <DataGrid
                            columns={columns}
                            rows={this.state.rows}
                            rowKeyGetter={rowKeyGetter}
                            selectedRows={this.state.selectedRows}
                            onSelectedRowsChange={rows => this.setState({ selectedRows: rows })}
                            groupBy={this.state.selectedOptions}
                            rowGrouper={rowGrouper}
                            expandedGroupIds={this.state.expandedGroupIds}
                            onExpandedGroupIdsChange={ids => this.setState({ expandedGroupIds: ids })}
                            defaultColumnOptions={{ resizable: true }}
                        />
                        <TDialogBox
                            openAddDialogBox={this.state.openInfoDialogBox}
                            handleClose={() => this.closeInfoDialogBox()}
                            title={"Price Sources"}
                        >
                            <DataGrid
                                columns={source_columns}
                                rows={this.state.sourceRows}
                                defaultColumnOptions={{ resizable: true }}
                                style={{ height: "500px" }}
                            />
                        </TDialogBox>
                    </div>
                }
                filterComponent={
                    this.state.counterPartyNames.length &&
                        this.state.marketNames.length &&
                        this.state.bookNames.length ? (
                            <PnlFilter
                                counterPartyNames={this.state.counterPartyNames}
                                marketNames={this.state.marketNames}
                                bookNames={this.state.bookNames}
                                onShowButtonClick={this.onShowButtonClick}
                            ></PnlFilter>
                        ) : null

                }
            >
            </LeftSideContainer>
        )
    }

    render() {
        return (
            <TContentContainer>

                <div >
                    {
                        this.state.pnlStatus?.is_pnl_updated ? (
                            <Alert severity="success">PNL up-to-date</Alert>
                        ) : (
                                <Alert severity="error" animation="wave">
                                    <div>
                                        <span>Calculating</span>
                                    </div>
                                </Alert>
                            )
                    }

                </div>
                <TContentDetailContainer
                    leftSideComponent={this.getTableComponent()}
                >
                </TContentDetailContainer>
            </TContentContainer>
        );
    }
}

export default Pnl;